import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import RegisterComponent from "./modules/authentication/register/RegisterComponent";
import LoginComponent from "./modules/authentication/login/LoginComponent";
import HomeComponent from "./components/home/HomeComponent";
import InstitutionPageComponent from "./modules/institution/institutionPage/InstitutionPageComponent";
import StudentDashboardComponent from "./modules/student/dashboard/StudentDashboardComponent";
import TpoDashboardComponent from "./modules/tpo/dashboard/TpoDashboardComponent";
import ForgotPasswordComponent from "./modules/authentication/forgot-password/ForgotPasswordComponent";
import ResetPasswordComponent from "./modules/authentication/resetPassword/ResetPasswordComponent";
import StudentProfileComponent from "./modules/student/profile/StudentProfileComponent";
import {
  BasicInfoDetails,
  EducationDetails,
  AccountDetails,
  ProjectDetails,
  InternshipDetails,
  ExtraCurricularDetails,
  CertificationDetails,
  TechSkillDetails,
} from "./modules/student/profile/StudentProfileComponent";
import TpoProfileComponent from "./modules/tpo/profile/TpoProfileComponent";
import { BasicTpoInfoDetails, TpoAccountDetails } from "./modules/tpo/profile/TpoProfileComponent";
import { BasicInstitutionInfoDetails, InstitutionAccountDetails, BillingDetails } from "./modules/institution/profile/InstitutionProfileComponent";
import InstitutionProfileComponent from "./modules/institution/profile/InstitutionProfileComponent";
import NewPlacementDriveComponent from "./modules/tpo/newPlacementDrive/NewPlacementDriveComponent";
import TechSkillReportComponent from "./modules/student/reports/TechSkillReportComponent";
import ShortlistedCandidatesComponent from "./modules/tpo/ShortlistedCandidatesComponent";
import StudentTpoFeedsComponent from "./modules/common/feed/StudentTpoFeedComponent";
import DriveHistory from "./modules/tpo/driveHistory/DriveHistory";
import StudentData from "./modules/tpo/studentData/StudentData";
import ELigibilityMessages from "./modules/student/eligibilityMessages/EligibilityMessages";
import MainPage from "./components/resumeView/Main";
import ApplicantsList from "./modules/tpo/applicantsList";
import PlacementHistory from "./modules/tpo/placementHistory";
import DriveResult from "./modules/tpo/driveResult";
import AdminRegistration from "./modules/authentication/register/adminRegistration";
import AdminDashBoardComponent from "./modules/admin/adminDashboardComponent";
import DashboardContent from "./modules/admin/dashboardContent";
import AddInstitution from "./modules/admin/addInstitution";
import Statistics from "./modules/admin/statistics";
import ManageUsers from "./modules/admin/manageUsers";
import JoiningRequest from "./modules/admin/joiningRequest";
import Payment from "./modules/institution/payment";
import Support from "./components/support";
import NotFound from "./components/notFound";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function App() {
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState();
  // rCrcnF

  useEffect(() => {
    let login = localStorage.getItem("rpc_user");
    login = JSON.parse(login);
    if (login) {
      setLogin(true);
      setUser(login.user);
    }
    if (!login) {
      setLogin(false);
    }

  }, []);

  return (
    <>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomeComponent />} />
            <Route path="/_support" element={<Support />} />
            <Route path="/forgot-password" element={<ForgotPasswordComponent />} />
            <Route path="/reset-password/:resetToken/:email" element={<ResetPasswordComponent />} />
            <Route path="/register" element={<RegisterComponent />} />
            <Route path="/login" element={<LoginComponent />} />
            <Route path="/admin-register" element={<AdminRegistration />} />
            {/* <Route path="/payment/:inst_id" element={<Payment />} /> */}

            {/* {login && user.role_id === 2 && ( */}
              <Route path="/institution">
                <Route path=":id" element={<InstitutionPageComponent Children={InstitutionPageComponent} />} />
                <Route path=":id/institution-profile">
                  <Route path="basic-info" element={<InstitutionProfileComponent Children={BasicInstitutionInfoDetails} />} />
                  <Route path="account" element={<InstitutionProfileComponent Children={InstitutionAccountDetails} />} />
                  <Route path="billing-dashboard" element={<InstitutionProfileComponent Children={BillingDetails} />} />
                  <Route path="payment" element={<InstitutionProfileComponent Children={Payment} />} />
                </Route>
              </Route>
            {/* )} */}
            {/* {login && user.role_id === 4 && ( */}
              <Route path="/student">
                <Route path=":id/:inst_id" element={<StudentDashboardComponent Children={StudentTpoFeedsComponent} />} />
                <Route path=":id/:inst_id/tpc-feeds" element={<StudentDashboardComponent Children={StudentTpoFeedsComponent} />} />
                <Route path=":id/:inst_id/student-profile">
                  <Route path="basic-info" element={<StudentProfileComponent Children={BasicInfoDetails} />} />
                  <Route path="education" element={<StudentProfileComponent Children={EducationDetails} />} />
                  <Route path="account" element={<StudentProfileComponent Children={AccountDetails} />} />
                  <Route path="project" element={<StudentProfileComponent Children={ProjectDetails} />} />
                  <Route path="internship" element={<StudentProfileComponent Children={InternshipDetails} />} />
                  <Route path="extra-curricular-activity" element={<StudentProfileComponent Children={ExtraCurricularDetails} />} />
                  <Route path="certification" element={<StudentProfileComponent Children={CertificationDetails} />} />
                  <Route path="skills" element={<StudentProfileComponent Children={TechSkillDetails} />} />
                </Route>
                <Route path=":id/:inst_id/student-tech-report" element={<StudentDashboardComponent Children={TechSkillReportComponent} />} />
                <Route path=":id/:inst_id/eligible-for-drives" element={<StudentDashboardComponent Children={ELigibilityMessages} />} />
                <Route path=":id/:inst_id/student-make-resume" element={<StudentDashboardComponent Children={MainPage} />} />
              </Route>
            {/* )} */}
            {/* {login && user.role_id === 3 && ( */}
              <Route path="/tpo">
                <Route path=":id/:inst_id" element={<TpoDashboardComponent Children={StudentTpoFeedsComponent} />} />
                <Route path=":id/:inst_id/tpo-feeds" element={<TpoDashboardComponent Children={StudentTpoFeedsComponent} />} />
                <Route path=":id/:inst_id/drive-history" element={<TpoDashboardComponent Children={DriveHistory} />} />
                <Route path=":id/:inst_id/drive-history/:drive_id/drive-applicants" element={<TpoDashboardComponent Children={ApplicantsList} />} />
                <Route path=":id/:inst_id/placement-history/:drive_id/drive-result" element={<TpoDashboardComponent Children={DriveResult} />} />
                <Route path=":id/:inst_id/student-data" element={<TpoDashboardComponent Children={StudentData} />} />
                <Route path=":id/:inst_id/create-placement-drive" element={<TpoDashboardComponent Children={NewPlacementDriveComponent} />} />
                <Route
                  path=":id/:inst_id/create-placement-drive/shortlised-students"
                  element={<TpoDashboardComponent Children={ShortlistedCandidatesComponent} />}
                />
                <Route path=":id/:inst_id/placement-history" element={<TpoDashboardComponent Children={PlacementHistory} />} />
                <Route path=":id/:inst_id/tpo-profile">
                  <Route path="basic-info" element={<TpoProfileComponent Children={BasicTpoInfoDetails} />} />
                  <Route path="account" element={<TpoProfileComponent Children={TpoAccountDetails} />} />
                </Route>
              </Route>
            {/* )} */}
            {/* {login && user.role_id === 1 && ( */}
              <Route path="/admin">
                <Route path=":id" element={<AdminDashBoardComponent Children={DashboardContent} />} />
                <Route path=":id/dashboard" element={<AdminDashBoardComponent Children={DashboardContent} />} />
                <Route path=":id/add-institution" element={<AdminDashBoardComponent Children={AddInstitution} />} />
                <Route path=":id/statistics" element={<AdminDashBoardComponent Children={Statistics} />} />
                <Route path=":id/admin-manage-users" element={<AdminDashBoardComponent Children={ManageUsers} />} />
                <Route path=":id/approve/joining-requests" element={<AdminDashBoardComponent Children={JoiningRequest} />} />
              </Route>
            {/* )} */}

            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </BrowserRouter>
      </Elements>
    </>
  );
}
