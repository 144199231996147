import React, { useState } from "react";
import "../../App.css";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ErrorLottie from "./errorLottie";
import SuccessLottie from "./successLottie";

const ConfirmationComponent = (props) => {
  const [open, setOpen] = useState(true);

  // console.log(props);

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if(props.children === "success"){
  //     setTimeout(() => {
  //       setOpen(false);
  //     }, 2000);
  //   }
  // }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="content"
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {props.children === "error" ? (
            <ErrorLottie />
          ) : (
            <>{props.children === "success" ? <SuccessLottie /> : ""}</>
          )}
        </DialogTitle>
        <DialogContent
          sx={{ textAlign: "center", color: "#03374d", fontWeight: "600" }}
        >
          {" "}
          {props.data}{" "}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmationComponent;
