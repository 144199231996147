import React from "react";
import { SaveSkillsData, ModifySkillsCount } from "../../../actions/actions";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, Divider, Grid, TextField } from "@material-ui/core";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "80px 60px 80px 100px",
    width: "100%",
    margin: "auto",
    minHeight: "490px",
  },
  deleteButton: {
    marginRight: "1%",
  },
  heading: {
    textAlign: "center",
    marginBottom: "50px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#044f6f",
  },
  addButton: {
    backgroundColor: "#7ff9ee",
    color: "#044f6f",
  },
  footer: {
    marginTop: "50px",
    marginLeft: "0%",
  },
  instance: {
    marginBottom: "1%",
    marginRight: "5%",
  },
}));

const SkillsForm = (props) => {
  const classes = useStyles();

  const handleChange = (index) => (event) => {
    const { value } = event.target;
    const list = [...props.SkillsFormData.Data];
    list[index] = value;
    props.SaveSkillsData(list);
  };
  const DeleteSkills = () => {
    console.log("Decreasing count");
    const list = [...props.SkillsFormData.Data];
    list.pop();
    props.SaveSkillsData(list);
    props.ModifySkillsCount(props.SkillsFormData.Count - 1);
  };

  const AddSkill = () => {
    const list = [...props.SkillsFormData.Data];
    list.push(null);
    props.SaveSkillsData(list);
    console.log("Increasing count");
    props.ModifySkillsCount(props.SkillsFormData.Count + 1);
  };
  let Form = [];
  for (let i = 0; i < props.SkillsFormData.Count; i++) {
    Form.push(
      <div className={classes.instance}>
        <Grid item md={12} xs={12} className="resume">
          <TextField
            required
            fullWidth
            name={`skill`}
            defaultValue={
              props.SkillsFormData.Data && props.SkillsFormData.Data[i]
                ? props.SkillsFormData.Data[i]
                : ""
            }
            label="Skill"
            onChange={handleChange(i)}
            variant="outlined"
            formControlProps={{
              fullWidth: true,
            }}
          />
        </Grid>
        <Divider />
      </div>
    );
  }

  return (
    <>
      <form autoComplete="off" noValidate>
        <Card className={classes.root}>
          <p className={classes.heading}>Add your Skills</p>
          <Grid container>{Form.map((instance) => instance)}</Grid>
          <Grid className={classes.footer}>
            <Button
              disabled={props.SkillsFormData.Count < 2}
              className={classes.deleteButton}
              onClick={DeleteSkills}
              variant="outlined"
              color="secondary"
            >
              Delete Skill
            </Button>
            <Button
              className={classes.addButton}
              onClick={AddSkill}
              variant="contained"
            >
              ADD Skill
            </Button>
          </Grid>
        </Card>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  SkillsFormData: state.Skills,
});

export default connect(mapStateToProps, { SaveSkillsData, ModifySkillsCount })(
  SkillsForm
);
