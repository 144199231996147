import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Paper, Button, TextField} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { PAYMENT_SUBSCRIBE, INST_PAYMENT_DETAILS, SERVER_URL } from "../../apiRoutes";
import './payment.css';



const Payment = () => {
  const stripe = useStripe();
  const elements = useElements();
  
  const {id} = useParams();

  
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");


  const createSubscription = async (e) => {
    try {

    e.preventDefault();
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("card"),
      });

      await axios
        .post(`${SERVER_URL}/${PAYMENT_SUBSCRIBE}/${id}`,{
            name,
            id,
            email,
            paymentMethod: paymentMethod.paymentMethod.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer sk_test_51N27rsSFuJf0ZZouzTBz1drHG8lHAMRSKfB4sPFagQsLgdO6ujOmvzymL6xqsDZOsf34uvEXhzGyeHqzkhStS59l00PJTpMHfv`,
            },
          }
        )
        .then(async (response) => {
          if (response.status === 422) {
            toast.error(response.data, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
          if (response.status !== 200) {
            return toast.error("Payment ok unsuccessful!", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
          const data = await response.data;

          const confirm = await stripe.confirmCardPayment(data.clientSecret);

          if (confirm.error) {
            return toast.error("Payment confirm unsuccessful!", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
          toast.success("Payment successful!", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (err) {
      console.log(err);
      // toast.error(err.response.data, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    }
  };

  useEffect(() => {
    axios.get(`${SERVER_URL}/${INST_PAYMENT_DETAILS}/${id}`).then((res) => {
      if(res.status === 202){
        setEmail(res.data.inst_email);
        setName(res.data.inst_name);
      }
    })
  }, [])

  return (
    <>
      <ToastContainer />
      <Paper elevation={3} sx={{padding: '10px 50px 50px 50px', position: 'absolute', top: '30vh',width: '900px'}}>
        <p style={{fontSize: '20px', fontWeight: '500', color: '#444343', padding: '20px', textAlign:'center'}}>Buy new subscription</p>
        <Paper
          elevation={5}
          sx={{
            padding: "50px",
            // width: "80%",
            margin: "auto",
            marginTop: "5%",
            alignContent: "center",
            // display:'flex'
          }}
        >
          <div style={{margin:'auto', width:'56%'}}>
            <TextField
              id="name-with-sx"
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
            />
            <TextField
              id="name-with-sx"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
            />
          </div>
          <div style={{backgroundColor:'aliceblue', width:'57%', padding:'20px', margin:'auto', borderRadius:'5px'}}>
            <CardElement/>
          </div>
          
          
          <div style={{margin:'auto', width:'56%', display:'flex', justifyContent:'space-between'}}>
            <Button variant="contained" style={{marginTop: '50px', backgroundColor:'#025578'}}>50,000 INR</Button>
            <Button variant="contained" style={{marginTop: '50px', backgroundColor:'#025578'}} onClick={createSubscription}>Subscribe</Button>
          </div>
        </Paper>
      </Paper>
    </>
  );
};

export default Payment;
