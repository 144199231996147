import React from "react";
import "./NavbarComponent.css";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Avatar, Badge } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const NavbarComponent = (props) => {
  const { data, role } = props;
  const { id, inst_id } = useParams("");

  return (
    <>
      <nav className="main-nav">
        <Row>
          {/* <Col xs={1}></Col> */}
          <Col xs={4} className="logo">
            <Link to="/">
              <h2>
                <span>R</span>emote
                <span>P</span>lacement
                <span>C</span>ell
              </h2>
            </Link>
          </Col>

          <Col xs={6} className="menu-link">
            {role === "student" ? (
              <>
                <Link
                  to={`/student/${id}/${inst_id}/student-profile/basic-info`}
                  className="profile"
                >
                  {data.s_name}
                </Link>
                <BootstrapTooltip title="Profile">
                  <Link
                    to={`/student/${id}/${inst_id}/student-profile/basic-info`}
                    className="profile-pic-img"
                  >
                    {data.profile_pic === null ||
                    data.profile_pic === undefined ? (
                      <Avatar
                        sx={{
                          bgcolor: "#03374d",
                          height: "40px",
                          width: "40px",
                          padding: "10px",
                        }}
                        className="profile-img"
                      >
                        <PersonIcon sx={{ fontSize: "25px", color: "white" }} />
                      </Avatar>
                    ) : (
                      <div>
                        <img
                          src={data.profile_pic}
                          alt="profile_pic"
                          style={{
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                          }}
                          className="profile-img"
                        />
                      </div>
                    )}
                  </Link>
                </BootstrapTooltip>
                <BootstrapTooltip title="Placement Drive">
                  <Link
                    to={`/student/${id}/${inst_id}/eligible-for-drives`}
                    className="notification-avatar"
                  >
                    <Avatar
                      sx={{ bgcolor: "#03374d", marginLeft: "20px" }}
                      className="profile-img"
                    >
                      <NotificationsNoneIcon />
                    </Avatar>
                    {data.to_apply_for_count !== 0 ? (
                      <Badge
                        badgeContent={data.to_apply_for_count}
                        color="error"
                        className="student-notif-badge"
                      />
                    ) : (
                      ""
                    )}
                  </Link>
                </BootstrapTooltip>
              </>
            ) : (
              <>
                {role === "tpo" ? (
                  <>
                    <Link
                      to={`/tpo/${id}/${inst_id}/tpo-profile/basic-info`}
                      className="profile"
                    >
                      {data.tpo_name}
                    </Link>
                    <BootstrapTooltip title="Profile">
                      <Link
                        to={`/tpo/${id}/${inst_id}/tpo-profile/basic-info`}
                        className="profile-pic-img"
                      >
                        {data.profile_pic === null ||
                        data.profile_pic === undefined ? (
                          <Avatar
                            sx={{
                              bgcolor: "#03374d",
                              height: "40px",
                              width: "40px",
                              padding: "10px",
                            }}
                            className="profile-img"
                          >
                            <PersonIcon
                              sx={{ fontSize: "25px", color: "white" }}
                            />
                          </Avatar>
                        ) : (
                          <div>
                            <img
                              src={data.profile_pic}
                              alt="profile_pic"
                              style={{
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                              }}
                              className="profile-img"
                            />
                          </div>
                        )}
                      </Link>
                    </BootstrapTooltip>
                    <BootstrapTooltip title="Notifications">
                      <Link to="/" className="notification-avatar-tpo">
                        <Avatar
                          sx={{ bgcolor: "#03374d", marginLeft: "20px" }}
                          className="profile-img"
                        >
                          {" "}
                          <NotificationsNoneIcon />{" "}
                        </Avatar>
                      </Link>
                    </BootstrapTooltip>
                  </>
                ) : (
                  <>
                    {role === "admin" ? (
                      <>
                        <Link to="/" className="profile">
                          {data.user_fullname}
                        </Link>
                        <BootstrapTooltip title="Profile">
                          <Link
                            to={`/admin/${id}/admin-profile/basic-info`}
                            className="profile-pic-img"
                          >
                            {data.profile_pic === null ||
                            data.profile_pic === undefined ? (
                              <Avatar
                                sx={{
                                  bgcolor: "#03374d",
                                  height: "40px",
                                  width: "40px",
                                  padding: "10px",
                                }}
                                className="profile-img"
                              >
                                <PersonIcon
                                  sx={{ fontSize: "25px", color: "white" }}
                                />
                              </Avatar>
                            ) : (
                              <div>
                                <img
                                  src={data.profile_pic}
                                  alt="profile_pic"
                                  style={{
                                    borderRadius: "50%",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                  className="profile-img"
                                />
                              </div>
                            )}
                          </Link>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Joining Requests">
                          <Link
                            to={`/admin/${id}/approve/joining-requests`}
                            className="notification-avatar-admin"
                          >
                            <Avatar
                              sx={{ bgcolor: "#03374d", marginLeft: "20px" }}
                              className="profile-img"
                            >
                              <NotificationsNoneIcon />
                            </Avatar>
                          </Link>
                        </BootstrapTooltip>
                      </>
                    ) : (
                      <>
                        <Link to={`/institution/${id}/institution-profile/basic-info`}
                          className="institution-name"
                          >
                          {data.inst_name}
                        </Link>
                        <LightTooltip title="Profile">
                          <Link
                            to={`/institution/${id}/institution-profile/basic-info`}
                            // className="profile-pic-img institution-profile-pic-img"
                          >
                            {data.inst_profile_pic === null ||
                            data.inst_profile_pic === undefined ? (
                              <Avatar
                                sx={{
                                  bgcolor: "#03374d",
                                  height: "40px",
                                  width: "40px",
                                  padding: "10px",
                                }}
                                // className="profile-img"
                              >
                                <PersonIcon
                                  sx={{ fontSize: "25px", color: "white" }}
                                />
                              </Avatar>
                            ) : (
                              <div>
                                <img
                                  src={data.inst_profile_pic}
                                  alt="profile_pic"
                                  style={{
                                    borderRadius: "50%",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                  // className="profile-img"
                                />
                              </div>
                            )}
                          </Link>
                        </LightTooltip>
                        <LightTooltip title="Notifications">
                          <Link
                            to="/"
                            // className="notification-avatar-institution"
                          >
                            <Avatar
                              sx={{ bgcolor: "#03374d", marginLeft: "20px" }}
                              // className="profile-img"
                            >
                              <NotificationsNoneIcon />
                            </Avatar>
                          </Link>
                        </LightTooltip>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </nav>
    </>
  );
};

export default NavbarComponent;
