import React from "react";
import "./adminStyle.css";
import { TextField, Typography } from "@mui/material";
import { Paper, Button } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ADD_ANONYMOUS_INST, SERVER_URL } from "../../apiRoutes";
import { ToastContainer, toast } from "react-toastify";
import "../../../node_modules/react-toastify/dist/ReactToastify.css";

const AddInstitution = () => {
  const formik = useFormik({
    initialValues: {
      inst_user: "",
      inst_email: "",
      inst_name: "",
      drive_criteria: "",
      inst_university: "",
      inst_loc: "",
      inst_pincode: "",
      inst_mobile: "",
    },
    onSubmit: (values) => {
      const {
        inst_user,
        inst_email,
        inst_name,
        drive_criteria,
        inst_university,
        inst_loc,
        inst_pincode,
        inst_mobile,
      } = values;

      axios
        .post(`${SERVER_URL}/${ADD_ANONYMOUS_INST}`, {
          inst_user,
          inst_email,
          inst_name,
          drive_criteria,
          inst_university,
          inst_pincode,
          inst_loc,
          inst_mobile,
        })
        .then((res) => {
          if (res.status === 422 || !res.data) {
            toast.error(res.data, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 202) {
            toast.success(res.data, {
              position: toast.POSITION.TOP_CENTER,
            });
            localStorage.setItem("user", JSON.stringify(res.data));
          }
        })
        .catch((err) => {
          toast.error(err.response.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    },
    validationSchema: Yup.object({
      inst_user: Yup.string().required("required"),
      inst_email: Yup.string()
        .email("Invalid Email Address")
        .required("required"),
      inst_name: Yup.string().required("required"),
      drive_criteria: Yup.string().required("required"),
      inst_university: Yup.string().required("required"),
      inst_loc: Yup.string().required("required"),
      inst_pincode: Yup.string().required("required"),
      inst_mobile: Yup.number()
        .required("required")
        .min(10, "Invalid Contact no."),
    }),
  });

  return (
    <>
      {/* {
                error ? <ConfirmationComponent data={resMsg} children={resIcon} /> : "" 
            } */}
      <ToastContainer />
      <Paper elevation={3} className="registerF justify-content-center">
        <h2 className="registerHead">Add Institution Details!!</h2>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <TextField
                id="outlined-basic"
                label="Institute Username"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.inst_user}
                onBlur={formik.handleBlur}
                name="inst_user"
                className="name userInput"
              />
              {formik.touched.inst_user && formik.errors.inst_user ? (
                <div>
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_user}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col>
              <TextField
                id="outlined-basic"
                onChange={formik.handleChange}
                value={formik.values.inst_email}
                onBlur={formik.handleBlur}
                name="inst_email"
                label="Email.."
                variant="outlined"
                className="email userInput"
              />
              {formik.touched.inst_email && formik.errors.inst_email ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_email}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <TextField
                id="outlined-basic"
                label="Institute Name"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.inst_name}
                onBlur={formik.handleBlur}
                name="inst_name"
                className="institution userInput"
              />
              {formik.touched.inst_name && formik.errors.inst_name ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_name}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col>
              <TextField
                id="outlined-basic"
                type="number"
                label="Campus Criteria Policy"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.drive_criteria}
                onBlur={formik.handleBlur}
                name="drive_criteria"
                className="code userInput"
              />
              {formik.touched.drive_criteria && formik.errors.drive_criteria ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.drive_criteria}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              {/* <Autocomplete onChange={(event, value) => setUniv(value.label)} id="university_name" name="inst_university" options={topUniversities}  isOptionEqualToValue={(option, value) =>option.iso === value.iso} renderInput={(params) => <TextField {...params} label="University" /> } /> */}

              <TextField
                id="outlined-basic"
                label="University"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.inst_university}
                onBlur={formik.handleBlur}
                name="inst_university"
                className="userInput"
              />
              {formik.touched.inst_university &&
              formik.errors.inst_university ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_university}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col>
              <TextField
                id="outlined-basic"
                label="Location"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.inst_loc}
                onBlur={formik.handleBlur}
                name="inst_loc"
                className="location userInput"
              />
              {formik.touched.inst_loc && formik.errors.inst_loc ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_loc}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <TextField
                id="outlined-basic"
                label="Pin Code"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.inst_pincode}
                onBlur={formik.handleBlur}
                name="inst_pincode"
                className="contact userInput"
              />
              {formik.touched.inst_pincode && formik.errors.inst_pincode ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_pincode}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col>
              <TextField
                id="outlined-basic"
                label="Contact No."
                type="number"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.inst_mobile}
                onBlur={formik.handleBlur}
                name="inst_mobile"
                className="contact userInput"
              />
              {formik.touched.inst_mobile && formik.errors.inst_mobile ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_mobile}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Button variant="outlined" className="submitBtn" type="submit">
              + Add
            </Button>
          </Row>
        </form>
      </Paper>
    </>
  );
};

export default AddInstitution;
