import React from "react";
import { SaveSocialData, ModifySocialCount } from "../../../actions/actions";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, Divider, Grid, TextField } from "@material-ui/core";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "60px",
    width: "90%",
    margin: "auto",
    minHeight: "490px",
  },
  deleteButton: {
    marginRight: "1%",
  },
  addButton: {
    backgroundColor: "#7ff9ee",
    color: "#044f6f",
  },
  footer: {
    padding: "1%",
  },
  heading: {
    textAlign: "center",
    marginBottom: "50px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#044f6f",
  },
  instance: {
    marginBottom: "1%",
    padding: "1%",
  },
}));

const SocialForm = (props) => {
  const classes = useStyles();

  const handleChange = (index) => (event) => {
    const { value } = event.target;
    const list = [...props.SocialFormData.Data];
    list[index] = value;
    props.SaveSocialData(list);
  };

  const DeleteSocial = () => {
    const list = [...props.SocialFormData.Data];
    list.pop();
    props.SaveSocialData(list);
    props.ModifySocialCount(props.SocialFormData.Count - 1);
  };

  const AddSocial = () => {
    const list = [...props.SocialFormData.Data];
    list.push(null);
    props.SaveSocialData(list);

    props.ModifySocialCount(props.SocialFormData.Count + 1);
  };
  let Form = [];
  for (let i = 0; i < props.SocialFormData.Count; i++) {
    Form.push(
      <div className={classes.instance}>
        <Grid container spacing={3} className="resume">
          <Grid item md={12} xs={12}>
            <TextField
              required
              fullWidth
              name={`Social`}
              defaultValue={
                props.SocialFormData.Data && props.SocialFormData.Data[i]
                  ? props.SocialFormData.Data[i]
                  : ""
              }
              label="Digital Presence"
              onChange={handleChange(i)}
              variant="outlined"
              formControlProps={{
                fullWidth: true,
              }}
            />
          </Grid>
        </Grid>
        <Divider />
      </div>
    );
  }

  return (
    <>
      <form autoComplete="off" noValidate>
        <Card className={classes.root}>
          <p className={classes.heading}>
            Add social links like linkedin , github etc
          </p>
          {Form.map((instance) => instance)}
          <div className={classes.footer}>
            <Button
              disabled={props.SocialFormData.Count < 2}
              className={classes.deleteButton}
              onClick={DeleteSocial}
              variant="outlined"
              color="secondary"
            >
              Delete Social
            </Button>
            <Button
              className={classes.addButton}
              onClick={AddSocial}
              variant="contained"
            >
              ADD Social
            </Button>
          </div>
        </Card>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  SocialFormData: state.Social,
});

export default connect(mapStateToProps, { SaveSocialData, ModifySocialCount })(
  SocialForm
);
