import React, { useState } from "react";
import "./RegisterComponent.css";
import { TextField, Typography } from "@mui/material";
import { Paper, Button, Autocomplete } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ADD_ANONYMOUS_INST, SERVER_URL } from "../../../apiRoutes";

const RegisterComponent = () => {
  const [univ, setUniv] = useState("");
  const formik = useFormik({
    initialValues: {
      inst_user: "",
      inst_email: "",
      inst_name: "",
      drive_criteria: 0,
      inst_loc: "",
      inst_pincode: 0,
      inst_mobile: 0,
    },
    onSubmit: (values) => {
      const {
        inst_user,
        inst_email,
        inst_name,
        drive_criteria,
        inst_loc,
        inst_pincode,
        inst_mobile,
      } = values;

      const inst_university = univ;
      // console.log(values);
      // console.log(univ);

      axios
        .post(`${SERVER_URL}/${ADD_ANONYMOUS_INST}`, {
          inst_user,
          inst_email,
          inst_name,
          drive_criteria,
          inst_university,
          inst_pincode,
          inst_loc,
          inst_mobile,
        })
        .then((res) => {
          if (res.status === 422 || !res.data) {
            toast.error(res.data, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 202) {
            toast.success(res.data, {
              position: toast.POSITION.TOP_CENTER,
            });
            localStorage.setItem("user", JSON.stringify(res.data));
          }
        })
        .catch((err) => {
          toast.error(err.response.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    },
    validationSchema: Yup.object({
      inst_user: Yup.string().required("required"),
      inst_email: Yup.string()
        .email("Invalid Email Address")
        .required("required"),
      inst_name: Yup.string().required("required"),
      drive_criteria: Yup.string().required("required"),
      inst_loc: Yup.string().required("required"),
      inst_pincode: Yup.string().required("required"),
      inst_mobile: Yup.number()
        .required("required")
        .min(10, "Invalid Contact no."),
    }),
  });

  // const setData = (e) => {
  //     setInputVal( (preVal) => {
  //         return {
  //             ...preVal,
  //             [e.target.name] : e.target.value
  //         }
  //     })
  // }

  const topUniversities = [
    {
      label: "Rajiv Gandhi Proudyogiki Vishwavidyalaya",
      value: "Rajiv Gandhi Proudyogiki Vishwavidyalaya",
    },
    { label: "Medicaps" },
    { label: "Sage" },
    { label: "Indian Institute of Science" },
    { label: "Jawaharlal Nehru University	" },
    { label: "Jamia Millia Islamia" },
    { label: "Jadavpur University" },
    { label: "Amrita Vishwa Vidyapeetham" },
    { label: "Banaras Hindu University" },
    { label: "Vellore Institute of Technology" },
    { label: "University of Hyderabad" },
    { label: "Savitribai Phule Pune University" },
    { label: "Chandigarh University" },
    { label: "Symbiosis International" },
    { label: "Dr. D. Y. Patil Vidyapeeth" },
  ];

  // console.log(formik.values);

  return (
    <>
      <ToastContainer />

      <Paper elevation={3} className="registerForm">
        {/* <p className="registerHead" ><span><AccountCircleIcon sx={{fontSize : "80px"}} /></span></p>     */}
        <h2 style={{ color: "#0E5E6F", margin: "30px auto 50px auto" }}>
          Get In Touch!!
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <TextField
                id="outlined-basic"
                label="TPO Name"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.inst_user}
                onBlur={formik.handleBlur}
                name="inst_user"
                className="name userInput"
              />
              {formik.touched.inst_user && formik.errors.inst_user ? (
                <div>
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_user}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col>
              <TextField
                id="outlined-basic"
                onChange={formik.handleChange}
                value={formik.values.inst_email}
                onBlur={formik.handleBlur}
                name="inst_email"
                label="Institution Email"
                variant="outlined"
                className="email userInput"
              />
              {formik.touched.inst_email && formik.errors.inst_email ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_email}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <TextField
                id="outlined-basic"
                label="Institute Name"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="inst_name"
                value={formik.values.inst_name}
                className="institution userInput"
              />
              {formik.touched.inst_name && formik.errors.inst_name ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_name}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col>
              <TextField
                id="outlined-basic"
                type="number"
                label="Campus Criteria Policy"
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="drive_criteria"
                className="code userInput"
              />
              {formik.touched.drive_criteria && formik.errors.drive_criteria ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.drive_criteria}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <Autocomplete
                onChange={(event, value) => setUniv(value.label)}
                id="university_name"
                name="inst_university"
                value={formik.values.inst_university}
                options={topUniversities}
                onBlur={formik.handleBlur}
                isOptionEqualToValue={(option, value) =>
                  option.iso === value.iso
                }
                renderInput={(params) => (
                  <TextField {...params} label="University" />
                )}
              />
              {formik.touched.inst_university &&
              formik.errors.inst_university ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_university}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col>
              <TextField
                id="outlined-basic"
                label="Institution Location"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.inst_loc}
                onBlur={formik.handleBlur}
                name="inst_loc"
                className="location userInput"
              />
              {formik.touched.inst_loc && formik.errors.inst_loc ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_loc}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <TextField
                id="outlined-basic"
                label="Location Pincode"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.inst_pincode}
                onBlur={formik.handleBlur}
                name="inst_pincode"
                className="contact userInput"
              />
              {formik.touched.inst_pincode && formik.errors.inst_pincode ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_pincode}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col>
              <TextField
                id="outlined-basic"
                label="Contact No."
                variant="outlined"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.inst_mobile}
                onBlur={formik.handleBlur}
                name="inst_mobile"
                className="contact userInput"
              />
              {formik.touched.inst_mobile && formik.errors.inst_mobile ? (
                <div
                  style={{
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      marginBottom: "10px",
                      marginTop: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.inst_mobile}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Button variant="outlined" className="submitBtn" type="submit">
            Join Us
          </Button>
        </form>
      </Paper>
    </>
  );
};

export default RegisterComponent;
