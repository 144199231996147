import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./StudentDashboardComponent.css";
import NavbarComponent from "../../common/navbar/NavbarComponent";
import SideNavComponent from "../../common/sideNav/SideNavComponent";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationComponent from "../../common/ConfirmationComponent";
import { GET_STUDENT_DATA, SERVER_URL } from "../../../apiRoutes";

const StudentDashboardComponent = (props) => {
  const navigate = useNavigate();
  const { id, inst_id } = useParams("");
  const [user, setUser] = useState([]);
  const [error, setError] = useState(false);
  const user_role = "student";

  const getUser = async () => {
    axios
      .get(`${SERVER_URL}/${GET_STUDENT_DATA}/${id}/${inst_id}`)
      .then(async (res) => {
        if (res.status === 422 || !res.data) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          const data = await res.data;
          setUser(data);
          data.backlogs = JSON.stringify(data.backlogs);
          data.current_year = JSON.stringify(data.current_year);
          data.tenth_passing_year = JSON.stringify(data.tenth_passing_year);
          data.twelve_passing_year = JSON.stringify(data.twelve_passing_year);
          data.tenth_percentage = JSON.stringify(data.tenth_percentage);
          data.twelve_percentage = JSON.stringify(data.twelve_percentage);
          data.ug_per = JSON.stringify(data.ug_per);

          if (
            !data.current_year ||
            !data.backlogs ||
            !data.gender ||
            !data.tenth_board ||
            !data.tenth_institute_name ||
            !data.tenth_passing_year ||
            !data.tenth_percentage ||
            !data.twelve_board ||
            !data.twelve_institute_name ||
            !data.twelve_passing_year ||
            !data.twelve_percentage ||
            !data.ug_per
          ) {
            setError(true);
            setTimeout(() => {
              navigate(`/student/${id}/${inst_id}/student-profile/education`);
            }, 3000);
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  const { Children } = props;

  return (
    <>
      {error ? (
        <ConfirmationComponent
          data="Kindly complete your academics information!"
          children="error"
        />
      ) : (
        ""
      )}
      <ToastContainer />
      <NavbarComponent data={user} role={user_role} />

      <SideNavComponent data={user} role={user_role} children={Children} />
    </>
  );
};

export default StudentDashboardComponent;
