import React, { useState, useEffect } from "react";
import "./StudentTpoFeedComponent.css";
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import SouthIcon from "@mui/icons-material/South";
import axios from "axios";
import { useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import NoDataFound from "../noDataFound";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_TPO_STUDENT_ALL_DRIVES,
  DELETE_TPO_OWN_DRIVE,
  SERVER_URL
} from "../../../apiRoutes";

const StudentTpoFeedsComponent = (props) => {
  const [drives, setDrives] = useState([]);
  const { inst_id } = useParams("");
  const { userRole } = props;

  const getAllPlacementDrives = async () => {
    axios
      .get(`${SERVER_URL}/${GET_TPO_STUDENT_ALL_DRIVES}/${inst_id}`)
      .then(async (res) => {
        if (res.status === 202) {
          const data = await res.data;
          // console.log(data);
          data.map((drive) => {
            drive.drive_creator_id = drive.drive_creator_id.toString();
            // for multiple batch
            drive.batch = drive.batch.replace("[", "");
            drive.batch = drive.batch.replace("]", "");
            drive.batch = drive.batch.split(",").join(",  ");
            // for multiple branch
            drive.branch = drive.branch.replace("[", "");
            drive.branch = drive.branch.replace("]", "");
            drive.branch = drive.branch.split('"').join(" ");
            drive.branch = drive.branch.split(",").join(", ");

            // for multiple gender
            drive.gender = drive.gender.replace("[", "");
            drive.gender = drive.gender.replace("]", "");
            drive.gender = drive.gender.split('"').join(" ");
            drive.gender = drive.gender.split(",").join(", ");

            drive.drive_description = JSON.parse(drive.drive_description);
            drive.profile_designation = drive.profile_designation.split(",");
            drive.profile_package = drive.profile_package.split(",");
            drive.req_skills = drive.req_skills.split("[").join("");
            drive.req_skills = drive.req_skills.split("],").join("|");
            drive.req_skills = drive.req_skills.split('"').join("");
            drive.req_skills = drive.req_skills.split("]").join("");
            drive.req_skills = drive.req_skills.split("|");
            drive.req_skills.map((skill, ind) => {
              if (skill === ",") {
                drive.req_skills = drive.req_skills[ind].replace(",", "");
              }
              return (drive.req_skills[ind] = drive.req_skills[ind].split(","));
            });
            return drive;
          });
          setDrives(data);
        } else if (res.status === 422) {
          toast.error("OOPs! Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  // const getCompanyLogo = (comp_name) => {
  //   comp_name = comp_name.replace(/ /g,'');
  //   comp_name = comp_name + '.com';
  //   console.log(comp_name);
  //   axios.get(`/company-logo/${comp_name}`).then((res) => {
  //     console.log(res.data);
  //       if(res.status === 202){
  //         return res.data;
  //       } else if(res.status === 422){
  //         return null;
  //       }
  //   }).catch((err) => {
  //     console.log(err);
  //     return null;
  //   })
  // }

  const deletePlacementDrive = (drive_id) => {
    // console.log(drive_id);
    axios
      .delete(`${SERVER_URL}/${DELETE_TPO_OWN_DRIVE}/${drive_id}`)
      .then(async (res) => {
        if (res.status === 202) {
          getAllPlacementDrives();
          toast.success("Placement Drive deleted successfully!!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  // console.log(drives);

  useEffect(() => {
    getAllPlacementDrives();
  }, []);
  return (
    <>
      <ToastContainer />
      {drives.length === 0 ? <NoDataFound /> : ""}
      {drives.map((drive) => (
        <>
          <Paper
            elevation={3}
            sx={{ bgcolor: "#D6E5E3" }}
            className="drive-feed"
          >
            <Row className="tpo-detail">
              <Col xs={1}>
                {drive.profile_pic === null ||
                drive.profile_pic === undefined ? (
                  <Avatar sx={{ bgcolor: deepOrange[500] }}>
                    {drive.drive_creator_name[0]}
                  </Avatar>
                ) : (
                  <div>
                    <img
                      src={drive.profile_pic}
                      alt="profile_pic"
                      className="feed-tpo-bar"
                    />
                  </div>
                )}
              </Col>
              <Col xs={3}>
                <p style={{ marginTop: "5px" }}>{drive.drive_creator_name}</p>
              </Col>
              {userRole === "tpo" ? (
                <Col>
                  <Button
                    sx={{ float: "right" }}
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      deletePlacementDrive(drive.drive_id);
                    }}
                  >
                    Delete
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col xs={3} className="company-logo">
                {/* <img src={CompanyImage} alt="company" /> */}
                {/* {
                        getCompanyLogo(drive.comp_name) === null ? 
                          <Avatar sx={{ bgcolor: '#013c54f7', margin:'auto', marginBottom: '20px', textAlign:'center', width:'100px', height:'100px'}}>
                            <span style={{fontSize:'50px'}}>{drive.comp_name[0].toUpperCase()}</span>
                          </Avatar>
                          :
                          <img src={getCompanyLogo(drive.comp_name)} style={{height:'100px', width:'100px', backgroundSize:'cover'}} alt={drive.comp_name} />
                      } */}
                <Avatar
                  sx={{
                    bgcolor: "#013c54f7",
                    margin: "auto",
                    marginBottom: "20px",
                    textAlign: "center",
                    width: "100px",
                    height: "100px",
                  }}
                >
                  <span style={{ fontSize: "50px" }}>
                    {drive.comp_name[0].toUpperCase()}
                  </span>
                </Avatar>

                <Row sx={{ marginBottom: "20px" }} component={Paper}>
                  <Col xs={2} className="skills-box">
                    <p className="skill-requirement-text">Skill Required</p>
                    {drive.req_skills.map((skill, ind) => (
                      <>
                        <Col>
                          <Paper
                            sx={{ padding: "20px", marginBottom: "15px" }}
                            elevation={2}
                          >
                            <p>{drive.profile_designation[ind]}</p>
                            {skill.map((skillName) => (
                              <>
                                <Divider />
                                <List
                                  sx={{
                                    width: "100%",
                                    bgcolor: "background.paper",
                                    textAlign: "center",
                                  }}
                                >
                                  <ListItem>
                                    <ListItemText primary={skillName} />
                                  </ListItem>
                                </List>
                              </>
                            ))}
                          </Paper>
                        </Col>
                      </>
                    ))}
                  </Col>
                </Row>
              </Col>
              <Col className="drive-detail">
                <TableContainer sx={{ marginBottom: "20px" }} component={Paper}>
                  <p className="comp-name">{drive.comp_name}</p>
                  <Table
                    sx={{ minWidth: "300px", margin: "auto" }}
                    aria-label="customized table"
                  >
                    <TableBody>
                      <TableRow key="comp_name">
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "300px" }}
                        >
                          Eligible Course
                        </TableCell>
                        <TableCell align="left">{drive.drive_course}</TableCell>
                      </TableRow>
                      <TableRow key="profile_designation">
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "300px" }}
                        >
                          Eligible Batch
                        </TableCell>
                        <TableCell align="left">{drive.batch}</TableCell>
                      </TableRow>
                      <TableRow key="drive_package">
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "300px" }}
                        >
                          Eligible Branch
                        </TableCell>
                        <TableCell align="left">{drive.branch}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TableContainer sx={{marginBottom: '20px'}} component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                          <TableBody>
                            {companyBasicDetails.map((row) => (
                              <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row" sx={{width: '300px'}}>{row.name}</StyledTableCell>
                                <StyledTableCell align="left">{row.value}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer> */}
                <Row>
                  <Col>
                    <TableContainer
                      sx={{ marginBottom: "20px" }}
                      component={Paper}
                    >
                      <p className="comp-name">Profile Designation</p>
                      <Table
                        sx={{ minWidth: "300px" }}
                        aria-label="customized table"
                      >
                        <TableBody>
                          {drive.profile_designation.map((profile, ind) => (
                            <TableRow key={profile}>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: "300px" }}
                              >
                                {profile}
                              </TableCell>
                              <TableCell align="left">
                                {drive.profile_package[ind]} LPA
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>
                </Row>
                <Row>
                  <Col sx={{ margin: "auto" }}>
                    <TableContainer
                      sx={{ marginBottom: "20px" }}
                      component={Paper}
                    >
                      <p className="comp-name">Eligibility Criteria</p>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableBody>
                          <TableRow key="tenth_per">
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ width: "300px" }}
                            >
                              10th %
                            </TableCell>
                            <TableCell align="left">
                              {drive.tenth_eligibility}
                            </TableCell>
                          </TableRow>
                          <TableRow key="twelve_per">
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ width: "300px" }}
                            >
                              12th %
                            </TableCell>
                            <TableCell align="left">
                              {drive.twelve_eligibility}
                            </TableCell>
                          </TableRow>
                          <TableRow key="current_cgpa">
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ width: "300px" }}
                            >
                              Current CGPA
                            </TableCell>
                            <TableCell align="left">{drive.ug_per}</TableCell>
                          </TableRow>
                          <TableRow key="backlogs">
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ width: "300px" }}
                            >
                              Backlogs
                            </TableCell>
                            <TableCell align="left">{drive.backlog}</TableCell>
                          </TableRow>
                          <TableRow key="gender">
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ width: "300px" }}
                            >
                              Gender
                            </TableCell>
                            <TableCell align="left">{drive.gender}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <TableContainer sx={{marginBottom: '20px'}} component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                              <TableBody>
                                {driveEligibility.map((row) => (
                                  <StyledTableRow key={row.name}>
                                    <StyledTableCell component="th" scope="row" sx={{width: '300px'}}>{row.name}</StyledTableCell>
                                    <StyledTableCell align="left">{row.value}</StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer> */}
                    <Row>
                      <Col>
                        <Paper sx={{ padding: "30px" }}>
                          <p
                            className="hiring-flow-heading"
                            sx={{ marginBottom: "30px" }}
                          >
                            Hiring Flow
                          </p>
                          <Paper
                            elevation={3}
                            sx={{
                              width: "300px",
                              height: "fit-content",
                              padding: "10px",
                              textAlign: "center",
                              margin: "auto",
                            }}
                            className="hiring-step-box"
                          >
                            <h6>Start</h6>
                          </Paper>
                          <SouthIcon
                            sx={{
                              fontSize: "30px",
                              margin: "10px 48%",
                              color: "gray",
                            }}
                          />

                          {drive.drive_description.map((desc) => (
                            <>
                              <Paper
                                elevation={3}
                                sx={{
                                  width: "300px",
                                  height: "fit-content",
                                  padding: "10px",
                                  textAlign: "center",
                                  margin: "auto",
                                }}
                                className="hiring-step-box"
                              >
                                <p style={{ fontSize: "14px" }}>{desc.title}</p>
                                <h6 style={{ color: "#5f6c79" }}>
                                  {desc.content}
                                </h6>
                              </Paper>
                              <SouthIcon
                                sx={{
                                  fontSize: "30px",
                                  margin: "10px 48%",
                                  color: "gray",
                                }}
                              />
                            </>
                          ))}
                          <Paper
                            elevation={3}
                            sx={{
                              width: "300px",
                              height: "fit-content",
                              padding: "10px",
                              textAlign: "center",
                              margin: "auto",
                            }}
                            className="hiring-step-box"
                          >
                            <h6>End</h6>
                          </Paper>
                        </Paper>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Paper>
        </>
      ))}
    </>
  );
};

export default StudentTpoFeedsComponent;
