import React, { useState, useEffect } from "react";
import "../tpo/driveHistory/DriveHistory.css";
import { alpha, styled } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  DataGrid,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Button, Divider, FormControl, Typography, InputLabel, Select, MenuItem, TextField, Dialog, DialogTitle} from "@mui/material";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GET_TPO_DATA, SERVER_URL } from "../../apiRoutes";
import { ToastContainer, toast } from "react-toastify";

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport sx={{ margin: "10px 20px 10px auto" }} />
      <Divider />
    </GridToolbarContainer>
  );
}

const PlacementHistory = () => {
  const navigate = useNavigate();
  const { id, inst_id } = useParams("");
  const [userDriveHistory, setUserDriveHistory] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [driveId, setDriveId] = useState(0);
  const [profileDesignation, setProfileDesignation] = useState([]);

  const handleClickOpen = (drive_id, profile_designation) => {
    setDriveId(drive_id);
    setProfileDesignation(profile_designation);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const result = (params) => {
    const drive_id = params.row.id;
    const data = {
      comp_name: params.row.comp_name,
    };
    return (
      <Button
        variant="outlined"
        className="show-result-btn"
        onClick={() => navigate(`${drive_id}/drive-result`, { state: data })}
      >
        Show Result
      </Button>
    );
  };
  const addResult = (params) => {
    // console.log(profileDesignation);
    return (
      <>
        <Button
          variant="outlined"
          className="add-result-btn"
          onClick={() =>
            handleClickOpen(params.row.id, params.row.profile_designation)
          }
        >
          Add Result
        </Button>
      </>
    );
  };

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "comp_name",
      headerName: "Company",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "profile_designation",
      headerName: "Profile Designation",
      flex: 3,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "drive_create_date",
      headerName: "Created At",
      flex: 3,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "batch",
      headerName: "Batch",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "drive_course",
      headerName: "Course",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "branch",
      headerName: "Branch",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "result",
      renderCell: result,
      headerName: "Result",
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
    {
      field: "addResult",
      renderCell: addResult,
      headerName: "Add Result",
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
  ];

  const getTpoDriveHistory = () => {
    axios
      .get(`${SERVER_URL}/${GET_TPO_DATA}/${id}/${inst_id}/drive-history`)
      .then(async (res) => {
        if (res.status === 422 || !res.data) {
          //   console.log("Error");
          toast.error("OOPs! Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          const data = await res.data;
          data.map((drive) => {
            drive.comp_name = drive.comp_name.toUpperCase();

            // drive.drive_package = drive.drive_package + ' LPA';
            // drive.batch = JSON.parse(drive.batch);
            // drive.branch = JSON.parse(drive.branch);

            // for multiple batch
            drive.batch = drive.batch.replace("[", "");
            drive.batch = drive.batch.replace("]", "");
            drive.batch = drive.batch.split(",").join(",  ");
            // for multiple branch
            drive.branch = drive.branch.replace("[", "");
            drive.branch = drive.branch.replace("]", "");
            drive.branch = drive.branch.split('"').join(" ");
            drive.branch = drive.branch.split(",").join(", ");

            drive.profile_designation = drive.profile_designation.split(",");

            const date = new Date(drive.drive_create_date).toLocaleDateString();
            const time = new Date(drive.drive_create_date).toLocaleTimeString();
            drive.drive_create_date = date + " , " + time;

            return drive;
          });
          setUserDriveHistory(data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  // console.log(userDriveHistory);

  useEffect(() => {
    getTpoDriveHistory();
  }, []);

  return (
    <>
      <ToastContainer />
      <div
        style={{
          height: "70vh",
          width: "90%",
          marginTop: "100px",
          marginLeft: "5%",
        }}
      >
        <StripedDataGrid
          className="drive-history-table"
          rows={userDriveHistory}
          pageSize={10}
          columns={columns}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
      <AddStudent
        selectedValue={selectedValue}
        profile={profileDesignation}
        drive_id={driveId}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

const AddStudent = (props) => {
  let { onClose, selectedValue, open, drive_id, profile } = props;
  console.log(drive_id);
  const { id, inst_id } = useParams("");

  const formik = useFormik({
    initialValues: {
      enrollment_no: "",
      placed_package: "",
      profileDesg: "",
    },
    onSubmit: (values) => {
      //   console.log("adding a student");
      const { enrollment_no, placed_package, profileDesg } = values;

      axios
        .post(`${SERVER_URL}/tpo/${id}/${inst_id}/add-result-for/${drive_id}`, {
          enrollment_no,
          profileDesg,
          placed_package,
        })
        .then(async (res) => {
          //   console.log(res.data);
          if (res.status === 422) {
            toast.error(res.data, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 202) {
            // console.log(res.data);
            toast.success('Added successfully!', {
              position: toast.POSITION.TOP_CENTER,
            });
            window.location.reload(false);
          }
        })
        .catch((err) => {
          toast.error(err.response.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    },
    validationSchema: Yup.object({
      enrollment_no: Yup.string().required("required"),
      placed_package: Yup.number("Must be an Numeric value").required(
        "required"
      ),
      profileDesg: Yup.string().required("required"),
    }),
  });

  const handleClose = () => {
    onClose(selectedValue);
  };

  //   console.log(formik.values);

  return (
    <>
      {/* {error ? <ConfirmationComponent data={resMsg} children={resIcon} /> : ""} */}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ textAlign: "center" }} color="secondary">
          Add Student Detail
        </DialogTitle>

        <form onSubmit={formik.handleSubmit} className="add-skill-dialog">
          <div>
            <TextField
              id="outlined-basic"
              label="Enrollment Number"
              value={formik.values.enrollment_no}
              onBlur={formik.handleBlur}
              name="enrollment_no"
              onChange={formik.handleChange}
              variant="outlined"
            />
            {formik.touched.enrollment_no && formik.errors.enrollment_no ? (
              <div>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.enrollment_no}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </div>
          <FormControl fullWidth>
            <InputLabel
              onBlur={formik.handleBlur}
              id="demo-simple-select-label"
            >
              Select Profile Designation
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="profileDesg"
              value={formik.values.profileDesg}
              label="Select Profile Designation"
              color="secondary"
              onChange={formik.handleChange}
            >
              {profile.map((prof, index) => (
                <MenuItem key={index} value={prof}>
                  {prof}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.profileDesg && formik.errors.profileDesg ? (
              <div>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.profileDesg}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </FormControl>
          <div>
            <TextField
              id="outlined-basic"
              label="Package"
              value={formik.values.placed_package}
              onBlur={formik.handleBlur}
              name="placed_package"
              onChange={formik.handleChange}
              type="number"
              variant="outlined"
            />
            {formik.touched.placed_package && formik.errors.placed_package ? (
              <div>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.placed_package}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </div>
          <Button
            variant="contained"
            style={{ width: "50%", margin: "auto", marginTop: 50 }}
            color="success"
            type="submit"
          >
            Save
          </Button>
        </form>
      </Dialog>
    </>
  );
};

AddStudent.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default PlacementHistory;
