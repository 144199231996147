import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Stepper, Step, StepButton, Button } from "@material-ui/core";
import ProfileForm from "./ProfileForm";
import EducationForm from "./EducationForm";
import SkillsForm from "./SkillsForm";
import SocialForm from "./SocialForm";
import ProjectsForm from "./ProjectsForm";
import Resume from "../resume/Index";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ConfirmationComponent from "../../../modules/common/ConfirmationComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    margin: "auto",
    marginTop: "20px",
  },
  contentRow: {
    marginTop: "20px",
  },
  stepNavigationButton: {
    display: "flex",
    color: "#044f6f",
    marginTop: "25vh",
  },
  continueBtn: {
    marginTop: "50px",
    float: "right",
    backgroundColor: "#044f6f",
    color: "white",
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: "40px",
    marginBottom: "40px",
    display: "flex",
  },
}));

const getSteps = () => {
  return [
    "Profile Section",
    "Academic Section",
    "Skills Section",
    "Project Section",
    "Social Accounts",
  ];
};

const getStepContent = (step) => {
  switch (step) {
    case 0:
      return <ProfileForm />;
    case 1:
      return <EducationForm />;
    case 2:
      return <SkillsForm />;
    case 3:
      return <ProjectsForm />;
    case 4:
      return <SocialForm />;
    default:
      return "Unknown step";
  }
};

const ResumeForm = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [error, setError] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [resIcon, setResIcon] = useState("");
  const steps = getSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const ValidateProfileDetails = () => {
    if (!props.profileData) {
      return false;
    }
    if (
      !props.profileData.Data.fname ||
      !props.profileData.Data.lname ||
      !props.profileData.Data.phone ||
      !props.profileData.Data.address
    ) {
      // alert("Please fill all the data");
      setError(true);
      setResMsg("Incomplete Data");
      setResIcon(<ErrorOutlineIcon sx={{ fontSize: "50px", color: "red" }} />);
      return false;
    }

    if (
      props.profileData.Data.fname.length < 1 ||
      props.profileData.Data.lname.length < 1 ||
      props.profileData.Data.address.length < 1
    ) {
      // alert("Pleasej fill all the data. ")
      setError(true);
      setResMsg("Incomplete Data");
      setResIcon(<ErrorOutlineIcon sx={{ fontSize: "50px", color: "red" }} />);
      return false;
    }

    if (props.profileData.Data.phone.length !== 10) {
      // alert("Enter a valid phone number.")
      setError(true);
      setResMsg("Invalid phone number");
      setResIcon(<ErrorOutlineIcon sx={{ fontSize: "50px", color: "red" }} />);
      return false;
    }
    return true;
  };

  const validateEducationDetails = () => {
    if (!props.educationFormData) return false;
    const Data = props.educationFormData.Data;
    for (let i = 0; i < Data.length; i++) {
      const instance = Data[i];
      if (
        !instance.courseName ||
        !instance.completionYear ||
        !instance.college ||
        !instance.percentage
      ) {
        // alert("Please fill all the data")
        setError(true);
        setResMsg("Incomplete Data");
        setResIcon(
          <ErrorOutlineIcon sx={{ fontSize: "50px", color: "red" }} />
        );
        return false;
      }

      if (
        instance.courseName.length < 1 ||
        instance.completionYear.length !== 4 ||
        instance.college.length < 1 ||
        instance.percentage.length < 1
      ) {
        // alert("Incomplete or invalid data")
        setError(true);
        setResMsg("Incomplete Data");
        setResIcon(
          <ErrorOutlineIcon sx={{ fontSize: "50px", color: "red" }} />
        );
        return false;
      }
    }
    return true;
  };

  const validateProjectDetails = () => {
    if (!props.projectFormData) return false;
    const Data = props.projectFormData.Data;
    for (let i = 0; i < Data.length; i++) {
      const instance = Data[i];
      if (!instance.projectName) {
        // alert("Please enter the name of project")
        setError(true);
        setResMsg("Incomplete project name");
        setResIcon("error");
        return false;
      }

      if (instance.projectName.length < 1) {
        // alert("Please enter the name of project")
        setError(true);
        setResMsg("Invalid project name");
        setResIcon("error");
        return false;
      }
    }
    return true;
  };

  const validateSkills = () => {
    console.log(props.SkillsFormData.Data.length);
    if (props.SkillsFormData.Data.length < 1) {
      // alert("Please enter your skill")
      setError(true);
      setResMsg("Incomplete Data");
      setResIcon("error");
      return false;
    }
    for (let i = 0; i < props.SkillsFormData.Data.length; i++) {
      console.log(props.SkillsFormData.Data[i]);
      if (
        !props.SkillsFormData.Data[i] ||
        (props.SkillsFormData.Data[i] &&
          props.SkillsFormData.Data[i].length < 1)
      ) {
        // alert("Please fill all skills")
        setError(true);
        setResMsg("Incomplete Data");
        setResIcon("error");
        return false;
      }
    }
    return true;
  };

  const validateSocialLinks = () => {
    if (props.SocialFormData.Data.length < 1) {
      // alert("Please enter your social url")
      setError(true);
      setResMsg("Incomplete Social Url");
      setResIcon("error");
      return false;
    }
    for (let i = 0; i < props.SocialFormData.Data.length; i++) {
      if (
        !props.SocialFormData.Data[i] ||
        (props.SocialFormData.Data[i] &&
          props.SocialFormData.Data[i].length < 1)
      ) {
        // alert("Please fill all urls")
        setError(true);
        setResMsg("Incomplete social url");
        setResIcon("error");
        return false;
      }
    }
    return true;
  };

  const handleComplete = () => {
    let flag = true;
    console.log(activeStep);
    const action = getSteps()[activeStep];

    if (action === "Profile Section") {
      flag = ValidateProfileDetails();
    } else if (action === "Education Section") {
      flag = validateEducationDetails();
    } else if (action === "Mini Project") {
      flag = validateProjectDetails();
    } else if (action === "Skills Sector") {
      flag = validateSkills();
    } else if (action === "Social") {
      flag = validateSocialLinks();
    }

    if (flag) {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    }
  };

  const handleReset = () => {
    window.location.reload(false);
  };

  const handleEdit = () => {
    setCompleted({});
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      {error ? <ConfirmationComponent data={resMsg} children={resIcon} /> : ""}
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton
              color="secondary"
              onClick={handleStep(index)}
              completed={completed[index]}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <div>
            <Button
              onClick={handleReset}
              style={{ margin: " 50px auto 50px 6%" }}
              variant="outlined"
              color="secondary"
            >
              Reset
            </Button>
            <Button
              onClick={handleEdit}
              style={{ margin: "50px auto 50px 30px" }}
              variant="outlined"
              color="secondary"
            >
              Edit
            </Button>
            <Resume style={{ marginTop: "100px" }} />
          </div>
        ) : (
          <>
            <Row className={classes.contentRow}>
              <Col xs={1}>
                <Button
                  disabled={activeStep === 0}
                  variant="outlined"
                  onClick={handleBack}
                  className={classes.stepNavigationButton}
                >
                  <KeyboardArrowLeft />
                </Button>
              </Col>
              <Col xs={10}>
                <div>{getStepContent(activeStep)}</div>
              </Col>
              <Col xs={1}>
                <Button
                  variant="outlined"
                  onClick={handleNext}
                  className={classes.stepNavigationButton}
                >
                  <KeyboardArrowRight />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className={classes.continueBtn}
                  variant="contained"
                  onClick={handleComplete}
                >
                  {completedSteps() === totalSteps() - 1
                    ? "Finish"
                    : "Save and Continue"}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  educationFormData: state.Education,
  profileData: state.Profile,
  projectFormData: state.Project,
  SkillsFormData: state.Skills,
  SocialFormData: state.Social,
});

export default connect(mapStateToProps, {})(ResumeForm);
