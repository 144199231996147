import React from "react";
import { connect } from "react-redux";
import ResumePattern from "./Resume";

const MainPage = () => {
  return (
    <div>
      <ResumePattern />
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(MainPage);
