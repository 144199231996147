import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import TextsmsIcon from "@mui/icons-material/Textsms";
import PostAddIcon from "@mui/icons-material/PostAdd";
import BusinessIcon from "@mui/icons-material/Business";
import Person3Icon from "@mui/icons-material/Person3";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { GET_EMAIL_SMS_DRIVE_COUNT, SERVER_URL } from "../../apiRoutes";
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import UserPieChart from "./userPieChart";

const data = [
  {
    name: "Page A",
    Email: 4000,
    SMS: 2400,
    Drive: 2400,
  },
  {
    name: "Page B",
    Email: 3000,
    SMS: 1398,
    Drive: 2210,
  },
  {
    name: "Page C",
    Email: 2000,
    SMS: 9800,
    Drive: 2290,
  },
  {
    name: "Page D",
    Email: 2780,
    SMS: 3908,
    Drive: 2000,
  },
  {
    name: "Page E",
    Email: 1890,
    SMS: 4800,
    Drive: 2181,
  },
  {
    name: "Page F",
    Email: 2390,
    SMS: 3800,
    Drive: 2500,
  },
  {
    name: "Page G",
    Email: 3490,
    SMS: 4300,
    Drive: 2100,
  },
];

const DashboardContent = () => {
  const [institutionCount, setInstitutionCount] = useState(0);
  const [driveCount, setDriveCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);

  const getBasicCountData = () => {
    axios
      .get(`${SERVER_URL}/${GET_EMAIL_SMS_DRIVE_COUNT}`)
      .then((res) => {
        if (res.status === 202) {
          // console.log(res.data);
          setDriveCount(res.data.drive.drive);
          setInstitutionCount(res.data.institution.institution);
          setStudentCount(res.data.student.student);
        } else if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    getBasicCountData();
  }, []);

  return (
    <>
      <ToastContainer />
      <Paper
        elevation={3}
        sx={{
          backgroundColor: "white",
          width: "100%",
          padding: "30px 50px",
          marginTop: "25px",
        }}
      >
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <Paper elevation={3} className="summarized-detail">
              <Typography sx={{ display: "inline-block" }}>
                <MarkEmailReadIcon
                  sx={{ fontSize: "40px", color: "#0E5E6F" }}
                />
              </Typography>
              <Typography
                sx={{
                  display: "inline-block",
                  float: "right",
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#d05900",
                }}
              ></Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  color: "#0E5E6F",
                }}
              >
                Emails Sent
              </Typography>
            </Paper>
          </Col>
          <Col>
            <Paper elevation={3} className="summarized-detail">
              <Typography sx={{ display: "inline-block" }}>
                <TextsmsIcon sx={{ fontSize: "40px", color: "#0E5E6F" }} />
              </Typography>
              <Typography
                sx={{
                  display: "inline-block",
                  float: "right",
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#d05900",
                }}
              ></Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  color: "#0E5E6F",
                }}
              >
                SMS Sent
              </Typography>
            </Paper>
          </Col>
          <Col>
            <Paper elevation={3} className="summarized-detail">
              <Typography sx={{ display: "inline-block" }}>
                <PostAddIcon sx={{ fontSize: "40px", color: "#0E5E6F" }} />
              </Typography>
              <Typography
                sx={{
                  display: "inline-block",
                  float: "right",
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#d05900",
                }}
              >
                {driveCount}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  color: "#0E5E6F",
                }}
              >
                Placement Drives
              </Typography>
            </Paper>
          </Col>
          <Col>
            <Paper elevation={3} className="summarized-detail">
              <Typography sx={{ display: "inline-block" }}>
                <BusinessIcon sx={{ fontSize: "40px", color: "#0E5E6F" }} />
              </Typography>
              <Typography
                sx={{
                  display: "inline-block",
                  float: "right",
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#d05900",
                }}
              >
                {institutionCount}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  color: "#0E5E6F",
                }}
              >
                Institution
              </Typography>
            </Paper>
          </Col>
          <Col>
            <Paper elevation={3} className="summarized-detail">
              <Typography sx={{ display: "inline-block" }}>
                <Person3Icon sx={{ fontSize: "40px", color: "#0E5E6F" }} />
              </Typography>
              <Typography
                sx={{
                  display: "inline-block",
                  float: "right",
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#d05900",
                }}
              >
                {studentCount}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  marginTop: "10px",
                  fontWeight: "600",
                  color: "#0E5E6F",
                }}
              >
                Student
              </Typography>
            </Paper>
          </Col>
        </Row>
        <Row style={{ marginTop: "50px" }}>
          <Col xs={7}>
            <Paper elevation={3} sx={{ padding: "20px" }}>
              <ComposedChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="" scale="band" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="Drive"
                  fill="#82ca9d"
                  stroke=""
                />
                <Line
                  type="monotone"
                  dataKey="Email"
                  stroke="#8884d8 "
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="SMS" stroke="#ff7300" />
              </ComposedChart>
            </Paper>
          </Col>
          <Col xs={5}>
            {/* <Paper elevation={3} sx={{padding:'20px'}}> */}
            {/* </Paper> */}
            <UserPieChart />
          </Col>
        </Row>
        <div className="pie-chart-details">
          <ul>
            <li className="pie-chart-list-items">
              <span
                className="pie-chart-list-content"
                style={{ backgroundColor: "#087897de" }}
              ></span>{" "}
              Student
            </li>
            <li className="pie-chart-list-items">
              <span
                className="pie-chart-list-content"
                style={{ backgroundColor: "#344D67" }}
              ></span>{" "}
              Institution
            </li>
            <li className="pie-chart-list-items">
              <span
                className="pie-chart-list-content"
                style={{ backgroundColor: "#44d8f1de" }}
              ></span>{" "}
              TPO
            </li>
          </ul>
        </div>
      </Paper>
    </>
  );
};

export default DashboardContent;
