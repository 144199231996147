export const SERVER_URL = "https://rpc-server.onrender.com";
// export const SERVER_URL = "http://localhost:8083";

// authentication routes
export const LOGIN_ROUTE = `login-user`;
export const FORGOT_PASSWORD = `forgot-password`;
export const REGISTER_ADMIN = `admin-register`;
export const RESET_PASSWORD = `reset-password`;


// common routes
export const GET_TPO_STUDENT_ALL_DRIVES = `all-placement-drives`;
export const DELETE_TPO_OWN_DRIVE = `delete-placement-drive`;


// institute routes-
export const GET_INST_DATA = `institution`;
export const UPLOAD_STUDENT_DATA = `upload-student-data`;
export const UPLOAD_TPO_DATA = `upload-tpo-data`;
export const UPDATE_INST_NAME = `update-institution-name`;
export const UPDATE_INST_MOBILE = `update-institution-mobile`;
export const UPDATE_INST_USER_NAME = `update-institution-user-name`;
export const UPDATE_INST_PASSWORD = `update-institution-password`;
export const UPDATE_INST_UNIV = `update-institution-university`;
export const UPDATE_INST_CAMPUS_POLICY = `update-institution-campus-drive-policy`;
export const UPDATE_INST_PROFILE_PIC = `update-institution-profile-image`;
export const PAYMENT_SUBSCRIBE = `subscribe`;
export const INST_PAYMENT_DETAILS = `get-inst-data-for-subscription`;
export const GET_INST_BILLING_DETAILS = `get-inst-billing-details`;



// admin api routes
export const ADD_ANONYMOUS_INST = `anonymous-institution-joining-rpc`;
export const GET_ADMIN_DATA = `get-admin`;
export const GET_EMAIL_SMS_DRIVE_COUNT = `get-count-emails-sms-sent/count-institution-drives-student`;
export const GET_ALL_PENDING_INST_REQ = `get-all-pending-institution-requests`;
export const APPROVE_PENDING_INST_REQ = `approve-pending-request`;
export const GET_ALL_USERS = `get-all-users`;
export const DELETE_STUDENTS = `admin/delete-students`;
export const DELETE_TPOS = `admin/delete-tpos`;
export const DELETE_INST = `admin/delete-institutions`;
export const ADMIN_GET_USER_DATA = `admin/get-user-data`;



// student routes
export const GET_STUDENT_DATA = `student`;
export const GET_ALL_ELIG_DRIVES = `all-eligible-drives`;
export const UPDATE_STUDENT_NAME = `update-student-name`;
export const UPDATE_STUDENT_EMAIL = `update-student-email`;
export const UPDATE_STUDENT_GENDER = `update-student-gender`;
export const UPDATE_STUDENT_MOBILE = `update-student-mobile`;
export const UPDATE_STUDENT_PASSWORD = `update-student-password`;
export const GET_INDIVIDUAL_PROJECT = `get-student-individual-project`;
export const REMOVE_INDIVIDUAL_PROJECT = `remove-student-individual-project`;
export const ADD_STUDENT_PROJECT = `add-student-project`;
export const GET_INDIVIDUAL_EXPERIENCE = `get-student-individual-experience`;
export const REMOVE_INDIVIDUAL_EXPERIENCE = `remove-student-individual-experience`;
export const ADD_STUDENT_EXPERIENCE = `add-student-experience`;
export const GET_INDIVIDUAL_ACTIVITY = `get-student-individual-extracurricular-activity`;
export const REMOVE_INDIVIDUAL_ACTIVITY = `remove-student-individual-extracurricular-activity`;
export const ADD_STUDENT_ACTIVITY = `add-student-extracurricular-activity`;
export const GET_INDIVIDUAL_CERTIFICATE = `get-student-individual-certificate`;
export const REMOVE_INDIVIDUAL_CERTIFICATE = `remove-student-individual-certificate`;
export const ADD_STUDENT_CERTIFICATE = `add-student-certification`;
export const GET_INDIVIDUAL_SKILLS = `get-student-individual-skill`;
export const REMOVE_INDIVIDUAL_SKILLS = `remove-student-individual-skill`;
export const ADD_STUDENT_SKILLS = `add-student-skill`;
export const UPDATE_CURRENT_YEAR = `update-student-current-year`;
export const UPDATE_GRADUATION_YEAR = `update-student-graduation-year`;
export const UPDATE_CURRENT_CGPA = `update-student-current-cgpa`;
export const UPDATE_CURRENT_BACKLOG = `update-student-current-backlog`;
export const UPDATE_TWELVE_INST_NAME = `update-student-twelve-institute-name`;
export const UPDATE_TWELVE_BOARD = `update-student-twelve-board`;
export const UPDATE_TWELVE_PASS_YEAR = `update-student-twelve-passing-year`;
export const UPDATE_TWELVE_PER = `update-student-twelve-per`;
export const UPDATE_TENTH_INST_NAME = `update-student-tenth-institute-name`;
export const UPDATE_TENTH_BOARD = `update-student-tenth-board`;
export const UPDATE_TENTH_PASS_YEAR = `update-student-tenth-passing-year`;
export const UPDATE_TENTH_PER = `update-student-tenth-per`;
export const UPDATE_STUDENT_PROFILE_PIC = `update-student-profile-image`;



// tpo routes
export const GET_TPO_DATA = `tpo`;
export const GET_AVAILABLE_BATCHES = `tpo/get-available-batches`;
export const GET_AVAILABLE_BRANCHES = `tpo/get-available-branches`;
export const GET_AVAILABLE_COURSES = `tpo/get-available-courses`;
export const GET_AVAILABLE_REQ_SKILLS = `tpo/get-available-skills-req`;
export const CREATE_PLACEMENT_DRIVE = `create-placement-drive`;
export const UPDATE_TPO_NAME = `update-tpo-name`;
export const UPDATE_TPO_MOBILE = `update-tpo-mobile`;
export const UPDATE_TPO_EMAIL = `update-tpo-email`;
export const UPDATE_STUDENT_ELIGIBILITY = `update-student-eligibility-for`;
export const   UPDATE_TPO_PASSWORD = `update-tpo-password`;



  // "proxy": "https://rpc-server.onrender.com/",
