import React from "react";
import { connect } from "react-redux";
import { Paper } from "@mui/material";
import ResumeForm from "./forms/ResumeForm";

const MainPage = () => {
  return (
    <Paper elevation={3} sx={{ padding: "0px 0px 20px 0px" }}>
      <ResumeForm />
    </Paper>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(MainPage);
