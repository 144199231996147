import React, { useState, useEffect } from "react";
import "./EligibilityMessages.css";
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemText,
  List,
  Divider,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  Alert,
  Avatar,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
// import CompanyImage from '../../../assets/company.jfif';
import SouthIcon from "@mui/icons-material/South";
import axios from "axios";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NoDataFound from "../../common/noDataFound";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_ALL_ELIG_DRIVES, SERVER_URL } from "../../../apiRoutes";

const ELigibilityMessages = () => {
  const [drives, setDrives] = useState([]);
  const { id, inst_id } = useParams("");
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const getAllPlacementDrives = async () => {
    axios
      .get(`${SERVER_URL}/${GET_ALL_ELIG_DRIVES}/${id}/${inst_id}`)
      .then(async (res) => {
        if (res.status === 202) {
          const data = await res.data.data;
          // console.log(data);

          data.map((drive) => {
            drive.drive_creator_id = drive.drive_creator_id.toString();
            // for multiple batch
            drive.batch = drive.batch.replace("[", "");
            drive.batch = drive.batch.replace("]", "");
            drive.batch = drive.batch.split(",").join(",  ");
            // for multiple branch
            drive.branch = drive.branch.replace("[", "");
            drive.branch = drive.branch.replace("]", "");
            drive.branch = drive.branch.split('"').join(" ");
            drive.branch = drive.branch.split(",").join(", ");

            // for multiple gender
            drive.gender = drive.gender.replace("[", "");
            drive.gender = drive.gender.replace("]", "");
            drive.gender = drive.gender.split('"').join(" ");
            drive.gender = drive.gender.split(",").join(", ");

            drive.drive_description = JSON.parse(drive.drive_description);
            drive.profile_designation = drive.profile_designation.split(",");
            drive.profile_package = drive.profile_package.split(",");
            drive.req_skills = drive.req_skills.split("[").join("");
            drive.req_skills = drive.req_skills.split("],").join("|");
            drive.req_skills = drive.req_skills.split('"').join("");
            drive.req_skills = drive.req_skills.split("]").join("");
            drive.req_skills = drive.req_skills.split("|");
            drive.req_skills.map((skill, ind) => {
              if (skill === ",") {
                drive.req_skills = drive.req_skills[ind].replace(",", "");
              }
              return (drive.req_skills[ind] = drive.req_skills[ind].split(","));
            });

            return drive;
          });
          setDrives(data);
        } else if (res.status === 200) {
          // console.log(res.data);
        } else if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  // console.log(drives);

  useEffect(() => {
    getAllPlacementDrives();
  }, []);
  return (
    <>
      {/* {
                error ? <ConfirmationComponent data={resMsg} children={resIcon} /> : "" 
            } */}

      <ToastContainer />
      {drives.length === 0 ? (
        <Paper elevation={3} sx={{ marginTop: "20px", height: "80vh" }}>
          <NoDataFound />
        </Paper>
      ) : (
        ""
      )}
      <div style={{ marginTop: "50px" }}>
        {drives.map((drive) => (
          <>
            <div className="eligibility-msg">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Paper elevation={4} className="drive-feed-short-details">
                    <Row>
                      <Col className="company-logo-short-detail">
                        {/* <img src={CompanyImage} style={{marginBottom: '20px', marginLeft: '30%', textAlign:'center'}} alt="company" /> */}
                        <Avatar
                          sx={{
                            bgcolor: "#013c54f7",
                            margin: "auto",
                            marginBottom: "20px",
                            textAlign: "center",
                            width: "100px",
                            height: "100px",
                          }}
                        >
                          <span style={{ fontSize: "50px" }}>
                            {drive.comp_name[0].toUpperCase()}
                          </span>
                        </Avatar>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TableContainer component={Paper}>
                          <p className="comp-name">{drive.comp_name}</p>
                          <Table
                            sx={{ minWidth: 400, maxWidth: 500 }}
                            aria-label="customized table"
                          >
                            <TableBody>
                              <TableRow key="comp_name">
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ width: "200px" }}
                                >
                                  Eligible Course
                                </TableCell>
                                <TableCell align="left">
                                  {drive.drive_course}
                                </TableCell>
                              </TableRow>
                              <TableRow key="profile_designation">
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ width: "200px" }}
                                >
                                  Eligible Batch
                                </TableCell>
                                <TableCell align="left">
                                  {drive.batch}
                                </TableCell>
                              </TableRow>
                              <TableRow key="drive_package">
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ width: "200px" }}
                                >
                                  Eligible Branch
                                </TableCell>
                                <TableCell align="left">
                                  {drive.branch}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Col>
                    </Row>
                    <Row className="apply-row">
                      <Col>
                        {drive.applied ? (
                          <Button
                            disabled={true}
                            variant="contained"
                            color="success"
                            sx={{
                              float: "right",
                              "&.Mui-disabled": {
                                background: "green",
                                color: "#ffffff",
                              },
                            }}
                          >
                            Applied!
                          </Button>
                        ) : (
                          <Button
                            onClick={handleClickOpen}
                            variant="outlined"
                            color="success"
                            sx={{ float: "right" }}
                          >
                            Apply now
                          </Button>
                        )}
                        <ApplyForDriveAsPerDesignation
                          profile={drive.profile_designation}
                          selectedValue={selectedValue}
                          drive_id={drive.drive_id}
                          open={open}
                          onClose={handleClose}
                        />
                      </Col>
                    </Row>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Row>
                    <Col>
                      <Paper
                        sx={{ minWidth: 437, marginTop: "20px" }}
                        className="skills-box"
                      >
                        <TableContainer
                          sx={{ marginBottom: "20px" }}
                          component={Paper}
                        >
                          <p className="drive-heading">Profile Designation</p>
                          <Table
                            sx={{ minWidth: 380 }}
                            aria-label="customized table"
                          >
                            <TableBody>
                              {drive.profile_designation.map((profile, ind) => (
                                <TableRow key={profile}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ width: "300px" }}
                                  >
                                    {profile}
                                  </TableCell>
                                  <TableCell align="left">
                                    {drive.profile_package[ind]} LPA
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Paper
                        sx={{ minWidth: 437, marginTop: "20px" }}
                        className="skills-box"
                      >
                        <Row sx={{ marginBottom: "20px" }} component={Paper}>
                          <p className="drive-heading">Skill Requirement</p>
                          {drive.req_skills.map((skill, ind) => (
                            <>
                              <Col xs={6}>
                                <Paper
                                  key={ind}
                                  sx={{ padding: "20px", margin: "10px" }}
                                >
                                  <p className="profile-designation-name">
                                    {drive.profile_designation[ind]}
                                  </p>
                                  {skill.map((skillName, index) => (
                                    <>
                                      <Divider />
                                      <List
                                        key={index}
                                        sx={{
                                          width: "100%",
                                          bgcolor: "background.paper",
                                          textAlign: "center",
                                        }}
                                      >
                                        <ListItem key={index}>
                                          <ListItemText
                                            key={index}
                                            primary={skillName}
                                          />
                                        </ListItem>
                                      </List>
                                    </>
                                  ))}
                                </Paper>
                              </Col>
                            </>
                          ))}
                        </Row>
                      </Paper>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ maxWidth: 495 }}>
                      <Paper
                        sx={{ minWidth: 437, marginTop: "20px" }}
                        className="skills-box"
                      >
                        <TableContainer
                          sx={{ marginBottom: "20px" }}
                          component={Paper}
                        >
                          <p className="drive-heading">Eligibility Criteria</p>
                          <Table
                            sx={{ minWidth: 360 }}
                            aria-label="customized table"
                          >
                            <TableBody>
                              <TableRow key="tenth_per">
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ width: "300px" }}
                                >
                                  10th %
                                </TableCell>
                                <TableCell align="left">
                                  {drive.tenth_eligibility}
                                </TableCell>
                              </TableRow>
                              <TableRow key="twelve_per">
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ width: "300px" }}
                                >
                                  12th %
                                </TableCell>
                                <TableCell align="left">
                                  {drive.twelve_eligibility}
                                </TableCell>
                              </TableRow>
                              <TableRow key="current_cgpa">
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ width: "300px" }}
                                >
                                  Current CGPA
                                </TableCell>
                                <TableCell align="left">
                                  {drive.ug_per}
                                </TableCell>
                              </TableRow>
                              <TableRow key="backlogs">
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ width: "300px" }}
                                >
                                  Backlogs
                                </TableCell>
                                <TableCell align="left">
                                  {drive.backlog}
                                </TableCell>
                              </TableRow>
                              <TableRow key="gender">
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ width: "300px" }}
                                >
                                  Gender
                                </TableCell>
                                <TableCell align="left">
                                  {drive.gender}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Paper
                        sx={{ padding: "10px 20px 20px 20px", maxWidth: 470 }}
                      >
                        <p
                          className="drive-heading"
                          sx={{ marginBottom: "30px" }}
                        >
                          Hiring Flow
                        </p>
                        <Paper
                          elevation={3}
                          sx={{
                            width: "300px",
                            height: "fit-content",
                            padding: "10px",
                            textAlign: "center",
                            margin: "auto",
                          }}
                          className="hiring-step-box"
                        >
                          <h6>Start</h6>
                        </Paper>
                        <SouthIcon
                          sx={{
                            fontSize: "30px",
                            margin: "10px 48%",
                            color: "gray",
                          }}
                        />

                        {drive.drive_description.map((desc) => (
                          <>
                            <Paper
                              elevation={3}
                              sx={{
                                width: "300px",
                                height: "fit-content",
                                padding: "10px",
                                textAlign: "center",
                                margin: "auto",
                              }}
                              className="hiring-step-box"
                            >
                              <h6>{desc.title}</h6>
                              <p>{desc.content}</p>
                            </Paper>
                            <SouthIcon
                              sx={{
                                fontSize: "30px",
                                margin: "10px 48%",
                                color: "gray",
                              }}
                            />
                          </>
                        ))}
                        <Paper
                          elevation={3}
                          sx={{
                            width: "300px",
                            height: "fit-content",
                            padding: "10px",
                            textAlign: "center",
                            margin: "auto",
                          }}
                          className="hiring-step-box"
                        >
                          <h6>End</h6>
                        </Paper>
                      </Paper>
                    </Col>
                  </Row>
                </AccordionDetails>
              </Accordion>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

const ApplyForDriveAsPerDesignation = (props) => {
  const { onClose, selectedValue, open, profile, drive_id } = props;

  const { id, inst_id } = useParams("");
  const [profileDesignation, setProfileDesignation] = useState("");
  let selected = true;
  const handleClose = () => {
    onClose(selectedValue);
  };
  const handleChange = (event) => {
    setProfileDesignation(event.target.value);
  };

  const applyForDrive = async (drive_id) => {
    console.log("applying for drive");
    console.log(profileDesignation);

    if (profileDesignation === "") {
      selected = false;
      alert("Please select profile designation");
    }

    if (selected) {
      axios
        .post(`${SERVER_URL}/student/${id}/${inst_id}/apply-for/${drive_id}`, {
          profileDesignation,
        })
        .then(async (res) => {
          if (res.status === 202) {
            console.log(res.data);
            window.location.reload(false);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ textAlign: "center" }} color="secondary">
        Select Profile Designation
      </DialogTitle>

      <FormControl className="add-skill-dialog">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Select Profile Designation
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={profileDesignation}
            label="Select Profile Designation"
            color="secondary"
            onChange={handleChange}
          >
            {profile.map((prof, index) => (
              <MenuItem key={index} value={prof}>
                {prof}
              </MenuItem>
            ))}
          </Select>
          {selected ? (
            ""
          ) : (
            <Alert severity="error">Please select profile designation</Alert>
          )}
        </FormControl>

        <Button
          variant="outlined"
          color="secondary"
          onClick={() => applyForDrive(drive_id)}
        >
          Apply
        </Button>
      </FormControl>
    </Dialog>
  );
};

ApplyForDriveAsPerDesignation.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default ELigibilityMessages;
