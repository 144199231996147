import React, { useState, useEffect } from "react";
import "../driveHistory/DriveHistory.css";
import { alpha, styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  DataGrid,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Divider } from "@mui/material";
import { GET_TPO_DATA, SERVER_URL } from "../../../apiRoutes";
import { ToastContainer, toast } from "react-toastify";

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport sx={{ margin: "10px 20px 10px auto" }} />
      <Divider />
    </GridToolbarContainer>
  );
}

const StudentData = () => {
  const { id, inst_id } = useParams("");
  const [studentData, setStudentData] = useState([]);

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "s_roll_no",
      headerName: "Enrollment No.",
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "s_name",
      headerName: "Name",
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
    {
      field: "s_email",
      headerName: "Email",
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
    {
      field: "course_name",
      headerName: "Course",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "branch_name",
      headerName: "Branch",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "graduation_year",
      headerName: "Graduation Year",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ug_per",
      headerName: "CGPA",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "offer_package",
      headerName: "Package",
      align: "center",
      headerAlign: "center",
    },
  ];

  const getStudentData = () => {
    axios
      .get(`${SERVER_URL}/${GET_TPO_DATA}/${id}/${inst_id}/student-data`)
      .then(async (res) => {
        if (res.status === 422 || !res.data) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          const data = await res.data;
          setStudentData(data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  // console.log(studentData);

  useEffect(() => {
    getStudentData();
  }, []);

  return (
    <>
      <ToastContainer />
      <div
        style={{
          height: "60vh",
          width: "90%",
          marginTop: "100px",
          marginLeft: "5%",
        }}
      >
        <StripedDataGrid
          className="drive-history-table"
          rows={studentData}
          pageSize={10}
          columns={columns}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </>
  );
};

export default StudentData;
