import {combineReducers} from "redux";
import educationData from './educationData';
import profileData from './profileData';
import projectData from './projectData';
import skillsData from './skillsData';
import socialData from './socialData';


export default combineReducers({
    Education : educationData,
    Profile : profileData,
    Skills : skillsData,
    Social : socialData,
    Project : projectData
});