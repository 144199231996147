import React, { useState } from "react";
import "./LoginComponent.css";
import {
  IconButton,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Paper,
  Button,
  TextField,
  FormControl,
  Card,
  CardContent,
  Backdrop
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import WelcomeImg from "../../../assets/welcome-login.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NavbarComponent from "../../../components/navbar/NavbarComponent";
import axios from "axios";
import { Link } from "react-router-dom";
// import AuthService from '../../../services/auth.service';
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LOGIN_ROUTE, SERVER_URL } from "../../../apiRoutes";
import LoadingLottie from "../../common/loadingLottie";

const LoginComponent = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const formik = useFormik({
    initialValues: {
      user_email: "",
      user_password: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      const { user_email, user_password } = values;
      // console.log(values);

      axios
        .post(`${SERVER_URL}/${LOGIN_ROUTE}`, {
          user_email,
          user_password,
        })
        .then((res) => {
          // console.log(res.data);
          
          if (res.status === 422 || !res.data) {
            setLoading(false);
            toast.error("OOPs! Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 202) {
            setLoading(false);
            const user = res.data.user;
            // console.log(user);

            if (user.role_id === 2) {
              localStorage.setItem("rpc_user", JSON.stringify(res.data));
              navigate(`/institution/${res.data.inst_id}`);
            } else if (user.role_id === 4) {
              localStorage.setItem("rpc_user", JSON.stringify(res.data));
              // console.log(res.data);
              navigate(`/student/${res.data.s_id}/${user.inst_id}`);
            } else if (user.role_id === 3) {
              localStorage.setItem("rpc_user", JSON.stringify(res.data));
              navigate(`/tpo/${res.data.tpo_id}/${user.inst_id}`);
            } else if (user.role_id === 1) {
              localStorage.setItem("rpc_user", JSON.stringify(res.data));
              navigate(`/admin/${res.data.admin_id}`);
            } else {
              toast.error("OOPs! something went wrong", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
        })
        .catch((err) => {
          toast.error(err.response.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    },
    validationSchema: Yup.object({
      user_email: Yup.string()
        .email("Invalid Email Address")
        .required("Required"),
      user_password: Yup.string()
        .min(4, "Password must have atleast 4 characters")
        .required("Password is required"),
    }),
  });
  // console.log(formik.values);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <NavbarComponent />
      <ToastContainer />
      
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <LoadingLottie />
      </Backdrop>
      <section>
        <Paper elevation={3} className="loginForm">
          <Row>
            <Col style={{ justifyContent: "center", marginBottom:20 }} >
              <Paper elevation={5} sx={{ height: 440, width:400 }}>
                <img
                  src={WelcomeImg}
                  alt="welcome_image"
                  height="350"
                  width="350"
                  style={{ alignSelf: "center", marginLeft: 30, marginTop: 50 }}
                ></img>
              </Paper>
            </Col>
            <Col style={{ justifyContent: "center" }}>
              <Card className="loginform-card">
                <CardContent>
                  <p className="loginHead">
                    <span>
                      <AccountCircleIcon sx={{ fontSize: "80px" }} />
                    </span>
                  </p>
                  <form
                    onSubmit={formik.handleSubmit}
                    style={{ margin: "auto" }}
                  >
                    <div>
                      <TextField
                        id="outlined-basic"
                        label="UserEmail"
                        variant="outlined"
                        name="user_email"
                        onChange={formik.handleChange}
                        value={formik.values.user_email}
                        onBlur={formik.handleBlur}
                        className="email userInput"
                      />
                      {formik.touched.user_email && formik.errors.user_email ? (
                        <div
                          style={{
                            color: "red",
                            marginBottom: "10px",
                            marginTop: "-10px",
                            fontSize: "12px",
                          }}
                        >
                          {formik.errors.user_email}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="outlinedpassword">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlinedpassword"
                        type={showPassword ? "text" : "password"}
                        value={formik.values.user_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="user_password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                      {formik.touched.user_password &&
                      formik.errors.user_password ? (
                        <div
                          style={{
                            color: "red",
                            marginBottom: "10px",
                            marginTop: "-10px",
                            fontSize: "12px",
                          }}
                        >
                          {formik.errors.user_password}
                        </div>
                      ) : (
                        ""
                      )}
                    </FormControl>
                    <Link to="/forgot-password" className="forgot-pass">
                      Forgot Password
                    </Link>
                    <Row>
                      <Button
                        variant="outlined"
                        type="submit"
                        sx={{ margin: "auto" }}
                        className="submitBtn"
                      >
                        Login
                      </Button>
                    </Row>
                  </form>
                </CardContent>
              </Card>
            </Col>
          </Row>
        </Paper>
      </section>
    </>
  );
};

export default LoginComponent;
