import React, { useState, useEffect } from "react";
import "./DriveHistory.css";
import { alpha, styled } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  DataGrid,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Button, Divider } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_TPO_DATA, SERVER_URL } from "../../../apiRoutes";

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport sx={{ margin: "10px 20px 10px auto" }} />
      <Divider />
    </GridToolbarContainer>
  );
}

const DriveHistory = () => {
  const navigate = useNavigate();
  const { id, inst_id } = useParams("");
  const [userDriveHistory, setUserDriveHistory] = useState([]);

  const applicants = (params) => {
    const drive_id = params.row.id;
    const data = {
      comp_name: params.row.comp_name,
    };
    return (
      <Button
        variant="outlined"
        onClick={() =>
          navigate(`${drive_id}/drive-applicants`, { state: data })
        }
        color="secondary"
      >
        Applicants
      </Button>
    );
  };

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "comp_name",
      headerName: "Company",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "profile_designation",
      headerName: "Profile Designation",
      flex: 3,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "drive_create_date",
      headerName: "Created At",
      flex: 3,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "batch",
      headerName: "Batch",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "drive_course",
      headerName: "Course",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "branch",
      headerName: "Branch",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "applicants",
      renderCell: applicants,
      headerName: "Applicants",
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
  ];

  const getTpoDriveHistory = () => {
    axios
      .get(`${SERVER_URL}/${GET_TPO_DATA}/${id}/${inst_id}/drive-history`)
      .then(async (res) => {
        if (res.status === 422 || !res.data) {
          // console.log("Error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          const data = await res.data;
          data.map((drive) => {
            drive.comp_name = drive.comp_name.toUpperCase();

            // drive.drive_package = drive.drive_package + ' LPA';
            // drive.batch = JSON.parse(drive.batch);
            // drive.branch = JSON.parse(drive.branch);

            // for multiple batch
            drive.batch = drive.batch.replace("[", "");
            drive.batch = drive.batch.replace("]", "");
            drive.batch = drive.batch.split(",").join(",  ");
            // for multiple branch
            drive.branch = drive.branch.replace("[", "");
            drive.branch = drive.branch.replace("]", "");
            drive.branch = drive.branch.split('"').join(" ");
            drive.branch = drive.branch.split(",").join(", ");

            drive.profile_designation = drive.profile_designation.split(",");

            const date = new Date(drive.drive_create_date).toLocaleDateString();
            const time = new Date(drive.drive_create_date).toLocaleTimeString();
            drive.drive_create_date = date + " , " + time;

            return drive;
          });
          setUserDriveHistory(data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  // console.log(userDriveHistory);

  useEffect(() => {
    getTpoDriveHistory();
  }, []);

  return (
    <>
      <ToastContainer />
      <div
        style={{
          height: "70vh",
          width: "90%",
          marginTop: "100px",
          marginLeft: "5%",
        }}
      >
        <StripedDataGrid
          className="drive-history-table"
          rows={userDriveHistory}
          pageSize={10}
          columns={columns}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </>
  );
};

export default DriveHistory;
