import React, { useState, useEffect } from "react";
import "./InstitutionPageComponent.css";
import S3 from "react-aws-s3";
import { Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import {
  Container,
  Paper,
  Button,
  FormControl,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import FooterComponent from "../../../components/footer/FooterComponent";
import DataUploadImage from "../../../assets/upload-data-center.svg";
import axios from "axios";
import { useParams } from "react-router-dom";
import NavbarComponent from "../../common/navbar/NavbarComponent";
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_INST_DATA,
  UPLOAD_STUDENT_DATA,
  UPLOAD_TPO_DATA,
  SERVER_URL
} from "../../../apiRoutes";

const studentData = [
  ["Name", "Email", "Enrollment_No", "Mobile_No", "Batch", "Graduation_Year"],
  ["John", "john@gmail.com", "0832CS201067", 1245639875, 2024, 2024],
  ["Rock", "Rock@gmail.com", "0832CS201007", 1245639875, 2024, 2024],
  ["Montu", "montu@gmail.com", "0832CS201167", 1245639875, 2024, 2024],
];
const tpoData = [
  ["Name", "Email", "Mobile_No"],
  ["Ahmed", "ahmed@gmail.com", 1234586975],
  ["Raed", "raed@gmail.com", 3568749102],
  ["Yazz", "yazz@gmail.com", 9548671258],
];

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const InstitutionPageComponent = () => {
  const [uploadStudentData, setUploadStudentData] = useState();
  const [uploadTpoData, setUploadTpoData] = useState();
  const [inputStudentFile, setInputStudentFile] = useState("");
  const [inputTpoFile, setInputTpoFile] = useState("");

  const { id } = useParams("");

  const [institute, setInstitute] = useState([]);

  const user_role = "institution";
  const getInstitute = async () => {
    axios
      .get(`${SERVER_URL}/${GET_INST_DATA}/${id}`)
      .then(async (res) => {
        if (res.status === 202) {
          const data = await res.data;
          setInstitute(data);
        } else if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const handleStudentFileChange = ({ target }) => {
    setUploadStudentData(target.files[0]);
    setInputStudentFile(target.value);
  };
  const handleTpoFileChange = ({ target }) => {
    setUploadTpoData(target.files[0]);
    setInputTpoFile(target.value);
  };
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: "institution",
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };

  const submitStudentData = async (e) => {
    e.preventDefault();

    const file = uploadStudentData;
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, `${Date.now()}.${file.name}`)
      .then((data) => {
        // console.log(data.location);
        if (!data.location.length) {
          toast.error("Some error occurred while uploading file", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        // console.log(data.key);
        const file_key = data.key;

        axios
          .post(`${SERVER_URL}/${UPLOAD_STUDENT_DATA}`, { file_key, id })
          .then((res) => {
            // console.log(res.data);
            if (res.status === 422 || res.status === 201) {
              toast.error(res.data, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (res.status === 202) {
              toast.success(res.data, {
                position: toast.POSITION.TOP_CENTER,
              });
              getInstitute();
            }
          })
          .catch((err) => {
            // console.log(err);
            toast.error(err.response.data, {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      })
      .catch((err) => {
        // console.error(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const submitTpoData = async (e) => {
    e.preventDefault();

    const file = uploadTpoData;
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, `${Date.now()}.${file.name}`)
      .then((data) => {
        // console.log(data.location);
        if (!data.location.length) {
          toast.error("Some error occurred while uploading file", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        const file_key = data.key;

        axios
          .post(`${SERVER_URL}/${UPLOAD_TPO_DATA}`, { file_key, id })
          .then((res) => {
            // console.log(res.data);
            if (res.status === 422 || res.status === 201) {
              toast.error(res.data, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else if (res.status === 202) {
              toast.success(res.data, {
                position: toast.POSITION.TOP_CENTER,
              });
              getInstitute();
            }
          })
          .catch((err) => {
            // console.log(err);
            toast.error(err.response.data, {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      })
      .catch((err) => {
        // console.error(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    getInstitute();
  }, []);

  return (
    <>
      <ToastContainer />

      <section>
        {/* <InstituteUserNavbarComponent /> */}
        <NavbarComponent data={institute} role={user_role} />
      </section>

      <section className="profile-img-section">
        <img
          src={require("../../../assets/image3.jpg")}
          className="inst-background-img"
          alt="background_image"
        />
      </section>

      <section className="about">
        <h1>{institute.inst_name}</h1>
      </section>
      <section className="sample-excel-sheets">
        <Container>
          <Paper elevation={3} className="sample-student-sheets">
            <Row>
              <Col md={10}>
                <p>Sample CSV File for students data!</p>
              </Col>
              <Col>
                <Button variant="contained" color="success">
                  <CSVLink
                    style={{ textDecoration: "none", color: "white" }}
                    filename="StudentData"
                    data={studentData}
                  >
                    Download
                  </CSVLink>
                </Button>
              </Col>
            </Row>
          </Paper>
          <Paper elevation={3} className="sample-tpo-sheets">
            <Row>
              <Col md={10}>
                <p>
                  Sample CSV File for Training & Placement Cell Officer data!
                </p>
              </Col>
              <Col>
                <Button variant="contained" color="success">
                  <CSVLink
                    style={{ textDecoration: "none", color: "white" }}
                    filename="TPOData"
                    data={tpoData}
                  >
                    Download
                  </CSVLink>
                </Button>
              </Col>
            </Row>
          </Paper>
        </Container>
      </section>

      <section
        className="upload-section"
        style={{ marginTop: "50px" }}
        id="add-users"
      >
        <Container className="upload-container">
          <Row className="">
            <Col sm={4}>
              <Paper elevation={3} className="upload-paper ">
                <FormControl>
                  <div className="school-icon">
                    <SchoolIcon sx={{ fontSize: 80 }} />
                  </div>
                  <p>Here! Upload student data of your Institute.</p>
                  <div>
                    <BootstrapTooltip title="Only .csv file">
                      <Button
                        variant="contained"
                        component="label"
                        className="upload-btn"
                      >
                        Upload File{" "}
                        <input
                          onChange={handleStudentFileChange}
                          value={inputStudentFile}
                          type="file"
                          accept=".xlsx, .xls, .csv, .spreadsheetml.sheet"
                          hidden
                        />
                      </Button>
                    </BootstrapTooltip>
                    <Button
                      onClick={submitStudentData}
                      variant="contained"
                      className="submit-btn"
                    >
                      Submit
                    </Button>
                  </div>
                </FormControl>
              </Paper>
            </Col>
            <Col sm={4} className="data-img">
              <img
                src={DataUploadImage}
                alt="upload_data_image"
                height="400"
                width="400"
              />
            </Col>
            <Col sm={4}>
              <Paper elevation={3} className="upload-paper ">
                <FormControl>
                  <div className="tpo-icon">
                    <AccountBoxIcon sx={{ fontSize: 80 }} />
                  </div>
                  <p>Here! Upload Training & Placement Officer data.</p>
                  <div>
                    <BootstrapTooltip title="Only .csv file">
                      <Button
                        variant="contained"
                        component="label"
                        className="upload-btn"
                      >
                        Upload File{" "}
                        <input
                          onChange={handleTpoFileChange}
                          value={inputTpoFile}
                          type="file"
                          accept=".xlsx, .xls, .csv, .spreadsheetml.sheet"
                          hidden
                        />
                      </Button>
                    </BootstrapTooltip>
                    <Button
                      onClick={submitTpoData}
                      variant="contained"
                      className="submit-btn"
                    >
                      Submit
                    </Button>
                  </div>
                </FormControl>
              </Paper>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <FooterComponent />
      </section>
    </>
  );
};

export default InstitutionPageComponent;
