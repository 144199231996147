import React, { useState, useEffect } from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import axios from "axios";
import { ADMIN_GET_USER_DATA, SERVER_URL } from "../../apiRoutes";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const COLORS = ["#344D67", "#087897de", "#44d8f1de"];
const UserPieChart = () => {
  const [userData, setUserData] = useState([]);

  const getUserData = () => {
    axios
      .get(`${SERVER_URL}/${ADMIN_GET_USER_DATA}`)
      .then((res) => {
        if (res.status === 202) {
          setUserData(res.data);
        } else if (res.status === 422) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(userData);
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <ResponsiveContainer style={{ width: "90%", height: "90%" }}>
        <PieChart>
          <Pie
            dataKey="Count"
            data={userData}
            labelLine={false}
            cx={200}
            cy={200}
            fill="#344D67"
            outerRadius={120}
            label={renderCustomizedLabel}
          >
            {userData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default UserPieChart;
