import React from "react";
import Lottie from "react-lottie";
import loader from "../../assets/lottie/loginLoader.json";

const LoadingLottie = () => {
  return (
    <>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loader,
        }}
        height={400}
        width={400}
      />
    </>
  );
};

export default LoadingLottie;
