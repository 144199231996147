import React from "react";
import "./NavbarComponent.css";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

const NavbarComponent = () => {
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary main-nav">
        <Container fluid>
          <Navbar.Brand href="/" className="logo">
              <h2>
                <span>R</span>emote
                <span>P</span>lacement
                <span>C</span>ell
              </h2>
          </Navbar.Brand>
          <Navbar.Toggle className="toggler" style={{width:'60px !important'}} aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="my-2 my-lg-0 collapse-toggler"
              style={{ maxHeight: '100px', display:'-webkit-inline-box', marginLeft:'auto' }}
              navbarScroll
            >
              <Nav.Link href="/login" className="login" >Sign In</Nav.Link>
              <Nav.Link href="" className="register" onClick={() => window.location.replace("/#register")}>Join Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarComponent;
