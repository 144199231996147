import React from "react";
import Lottie from "react-lottie";
import noData from "../../assets/lottie/no_data.json";

const NoDataFound = () => {
  return (
    <>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: noData,
        }}
        height={600}
        // width={500}
      />
    </>
  );
};

export default NoDataFound;
