import React, { useState } from "react";
import "./SideNavComponent.css";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FeedIcon from "@mui/icons-material/Feed";
import InfoIcon from "@mui/icons-material/Info";
import InsightsIcon from "@mui/icons-material/Insights";
import PersonIcon from "@mui/icons-material/Person";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HistoryIcon from "@mui/icons-material/History";
import DatasetIcon from "@mui/icons-material/Dataset";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { Avatar } from "@mui/material";
import { Link, Outlet, useParams, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";

let drawerWidth = 250;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // width: `calc(100% - ${drawerWidth}px)`,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    // backgroundColor : "#372948",
    backgroundColor: "#03374d",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7.5) + 1,
    },
    // backgroundColor : "#372948"
    backgroundColor: "#03374d",
  },
  showUserDetail: {
    // color: "#ecfbff"
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 600,
    color: "white",
    marginBottom: "50px !important",
  },
  hideUserDetail: {
    display: "none",
    marginTop: "100px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(),
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  divider: {
    backgroundColor: "white",
  },
  marginDivider: {
    backgroundColor: "white",
    marginTop: "200px",
  },
  footerDivider: {
    backgroundColor: "white",
    marginTop: "150px",
  },
  footerMarginDivider: {
    backgroundColor: "white",
    marginTop: "250px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
});

const SideNavComponent = (props) => {
  // console.log(props);
  const { id, inst_id } = useParams("");
  const navigate = useNavigate();
  const [state, setState] = useState({
    open: true,
    anchorEl: null,
  });

  const handleDrawerOpen = () => {
    setState({ open: !state.open });
  };

  const logout = () => {
    localStorage.removeItem("rpc_user");
    navigate("/");
    window.location.reload();
  };

  // const handleDrawerClose = () => {
  //   setState({ open: false });
  // };

  // const handleMenu = event => {
  //   setState({ anchorEl: event.currentTarget });
  // };
  // const handleClose = () => {
  //   setState({ anchorEl: null });
  // };

  const { classes, data, role } = props;
  const studentIcon = [
    <FeedIcon />,
    <InfoIcon />,
    <InsightsIcon />,
    <ContentPasteGoIcon />,
  ];
  const studentNavLinks = [
    `/student/${id}/${inst_id}/tpc-feeds`,
    `/student/${id}/${inst_id}/student-profile/basic-info`,
    `/student/${id}/${inst_id}/student-tech-report`,
    `/student/${id}/${inst_id}/student-make-resume`,
  ];
  const tpoIcon = [
    <AddBoxIcon />,
    <InfoIcon />,
    <HistoryIcon />,
    <FeedIcon />,
    <DatasetIcon />,
    <HowToRegIcon />,
  ];
  const tpoNavLinks = [
    `/tpo/${id}/${inst_id}/create-placement-drive`,
    `/tpo/${id}/${inst_id}/tpo-profile/basic-info`,
    `/tpo/${id}/${inst_id}/drive-history`,
    `/tpo/${id}/${inst_id}/tpo-feeds`,
    `/tpo/${id}/${inst_id}/student-data`,
    `/tpo/${id}/${inst_id}/placement-history`,
  ];
  const adminIcon = [
    <DashboardIcon />,
    <AddCircleIcon />,
    <TrendingUpIcon />,
    <ManageAccountsIcon />,
  ];
  const adminNavLinks = [
    `/admin/${id}/dashboard`,
    `/admin/${id}/add-institution`,
    `/admin/${id}/approve/joining-requests`,
    `/admin/${id}/admin-manage-users`,
  ];

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ display: "inline-block" }}>
          <AppBar position="relative" className={classes.appBar}>
            <Toolbar disableGutters={true}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleDrawerOpen}
                className={classes.menuButton}
              >
                <MenuIcon
                  classes={{
                    root: state.open
                      ? classes.menuButtonIconOpen
                      : classes.menuButtonIconClosed,
                  }}
                />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
        {/* <NavbarComponent data={data} role={role} /> */}
      </div>
      <div style={{ display: "flex" }}>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: state.open,
            [classes.drawerClose]: !state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: state.open,
              [classes.drawerClose]: !state.open,
            }),
          }}
          open={state.open}
        >
          <div className={classes.toolbar} />

          {role === "student" ? (
            <>
              <div
                className={
                  state.open ? classes.showUserDetail : classes.hideUserDetail
                }
              >
                {data.profile_pic === null || data.profile_pic === undefined ? (
                  <Avatar
                    sx={{
                      bgcolor: "white",
                      height: "100px",
                      width: "100px",
                      padding: "30px",
                    }}
                    className="user-img"
                  >
                    <PersonIcon sx={{ fontSize: "80px", color: "#03374d" }} />
                  </Avatar>
                ) : (
                  <div>
                    <img
                      src={data.profile_pic}
                      alt="profile_pic"
                      height="150px"
                      width="150px"
                      style={{
                        borderRadius: "50%",
                        marginTop: "50px",
                        marginBottom: "50px",
                      }}
                    />
                  </div>
                )}

                <span>{data.s_name}</span>
                <br />
                <span>{data.s_roll_no}</span>
              </div>
              <Divider
                className={state.open ? classes.divider : classes.marginDivider}
              />
              <List className="user-menu">
                {["T&P Feeds", "About", "Reports", "Create Resume"].map(
                  (text, index) => (
                    <Link to={studentNavLinks[index]}>
                      <ListItem button key={index}>
                        <ListItemIcon>{studentIcon[index]}</ListItemIcon>
                        <ListItemText primary={text} />
                      </ListItem>
                    </Link>
                  )
                )}
              </List>
            </>
          ) : (
            <>
              {role === "tpo" ? (
                <>
                  <div
                    className={
                      state.open
                        ? classes.showUserDetail
                        : classes.hideUserDetail
                    }
                  >
                    {data.profile_pic === null ||
                    data.profile_pic === undefined ? (
                      <Avatar
                        sx={{
                          bgcolor: "white",
                          height: "100px",
                          width: "100px",
                          padding: "30px",
                        }}
                        className="user-img"
                      >
                        <PersonIcon
                          sx={{ fontSize: "80px", color: "#03374d" }}
                        />
                      </Avatar>
                    ) : (
                      <div>
                        <img
                          src={data.profile_pic}
                          alt="profile_pic"
                          height="150px"
                          width="150px"
                          style={{
                            borderRadius: "50%",
                            marginTop: "50px",
                            marginBottom: "50px",
                          }}
                        />
                      </div>
                    )}
                    <span>{data.tpo_name}</span>
                    {/* <span>{data.tpo_id}</span> */}
                  </div>
                  <Divider
                    className={
                      state.open ? classes.divider : classes.marginDivider
                    }
                  />
                  <List className="user-menu">
                    {[
                      "New Placement Drive",
                      "About",
                      "Drive History",
                      "Feeds",
                      "Student Data",
                      "Placement History",
                    ].map((text, index) => (
                      <Link to={tpoNavLinks[index]}>
                        <ListItem button key={index}>
                          <ListItemIcon>{tpoIcon[index]}</ListItemIcon>
                          <ListItemText primary={text} />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </>
              ) : (
                <>
                  <div
                    className={
                      state.open
                        ? classes.showUserDetail
                        : classes.hideUserDetail
                    }
                  >
                    {data.profile_pic === null ||
                    data.profile_pic === undefined ? (
                      <Avatar
                        sx={{
                          bgcolor: "white",
                          height: "100px",
                          width: "100px",
                          padding: "30px",
                        }}
                        className="user-img"
                      >
                        <PersonIcon
                          sx={{ fontSize: "80px", color: "#03374d" }}
                        />
                      </Avatar>
                    ) : (
                      <div>
                        <img
                          src={data.profile_pic}
                          alt="profile_pic"
                          height="150px"
                          width="150px"
                          style={{
                            borderRadius: "50%",
                            marginTop: "50px",
                            marginBottom: "50px",
                          }}
                        />
                      </div>
                    )}
                    <span style={{ textAlign: "center" }}>
                      {data.user_fullname}
                    </span>
                  </div>
                  <Divider
                    className={
                      state.open ? classes.divider : classes.marginDivider
                    }
                  />
                  <List className="user-menu">
                    {[
                      "Dashboard",
                      "Add Institution",
                      "Pending Requests",
                      "Manage users",
                    ].map((text, index) => (
                      <Link to={adminNavLinks[index]}>
                        <ListItem button key={index}>
                          <ListItemIcon>{adminIcon[index]}</ListItemIcon>
                          <ListItemText primary={text} />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </>
              )}
            </>
          )}

          <Divider
            className={
              state.open ? classes.footerDivider : classes.footerMarginDivider
            }
          />
          <div
            onClick={logout}
            style={{
              flexDirection: "row",
              color: "white",
              marginLeft: "20px",
              marginTop: "20px",
              cursor: "pointer",
            }}
          >
            <LogoutIcon />
            <span style={{ marginLeft: "20px" }}>Logout</span>
          </div>
          <Outlet />
        </Drawer>
        <div className="child-component">
          <props.children userRole={props.role} />
        </div>
      </div>
    </>
  );
};

SideNavComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SideNavComponent);
