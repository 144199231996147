import React from "react";
import Lottie from "react-lottie";
import error from "../../assets/lottie/error.json";

const ErrorLottie = () => {
  return (
    <>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: error,
        }}
        height={400}
        width={400}
      />
    </>
  );
};

export default ErrorLottie;
