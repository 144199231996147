import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import S3 from "react-aws-s3";
import {
  Paper,
  styled,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NavbarComponent from "../../common/navbar/NavbarComponent";
import { tableCellClasses } from "@mui/material/TableCell";
import { Link, Outlet, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import PolicyIcon from "@mui/icons-material/Policy";
import SchoolIcon from "@mui/icons-material/School";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import KeyIcon from "@mui/icons-material/Key";
import VerifiedIcon from "@mui/icons-material/Verified";
import ConfirmationComponent from "../../common/ConfirmationComponent";
import { ToastContainer, toast } from "react-toastify";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  UPDATE_INST_CAMPUS_POLICY,
  UPDATE_INST_MOBILE,
  UPDATE_INST_NAME,
  UPDATE_INST_PASSWORD,
  UPDATE_INST_PROFILE_PIC,
  UPDATE_INST_UNIV,
  UPDATE_INST_USER_NAME,
  GET_INST_DATA,
  GET_INST_BILLING_DETAILS,
  SERVER_URL
} from "../../../apiRoutes";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const BasicInstitutionInfoDetails = (props) => {
  const { institute } = props;
  console.log(institute);
  const [inputValue, setInputValue] = useState({
    inst_name: "",
    inst_email: "",
    inst_mobile: "",
  });
  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editMobile, setEditMobile] = useState(false);

  const enableEdit = (e) => {
    if (e.target.name === "editName") {
      setEditName(!editName);
    }
    if (e.target.name === "editEmail") {
      setEditEmail(!editEmail);
    }
    if (e.target.name === "editMobile") {
      setEditMobile(!editMobile);
    }
  };
  const handleEdit = (e) => {
    setInputValue((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const updateName = async (e) => {
    e.preventDefault();
    const { inst_name } = inputValue;
    axios
      .put(UPDATE_INST_NAME, { inst_name })
      .then((res) => {
        // console.log(res.data);
        if (res.status === 422 || !res.data) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          setEditName(!editName);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateMobile = async (e) => {
    e.preventDefault();
    const { inst_mobile } = inputValue;
    axios
      .put(UPDATE_INST_MOBILE, { inst_mobile })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          //   alert(res.data);
          setEditMobile(!editMobile);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <>
      <ToastContainer />
      <Paper
        className="basic-details"
        elevation={3}
        id="basic-info"
        sx={{ minHeight: "400px" }}
      >
        <p style={{textAlign:'center'}}>Basic Details</p>
        <TableContainer
          sx={{ padding: "20px 20px 50px 20px", minHeight: "400px" }}
        >
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="Name">
                <StyledTableCell component="th" scope="row">
                  Name
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editName ? (
                    <>
                      <Form.Control
                        className="mt-3"
                        placeholder={institute.inst_name}
                        name="inst_name"
                        onChange={handleEdit}
                      />
                    </>
                  ) : (
                    <>{institute.inst_name}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editName ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateName}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editName"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editName"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="email">
                <StyledTableCell component="th" scope="row">
                  Email
                </StyledTableCell>
                <StyledTableCell align="left">
                  {institute.inst_email}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Button
                    sx={{ color: "#0467a6" }}
                    name="editEmail"
                    onClick={enableEdit}
                    disabled={true}
                  >
                    Edit
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="mobile">
                <StyledTableCell component="th" scope="row">
                  Mobile No.
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editMobile ? (
                    <Form.Control
                      type="telephone"
                      className="mt-3"
                      onChange={handleEdit}
                      name="inst_mobile"
                      placeholder={institute.inst_mobile}
                    />
                  ) : (
                    <>{institute.inst_mobile}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editMobile ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateMobile}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editMobile"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editMobile"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export const InstitutionAccountDetails = (props) => {
  const { institute } = props;
  const [inputValue, setInputValue] = useState({
    inst_user: "",
    user_password: "",
    inst_univ: "",
    drive_criteria: 0,
  });
  const [editUsername, setEditUsername] = useState(false);
  const [editUniv, setEditUniv] = useState(false);
  const [editPolicy, setEditPolicy] = useState(false);
  const [error, setError] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [resIcon, setResIcon] = useState("");

  const enableEdit = (e) => {
    if (e.target.name === "editUsername") {
      setEditUsername(!editUsername);
    }
    if (e.target.name === "editUniv") {
      setEditUniv(!editUniv);
    }
    if (e.target.name === "editPolicy") {
      setEditPolicy(!editPolicy);
    }
  };
  const updateUsername = async (e) => {
    e.preventDefault();
    const { inst_user } = inputValue;
    axios
      .put(`${SERVER_URL}/${UPDATE_INST_USER_NAME}`, { inst_user })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          //   alert("error");
          setError(true);
          setResMsg("OOPs! Something went wrong");
          setResIcon("error");
        } else {
          //   alert(res.data);
          setEditUsername(!editUsername);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        setError(true);
        setResMsg("OOPs! Something went wrong");
        setResIcon("error");
      });
  };
  const updatePassword = async (e) => {
    e.preventDefault();
    const email = institute.inst_email;
    axios
      .post(`${SERVER_URL}/${UPDATE_INST_PASSWORD}`, { email })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          setError(true);
          setResMsg("OOPs! Something went wrong");
          setResIcon("error");
        } else {
          // alert(res.data);
          setError(true);
          setResMsg(res.data.message);
          setResIcon("success");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setResMsg("OOPs! Something went wrong");
        setResIcon("error");
      });
  };
  const updateUniversity = async (e) => {
    e.preventDefault();
    const { inst_univ } = inputValue;
    axios
      .put(`${SERVER_URL}/${UPDATE_INST_UNIV}`, { inst_univ })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          setError(true);
          setResMsg("OOPs! Something went wrong");
          setResIcon("error");
        } else {
          //   alert(res.data);
          setEditUniv(!editUniv);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        setError(true);
        setResMsg("OOPs! Something went wrong");
        setResIcon("error");
      });
  };
  const updateCampusPolicy = async (e) => {
    e.preventDefault();
    const { drive_criteria } = inputValue;
    axios
      .put(`${SERVER_URL}/${UPDATE_INST_CAMPUS_POLICY}`, { drive_criteria })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          setError(true);
          setResMsg("OOPs! Something went wrong");
          setResIcon("error");
        } else {
          // alert(res.data);
          setEditPolicy(!editPolicy);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        setError(true);
        setResMsg("OOPs! Something went wrong");
        setResIcon("error");
      });
  };

  const handleEdit = (e) => {
    setInputValue((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  //   console.log(inputValue);
  return (
    <>
      {error ? <ConfirmationComponent data={resMsg} children={resIcon} /> : ""}
      <Paper className="account-details" elevation={3}>
        <p>Account Info</p>
        <TableContainer sx={{ padding: "20px" }}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="s_name">
                <StyledTableCell component="th" scope="row">
                  <AccountCircleIcon />
                </StyledTableCell>
                <StyledTableCell align="left">Username</StyledTableCell>
                <StyledTableCell align="left">
                  {editUsername ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      value={inputValue.s_name}
                      placeholder="Enter Username"
                      name="inst_user"
                      onChange={handleEdit}
                    />
                  ) : (
                    <>{institute.inst_user}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {/* <Button sx={{color: '#0467a6'}} name="editUsername" onClick={enableEdit} >Edit</Button>  */}
                  {editUsername ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateUsername}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editUsername"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editUsername"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="password">
                <StyledTableCell component="th" scope="row">
                  <KeyIcon />
                </StyledTableCell>
                <StyledTableCell align="left">Password</StyledTableCell>
                <StyledTableCell align="left">********</StyledTableCell>
                <StyledTableCell align="right">
                  {/* <Button sx={{color: '#0467a6'}} name="editPassword" onClick={enableEdit} >Edit</Button>  */}
                  <Button
                    sx={{ color: "#0467a6" }}
                    name="editPassword"
                    onClick={updatePassword}
                  >
                    Change
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <p>Institution Policy</p>
        <TableContainer sx={{ padding: "20px 20px 50px 20px" }}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="inst_univ">
                <StyledTableCell component="th" scope="row">
                  <SchoolIcon />
                </StyledTableCell>
                <StyledTableCell align="left"> University </StyledTableCell>
                <StyledTableCell align="center">
                  {editUniv ? (
                    <Form.Control
                      type="email"
                      className="mt-3"
                      placeholder="Enter Name"
                      name="inst_univ"
                      onChange={handleEdit}
                    />
                  ) : (
                    <>{institute.inst_university}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {/* <Button sx={{color: '#0467a6'}} name="editLinkedInAcc" onClick={enableEdit} >Edit</Button>  */}

                  {editUniv ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateUniversity}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editUniv"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editUniv"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="github">
                <StyledTableCell component="th" scope="row">
                  <PolicyIcon />
                </StyledTableCell>
                <StyledTableCell align="left"> Campus Policy </StyledTableCell>
                <StyledTableCell align="center">
                  {editPolicy ? (
                    <Form.Control
                      type="email"
                      className="mt-3"
                      placeholder="Enter Name"
                      name="drive_criteria"
                      onChange={handleEdit}
                    />
                  ) : (
                    <>{institute.drive_criteria}%</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {/* <Button sx={{color: '#0467a6'}} name="editGithubAcc" onClick={enableEdit} >Edit</Button>  */}
                  {editPolicy ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateCampusPolicy}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editPolicy"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editPolicy"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export const BillingDetails = (props) => {
  const {id} = useParams();

  const [data, setData] = useState();
  const getBillingData = () => {
    try{
      axios.get(`${GET_INST_BILLING_DETAILS}/${id}`).then((res) => {
        let data = res.data;
        if(res.status === 202){
          data.map((item) => {
            item.start_date = item.start_date.slice(0, 10);
            item.end_date = item.end_date.slice(0, 10);
            return item;
          })
          setData(res.data);
        }
      })
    }catch(err){
      console.log(err);
      
    }
  }
  useEffect(() => {
    getBillingData();
  }, [])

  return (
    <>
      <Paper className="account-details" elevation={3}>
        <p style={{textAlign:'center'}}>Billing Info</p>
        <TableContainer sx={{ padding: "20px" }}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="s_name">
                <StyledTableCell component="th" scope="row">
                  {/* <VerifiedIcon style={{color:'green'}} /> */}
                </StyledTableCell>
                <StyledTableCell align="left">Issued on</StyledTableCell>
                <StyledTableCell align="left">Expires on</StyledTableCell>
                <StyledTableCell align="left">Invoice</StyledTableCell>
              </StyledTableRow>
              {data !== undefined ? 
                data.map((bill) => (
                  <StyledTableRow key="bill">
                    <StyledTableCell component="th" scope="row">
                      <VerifiedIcon style={{ color: "green" }} />
                    </StyledTableCell>
                    <StyledTableCell align="left">{bill.start_date}</StyledTableCell>
                    <StyledTableCell align="left">{bill.end_date}</StyledTableCell>
                    <StyledTableCell align="left">
                      <a href={bill.invoice_pdf}>Download</a>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
                : 
                ''
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

const InstitutionProfileComponent = (props) => {
  const inputRef = useRef();
  const [user, setUser] = useState([]);
  const user_role = "institution";
  const navigate = useNavigate();

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: "tpo/profile",
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];
  const formik = useFormik({
    initialValues: {
      inst_profile_pic: "",
    },
    onSubmit: (values) => {
      const file = values.profile_pic;
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, `${Date.now()}.${file.name}`).then(
        (data) => {
          // console.log(data.location);
          if (!data.location.length) {
            toast.error("OOPs! something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
          const imgUrl = data.location;
          axios
            .put(UPDATE_INST_PROFILE_PIC, { imgUrl })
            .then((res) => {
              if (res.status === 202) {
                toast.success(res.data, {
                  position: toast.POSITION.TOP_CENTER,
                });
                window.location.reload(false);
              } else if (res.status === 422) {
                toast.error(res.data, {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            })
            .catch((err) => {
              toast.error(err.response.data, {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        }
      );
    },
    validationSchema: Yup.object({
      profile_pic: Yup.mixed()
        .test(
          "fileSize",
          "File Size is too large",
          (value) => value.size >= 20000
        )
        .test("fileType", "Unsupported File Format", (value) =>
          SUPPORTED_FORMATS.includes(value.type)
        ),
    }),
  });

  const getUser = async () => {
    axios.get(`${SERVER_URL}/${GET_INST_DATA}/${id}`)
      .then(async (res) => {
        
        if (res.status === 422 || !res.data) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          const data = await res.data;
          setUser(data);
          // console.log(data);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleFileInput = (e) => {
    formik.values.profile_pic = e.target.files[0];
    formik.handleSubmit();
  };
  const profilePicStyle = {
    backgroundImage: `url(${user.profile_pic})`,
    backgroundSize: "cover",
  };
  const logout = () => {
    localStorage.removeItem("rpc_user");
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    getUser();
  }, []);

  const { id } = useParams("");

  const API_URL = `/institution/${id}/institution-profile`;

  return (
    <>
      <ToastContainer />
      <section>
        <NavbarComponent data={user} role={user_role} />
      </section>
      <Row className="profile-img-section">
        <Col md={{ span: 2, offset: 2 }} style={{ marginTop: "100px" }}>
          <form
            onSubmit={formik.handleSubmit}
            className="user-profile-add-image"
          >
            <Button
              sx={{
                bgcolor: "#f4feddf5",
                margin: "auto",
                marginBottom: "20px",
                textAlign: "center",
                width: "150px",
                height: "150px",
                borderRadius: "50%",
              }}
              className="attach-file-btn"
              style={profilePicStyle}
              onClick={() => {
                console.log(inputRef);
                inputRef.current.click();
              }}
            >
              <input
                ref={inputRef}
                type="file"
                id="certificate"
                name="profile_pic"
                onChange={handleFileInput}
                hidden
              />
              {user.inst_profile_pic === null || user.inst_profile_pic === undefined ? (
                <AddPhotoAlternateIcon
                  sx={{ color: "#0E5E6F", fontSize: "50px", margin: "auto" }}
                />
              ) : (
                ""
              )}
            </Button>
            {formik.touched.inst_profile_pic && formik.errors.inst_profile_pic ? (
              <div>
                <Typography
                  sx={{
                    textAlign: "left",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                    color: "white",
                  }}
                >
                  {formik.errors.inst_profile_pic}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </form>
        </Col>
        <Col className="profile-name">
          <span>{user.inst_name}</span>
          {/* <span style={{fontSize : "14px"}} >TPO_ID</span> */}
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 2, offset: 1 }} className="profile-list">
          <List sx={{ bgcolor: "background.paper" }}>
            <Link to={API_URL + "/basic-info"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <InfoIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Basic Info" />
              </ListItem>
            </Link>
            <Link to={API_URL + "/account"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <ManageAccountsIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Account" />
              </ListItem>
            </Link>
            <Link to={API_URL + "/billing-dashboard"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <CurrencyRupeeIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Billing Dashboard" />
              </ListItem>
            </Link>
            <Link to={API_URL + "/payment"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <CurrencyRupeeIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Buy Subscription" />
              </ListItem>
            </Link>
            <ListItem
              onClick={logout}
              style={{ cursor: "pointer" }}
              className="profile-list-item"
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#4b425d" }}>
                  <LogoutIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Col>
        <Col xs={{ span: 7, offset: 1 }}>
          <props.Children institute={user} />
        </Col>
      </Row>
      <Outlet />
    </>
  );
};

export default InstitutionProfileComponent;
