import React, { useState } from "react";
import "./RegisterComponent.css";
import {
  Paper,
  TextField,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormControl,
  Button,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REGISTER_ADMIN, SERVER_URL } from "../../../apiRoutes";

const AdminRegistration = () => {
  const [showPassword, setShowPassword] = useState(false);

  const [inputValue, setInputValue] = useState({
    user_fullname: "",
    user_email: "",
    user_mobile: "",
    user_password: "",
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const setData = (e) => {
    setInputValue((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  // console.log(inputValue);
  const addAdmin = () => {
    const { user_fullname, user_email, user_mobile, user_password } =
      inputValue;
    axios
      .post(`${SERVER_URL}/${REGISTER_ADMIN}`, {
        user_fullname,
        user_email,
        user_mobile,
        user_password,
      })
      .then((res) => {
        //    console.log(res.data);
        if (res.status === 202) {
          toast.success("Registered Successfully!!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <>
      {/* {
                error ? <ConfirmationComponent data={resMsg} children={resIcon} /> : "" 
            } */}
      <ToastContainer />
      <Paper
        elevation={3}
        sx={{
          padding: "50px 5%",
          width: "40%",
          margin: "auto",
          marginTop: "5%",
          alignContent: "center",
        }}
      >
        <h2
          style={{
            color: "#0E5E6f",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          SignUp !!
        </h2>
        <p className="loginHead">
          <span>
            <AccountCircleIcon sx={{ fontSize: "80px" }} />
          </span>
        </p>
        <div style={{ margin: "auto", marginLeft: "5%" }}>
          <TextField
            id="name-with-sx"
            label="Name"
            variant="outlined"
            value={inputValue.user_fullname}
            onChange={setData}
            name="user_fullname"
          />
          <TextField
            id="email-with-sx"
            label="Email"
            variant="outlined"
            value={inputValue.user_email}
            onChange={setData}
            name="user_email"
          />
          <TextField
            id="contact-num-with-sx"
            label="Contact No."
            variant="outlined"
            value={inputValue.user_mobile}
            onChange={setData}
            name="user_mobile"
          />
          <FormControl
            sx={{ display: "block", width: "300px" }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={inputValue.user_password}
              name="user_password"
              onChange={setData}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <Button
            variant="contained"
            onClick={addAdmin}
            sx={{
              backgroundColor: "#0E5E6F",
              color: "white",
              margin: "50px auto 0px 35%",
            }}
          >
            SignUp
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default AdminRegistration;
