import React, { useState, useEffect } from "react";
import "./adminStyle.css";
import {Accordion, AccordionSummary, AccordionDetails, Typography, Paper, Divider, Button} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { GET_ALL_PENDING_INST_REQ, APPROVE_PENDING_INST_REQ, SERVER_URL} from "../../apiRoutes";
import NoDataFound from "../common/noDataFound";

const JoiningRequest = () => {
  const [requests, setRequests] = useState([]);

  const getPendingRequest = () => {
    axios
      .get(`${SERVER_URL}/${GET_ALL_PENDING_INST_REQ}`)
      .then((res) => {
        if (res.status === 202) {
          // console.log(res.data);
          setRequests(res.data);
        } else if (res.status === 422) {
          toast.error("OOPs! Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error("OOPs! Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const approvePendingRequest = (inst_id) => {
    axios
      .post(`${SERVER_URL}/${APPROVE_PENDING_INST_REQ}/${inst_id}`)
      .then((res) => {
        if (res.status === 202) {
          // console.log(res.data);
          getPendingRequest();
          toast.success("Approved Successfully!!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 422) {
          // console.log("Something went wrong");
          toast.error("OOPs! Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error("OOPs! Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    getPendingRequest();
  }, []);

  return (
    <>
      <ToastContainer />
      {/* {
                error ? <ConfirmationComponent data={resMsg} children={resIcon} /> : "" 
            } */}
      {requests.length === 0 ? <NoDataFound /> : ""}
      <Paper className="request-paper" elevation={3}>
        {requests.map((req, index) => (
          <Accordion
            key={index}
            elevation={3}
            sx={{ marginBottom: "50px", padding: "20px" }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  color: "#0E5E6F",
                  fontSize: "18px",
                  fontWeight: 600,
                  letterSpacing: 1,
                }}
              >
                {req.inst_name}
              </Typography>
              <Button
                sx={{ width: "10%", marginLeft: "auto" }}
                variant="contained"
                color="success"
                onClick={() => approvePendingRequest(req.inst_id)}
              >
                Approve
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <Row>
                <Col xs={3}>
                  <Typography>Registered By</Typography>
                </Col>
                <Col>{req.inst_user}</Col>
              </Row>
              <Divider sx={{ marginBottom: "20px", marginTop: "5px" }} />
              <Row>
                <Col xs={3}>
                  <Typography>Institution Email</Typography>
                </Col>
                <Col>{req.inst_email}</Col>
              </Row>
              <Divider sx={{ marginBottom: "20px", marginTop: "5px" }} />
              <Row>
                <Col xs={3}>
                  <Typography>Institution Location</Typography>
                </Col>
                <Col>{req.inst_loc}</Col>
              </Row>
              <Divider sx={{ marginBottom: "20px", marginTop: "5px" }} />
              <Row>
                <Col xs={3}>
                  <Typography>Institution Campus policy</Typography>
                </Col>
                <Col>{req.drive_criteria}</Col>
              </Row>
              <Divider sx={{ marginBottom: "20px", marginTop: "5px" }} />
              <Row>
                <Col xs={3}>
                  <Typography>Contact No.</Typography>
                </Col>
                <Col>{req.inst_mobile}</Col>
              </Row>
              <Divider sx={{ marginBottom: "20px", marginTop: "5px" }} />
              <Row>
                <Col xs={3}>
                  <Typography>Affiliated University</Typography>
                </Col>
                <Col>{req.inst_university}</Col>
              </Row>
              <Divider sx={{ marginBottom: "20px", marginTop: "5px" }} />
              <Row>
                <Col xs={3}>
                  <Typography>Pincode</Typography>
                </Col>
                <Col>{req.inst_pincode}</Col>
              </Row>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </>
  );
};

export default JoiningRequest;
