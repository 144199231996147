import React from "react";
import Lottie from "react-lottie";
import success from "../../assets/lottie/success.json";

const SuccessLottie = () => {
  return (
    <>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: success,
        }}
        height={100}
        width={100}
      />
    </>
  );
};

export default SuccessLottie;
