import React, { useState } from "react";
import "../tpo/driveHistory/DriveHistory.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import { Button } from "@mui/material";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import ConfirmationComponent from "../common/ConfirmationComponent";
import { UPDATE_STUDENT_ELIGIBILITY, SERVER_URL } from "../../apiRoutes";

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const ShortlistedCandidatesComponent = () => {
  const { id, inst_id } = useParams("");
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location.state);
  const [error, setError] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [resIcon, setResIcon] = useState("");

  let shortlistedStudents = location.state.shortlistedStudents;
  let companyName = location.state.companyName;
  let profile = Object.keys(shortlistedStudents);
  let candidates = Object.values(shortlistedStudents);
  // console.log(shortlistedStudents)
  const student_id = [];

  candidates.map((profile) => {
    profile.map((student) => {
      student_id.push(student.id);
      return student;
    });
    return profile;
  });

  const pushEligibilityMessage = () => {
    axios
      .put(`${SERVER_URL}/${UPDATE_STUDENT_ELIGIBILITY}/${inst_id}/${location.state.drive_id}`, { student_id, companyName })
      .then(async (res) => {
        if (res.status === 422 || !res.data) {
          // console.log("Error");
          setResMsg(res.data);
          setResIcon("error");
          setError(true);
        } else if (res.status === 202) {
          setResMsg(res.data);
          setResIcon("success");
          setError(true);
          setTimeout(() => {
            navigate(`/tpo/${id}/${inst_id}/tpo-feeds`);
          }, 1500);
        }
      })
      .catch((err) => {
        setResMsg(err);
        setResIcon("error");
        setError(true);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    { field: "s_roll_no", headerName: "Enrollment No.", flex: 2 },
    { field: "s_name", headerName: "Name", flex: 2 },
    { field: "gender", headerName: "Gender", flex: 1 },
    { field: "current_cgpa", headerName: "CGPA", flex: 1 },
    { field: "tenth_percentage", headerName: "10th %", flex: 1 },
    { field: "twelve_percentage", headerName: "12th %", flex: 1 },
    { field: "backlogs", headerName: "Backlog", flex: 1 },
  ];

  return (
    <>
      {error ? <ConfirmationComponent data={resMsg} children={resIcon} /> : ""}
      <Button
        variant="contained"
        sx={{ float: "right", marginTop: "100px", marginRight: "50px" }}
        onClick={pushEligibilityMessage}
      >
        Send Notifications
      </Button>

      {candidates.map((profileCandidates, index) => (
        <div
          style={{
            height: "50vh",
            width: "80%",
            marginTop: "170px",
            marginLeft: "10%",
          }}
        >
          <p className="profile-heading">{profile[index]}</p>
          <StripedDataGrid
            className="drive-history-table"
            rows={profileCandidates}
            pageSize={10}
            columns={columns}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>
      ))}
    </>
  );
};

export default ShortlistedCandidatesComponent;
