import React, { useState } from "react";
import {
  Button,
  TextField,
  FormControl,
  Card,
  CardContent,
  Paper,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import axios from "axios";
import "./ForgotPasswordComponent.css";
import { ToastContainer, toast } from "react-toastify";
import { FORGOT_PASSWORD, SERVER_URL } from "../../../apiRoutes";

const ForgotPasswordComponent = () => {
  const [user_email, setEmail] = useState("");

  const sendEmail = async (e) => {
    // console.log(user_email);
    e.preventDefault();
    await axios
      .post(`${SERVER_URL}/${FORGOT_PASSWORD}`, {
        user_email,
      })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          toast.error("OOPs! Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <>
      <ToastContainer />

      <Paper elevation={3} className="forgot-pass-form">
        <Card>
          <CardContent className="forgot-pass-card">
            <p className="user-head">
              <span>
                <AccountCircleIcon sx={{ fontSize: "80px" }} />
              </span>
            </p>
            <FormControl variant="outlined">
              <TextField
                id="outlined-basic"
                label="UserEmail"
                varient="outlined"
                name="user_email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="email userInput"
              />
              <Button
                variant="outlined"
                onClick={sendEmail}
                className="sendBtn"
              >
                Send
              </Button>
            </FormControl>
          </CardContent>
        </Card>
      </Paper>
    </>
  );
};

export default ForgotPasswordComponent;
