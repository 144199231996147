import React, { useState, useEffect, useRef } from "react";
import S3 from "react-aws-s3";
import "./StudentProfileComponent.css";
import NavbarComponent from "../../../modules/common/navbar/NavbarComponent";
import { Row, Col, ProgressBar, Form } from "react-bootstrap";
import { Paper, styled, List, ListItem, ListItemAvatar, ListItemText, Avatar, Table, TableBody, TableCell, TableContainer, TableRow, Card, CardContent, Box, Typography, Button, DialogTitle, Dialog, FormControl, TextField, Select, MenuItem, InputLabel} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import InfoIcon from "@mui/icons-material/Info";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { tableCellClasses } from "@mui/material/TableCell";
import { Link, Outlet, useParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PropTypes from "prop-types";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import mime from "mime";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GET_INDIVIDUAL_PROJECT,
  UPDATE_STUDENT_EMAIL,
  UPDATE_STUDENT_GENDER,
  UPDATE_STUDENT_MOBILE,
  UPDATE_STUDENT_NAME,
  UPDATE_STUDENT_PASSWORD,
  REMOVE_INDIVIDUAL_PROJECT,
  ADD_STUDENT_PROJECT,
  UPDATE_CURRENT_YEAR,
  UPDATE_GRADUATION_YEAR,
  UPDATE_CURRENT_CGPA,
  UPDATE_CURRENT_BACKLOG,
  UPDATE_TWELVE_INST_NAME,
  UPDATE_TWELVE_BOARD,
  UPDATE_TWELVE_PASS_YEAR,
  UPDATE_TWELVE_PER,
  UPDATE_TENTH_INST_NAME,
  UPDATE_TENTH_BOARD,
  UPDATE_TENTH_PASS_YEAR,
  UPDATE_TENTH_PER,
  GET_INDIVIDUAL_EXPERIENCE,
  REMOVE_INDIVIDUAL_EXPERIENCE,
  ADD_STUDENT_EXPERIENCE,
  GET_INDIVIDUAL_ACTIVITY,
  REMOVE_INDIVIDUAL_ACTIVITY,
  ADD_STUDENT_ACTIVITY,
  GET_INDIVIDUAL_CERTIFICATE,
  REMOVE_INDIVIDUAL_CERTIFICATE,
  ADD_STUDENT_CERTIFICATE,
  GET_INDIVIDUAL_SKILLS,
  REMOVE_INDIVIDUAL_SKILLS,
  ADD_STUDENT_SKILLS,
  UPDATE_STUDENT_PROFILE_PIC,
  SERVER_URL,
} from "../../../apiRoutes";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const BasicInfoDetails = (props) => {
  const { student } = props;
  const {id, inst_id} = useParams();
  const [inputValue, setInputValue] = useState({
    s_name: "",
    s_email: "",
    s_gender: "",
    s_mobile: "",
  });
  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editGender, setEditGender] = useState(false);
  const [editMobile, setEditMobile] = useState(false);

  const enableEdit = (e) => {
    if (e.target.name === "editName") {
      setEditName(!editName);
    }
    if (e.target.name === "editEmail") {
      setEditEmail(!editEmail);
    }
    if (e.target.name === "editGender") {
      setEditGender(!editGender);
    }
    if (e.target.name === "editMobile") {
      setEditMobile(!editMobile);
    }
  };
  const handleEdit = (e) => {
    setInputValue((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const updateName = async (e) => {
    e.preventDefault();
    const { s_name } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_STUDENT_NAME}`, { s_name })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditName(!editEmail);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateEmail = async (e) => {
    e.preventDefault();
    const { s_email } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_STUDENT_EMAIL}`, { s_email })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditEmail(!editEmail);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateGender = async (e) => {
    e.preventDefault();
    const { s_gender } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_STUDENT_GENDER}`, { s_gender })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditGender(!editGender);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateMobile = async (e) => {
    e.preventDefault();
    const { s_mobile } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_STUDENT_MOBILE}`, { s_mobile })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditMobile(!editMobile);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  // console.log(inputValue);
  return (
    <>
      <ToastContainer />
      <Paper
        className="basic-details"
        elevation={3}
        id="basic-info"
        sx={{ minHeight: "400px" }}
      >
        <p>Basic Details</p>
        <TableContainer
          sx={{ padding: "20px 20px 50px 20px", minHeight: "400px" }}
        >
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="Name">
                <StyledTableCell component="th" scope="row">
                  Name
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editName ? (
                    <>
                      <Form.Control
                        type="email"
                        className="mt-3"
                        placeholder={student.s_name}
                        name="s_name"
                        onChange={handleEdit}
                      />
                    </>
                  ) : (
                    <>{student.s_name}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editName ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateName}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editName"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editName"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="email">
                <StyledTableCell component="th" scope="row">
                  Email
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editEmail ? (
                    <Form.Control
                      type="email"
                      className="mt-3"
                      name="s_email"
                      onChange={handleEdit}
                      placeholder={student.s_email}
                    />
                  ) : (
                    <>{student.s_email}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editEmail ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateEmail}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editEmail"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      disabled
                      name="editEmail"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="gender">
                <StyledTableCell component="th" scope="row">
                  Gender
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editGender ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="s_gender"
                      placeholder={student.gender}
                    />
                  ) : (
                    <>{student.gender}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editGender ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateGender}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editGender"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editGender"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="mobile">
                <StyledTableCell component="th" scope="row">
                  Mobile No.
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editMobile ? (
                    <Form.Control
                      type="telephone"
                      className="mt-3"
                      onChange={handleEdit}
                      name="s_mobile"
                      placeholder={student.s_mobile}
                    />
                  ) : (
                    <>{student.s_mobile}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editMobile ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateMobile}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editMobile"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editMobile"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export const AccountDetails = (props) => {
  const { student } = props;
  const {id, inst_id} = useParams();
  const [inputValue, setInputValue] = useState({
    s_name: "",
    user_password: "",
  });

  const [editUsername, setEditUsername] = useState(false);
  const [editPassword, setEditPassword] = useState(false);

  const enableEdit = (e) => {
    if (e.target.name === "editUsername") {
      setEditUsername(!editUsername);
    }
    if (e.target.name === "editPassword") {
      setEditPassword(!editPassword);
    }
  };
  const updateUsername = async (e) => {
    e.preventDefault();
    const { s_name } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_STUDENT_NAME}`, { s_name })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditUsername(!editUsername);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updatePassword = async (e) => {
    e.preventDefault();
    const email = student.s_email;
    axios
      .post(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_STUDENT_PASSWORD}`, { email })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
          // window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleEdit = (e) => {
    setInputValue((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  // console.log(inputValue);
  return (
    <>
      <ToastContainer />
      <Paper className="account-details" elevation={3}>
        <p>Account Info</p>
        <TableContainer sx={{ padding: "20px" }}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="s_name">
                <StyledTableCell component="th" scope="row">
                  Username
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editUsername ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      value={inputValue.s_name}
                      placeholder="Enter Username"
                      name="s_name"
                      onChange={handleEdit}
                    />
                  ) : (
                    <>{student.s_name}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {/* <Button sx={{color: '#0467a6'}} name="editUsername" onClick={enableEdit} >Edit</Button>  */}
                  {editUsername ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateUsername}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editUsername"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editUsername"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="password">
                <StyledTableCell component="th" scope="row">
                  Password
                </StyledTableCell>
                <StyledTableCell align="left">********</StyledTableCell>
                <StyledTableCell align="right">
                  <Button
                    sx={{ color: "#0467a6" }}
                    name="editPassword"
                    onClick={updatePassword}
                  >
                    Change
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export const ProjectDetails = () => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [projects, setProjects] = useState([]);
  const {id, inst_id} = useParams();

  const getProjects = () => {
    axios
      .get(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${GET_INDIVIDUAL_PROJECT}`)
      .then((res) => {
        if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          setProjects(res.data);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const removeProject = (project_id) => {
    axios
      .delete(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${REMOVE_INDIVIDUAL_PROJECT}/${project_id}`)
      .then((res) => {
        if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
          getProjects();
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    getProjects();
  }, []);
  // console.log(projects);

  return (
    <>
      <ToastContainer />
      <Paper sx={{}} className="project-details" elevation={3}>
        <p>Projects</p>
        <Row>
          {projects.map((pro) => (
            <Col xs={6}>
              <Card
                elevation={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "30px 30px 10px 30px",
                  padding: "15px",
                  color: "#044f6f",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{ fontWeight: "500", textAlign: "center" }}
                    >
                      {pro.project_name}
                    </Typography>
                    <Typography
                      sx={{
                        color: "gray",
                        fontWeight: "500",
                        fontSize: "13px",
                        marginTop: "20px",
                        textAlign: "center",
                      }}
                      component="div"
                    >
                      {pro.project_desc}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a href={pro.project_url} target="_blank" rel="noopener noreferrer">
                        <Button
                          variant="outlined"
                          color="secondary"
                          sx={{ marginRight: "50px" }}
                          className="project-edit-btn"
                        >
                          Demo
                        </Button>
                      </a>
                      <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ marginLeft: "50px" }}
                        onClick={() => removeProject(pro.project_id)}
                        className="project-edit-btn"
                      >
                        Remove
                      </Button>
                    </div>
                  </CardContent>
                </Box>
              </Card>
            </Col>
          ))}
        </Row>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClickOpen}
          sx={{ margin: "30px 50px", display: "block" }}
        >
          Add Project
        </Button>
        <AddProjects
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Paper>
    </>
  );
};
const AddProjects = (props) => {
  const { onClose, selectedValue, open } = props;
  const {id, inst_id} = useParams();
  const formik = useFormik({
    initialValues: {
      project_name: "",
      project_url: "",
      project_desc: "",
    },
    onSubmit: (values) => {
      const { project_name, project_url, project_desc } = values;

      axios.post(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${ADD_STUDENT_PROJECT}`, {
          project_name,
          project_url,
          project_desc,
        })
        .then((res) => {
          if (res.status === 202) {
            toast.success(res.data, {
              position: toast.POSITION.TOP_CENTER,
            });
            window.location.reload(false);
          } else if (res.status === 422 || !res.data) {
            toast.error(res.data, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          toast.error(err.response.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    },
    validationSchema: Yup.object({
      project_name: Yup.string().required("Required"),
      project_url: Yup.string().matches(
                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    'Enter correct url!'
                  ).required("Required"),
      project_desc: Yup.string().required("Required"),
    }),
  });

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <>
      <ToastContainer />
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ textAlign: "center" }} color="secondary">
          Add Project
        </DialogTitle>

        <form onSubmit={formik.handleSubmit} className="add-activity-dialog">
          <TextField
            id="outlined-basic"
            className="organization-name"
            label="Project Name"
            variant="outlined"
            name="project_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.project_name}
          />
          {formik.touched.project_name && formik.errors.project_name ? (
            <div>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "red",
                  marginBottom: "10px",
                  marginTop: "-10px",
                  fontSize: "12px",
                }}
              >
                {formik.errors.project_name}
              </Typography>
            </div>
          ) : (
            ""
          )}
          <TextField
            id="outlined-basic"
            className="position-title-name"
            label="Project Demo"
            variant="outlined"
            name="project_url"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.project_url}
          />
          {formik.touched.project_url && formik.errors.project_url ? (
            <div>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "red",
                  marginBottom: "10px",
                  marginTop: "-10px",
                  fontSize: "12px",
                }}
              >
                {formik.errors.project_url}
              </Typography>
            </div>
          ) : (
            ""
          )}
          <TextField
            id="outlined-basic"
            className="position-title-name"
            label="Project Description"
            variant="outlined"
            name="project_desc"
            multiline={true}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.project_desc}
          />
          {formik.touched.project_desc && formik.errors.project_desc ? (
            <div>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "red",
                  marginBottom: "10px",
                  marginTop: "-10px",
                  fontSize: "12px",
                }}
              >
                {formik.errors.project_url}
              </Typography>
            </div>
          ) : (
            ""
          )}

          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            sx={{ marginLeft: "30%", width: "35%", marginTop: "50px" }}
          >
            Save
          </Button>
        </form>
      </Dialog>
    </>
  );
};

export const EducationDetails = (props) => {
  const { student } = props;
  // console.log(student);
  const { id, inst_id } = useParams("");
  const [institution, setInstitution] = useState([]);
  const [editCurrentYear, setEditCurrentYear] = useState(false);
  const [editGraduationYear, setEditGraduationYear] = useState(false);
  const [editCurrentCgpa, setEditCurrentCgpa] = useState(false);
  const [editCurrentBacklog, setEditCurrentBacklog] = useState(false);
  const [editTwelveInstName, setEditTwelveInstName] = useState(false);
  const [editTwelveBoard, setEditTwelveBoard] = useState(false);
  const [editTwelveLocation, setEditTwelveLocation] = useState(false);
  const [editTwelvePassingYear, setEditTwelvePassingYear] = useState(false);
  const [editTwelvePer, setEditTwelvePer] = useState(false);
  // const [editTwelveResult, setEditTwelveResult] = useState(false);
  const [editTenthInstName, setEditTenthInstName] = useState(false);
  const [editTenthBoard, setEditTenthBoard] = useState(false);
  const [editTenthLocation, setEditTenthLocation] = useState(false);
  const [editTenthPassingYear, setEditTenthPassingYear] = useState(false);
  const [editTenthPer, setEditTenthPer] = useState(false);
  // const [editTenthResult, setEditTenthResult] = useState(false);

  const getInstitution = () => {
    axios
      .get(`${SERVER_URL}/student/${id}/${inst_id}/student-institution-data`)
      .then(async (res) => {
        if (res.status === 202) {
          const data = await res.data;
          setInstitution(data);
        } else if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const enableEdit = (e) => {
    if (e.target.name === "editCurrentYear") {
      setEditCurrentYear(!editCurrentYear);
    }
    if (e.target.name === "editGraduationYear") {
      setEditGraduationYear(!editGraduationYear);
    }
    if (e.target.name === "editCurrentCgpa") {
      setEditCurrentCgpa(!editCurrentCgpa);
    }
    if (e.target.name === "editCurrentBacklog") {
      setEditCurrentBacklog(!editCurrentBacklog);
    }
    if (e.target.name === "editTwelveInstName") {
      setEditTwelveInstName(!editTwelveInstName);
    }
    if (e.target.name === "editTwelveBoard") {
      setEditTwelveBoard(!editTwelveBoard);
    }
    if (e.target.name === "editTwelveLocation") {
      setEditTwelveLocation(!editTwelveLocation);
    }
    if (e.target.name === "editTwelvePassingYear") {
      setEditTwelvePassingYear(!editTwelvePassingYear);
    }
    if (e.target.name === "editTwelvePer") {
      setEditTwelvePer(!editTwelvePer);
    }
    // if(e.target.name === 'editTwelveResult'){
    //     setEditTwelveResult(!editTwelveResult)
    // }
    if (e.target.name === "editTenthInstName") {
      setEditTenthInstName(!editTenthInstName);
    }
    if (e.target.name === "editTenthBoard") {
      setEditTenthBoard(!editTenthBoard);
    }
    if (e.target.name === "editTenthLocation") {
      setEditTenthLocation(!editTenthLocation);
    }
    if (e.target.name === "editTenthPassingYear") {
      setEditTenthPassingYear(!editTenthPassingYear);
    }
    if (e.target.name === "editTenthPer") {
      setEditTenthPer(!editTenthPer);
    }
    // if(e.target.name === 'editTenthResult'){
    //     setEditTenthResult(!editTenthResult)
    // }
  };

  const [inputValue, setInputValue] = useState({
    current_year: "",
    graduation_year: "",
    current_cgpa: 0,
    current_backlog: 0,
    twelve_institute_name: "",
    twelve_board: "",
    twelve_passing_year: "",
    twelve_per: 0,
    tenth_institute_name: "",
    tenth_board: "",
    tenth_passing_year: "",
    tenth_per: 0,
  });

  const updateCurrentYear = async (e) => {
    e.preventDefault();
    const { current_year } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_CURRENT_YEAR}`, { current_year })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditCurrentYear(!editCurrentYear);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateGraduationYear = async (e) => {
    e.preventDefault();
    const { graduation_year } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_GRADUATION_YEAR}`, { graduation_year })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditGraduationYear(!editGraduationYear);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateCurrentCgpa = async (e) => {
    e.preventDefault();
    const { current_cgpa } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_CURRENT_CGPA}`, { current_cgpa })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditCurrentCgpa(!editCurrentCgpa);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateCurrentBacklog = async (e) => {
    e.preventDefault();
    const { current_backlog } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_CURRENT_BACKLOG}`, { current_backlog })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditCurrentBacklog(!editCurrentBacklog);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateTwelveInstitutionName = async (e) => {
    e.preventDefault();
    const { twelve_institute_name } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_TWELVE_INST_NAME}`, { twelve_institute_name })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditTwelveInstName(!editTwelveInstName);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateTwelveBoard = async (e) => {
    e.preventDefault();
    const { twelve_board } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_TWELVE_BOARD}`, { twelve_board })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditTwelveBoard(!editTwelveBoard);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateTwelvePassingYear = async (e) => {
    e.preventDefault();
    const { twelve_passing_year } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_TWELVE_PASS_YEAR}`, { twelve_passing_year })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditTwelvePassingYear(!editTwelvePassingYear);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateTwelvePercentage = async (e) => {
    e.preventDefault();
    const { twelve_per } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_TWELVE_PER}`, { twelve_per })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditTwelvePer(!editTwelvePer);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateTenthInstitutionName = async (e) => {
    e.preventDefault();
    const { tenth_institute_name } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_TENTH_INST_NAME}`, { tenth_institute_name })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditTenthInstName(!editTenthInstName);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateTenthBoard = async (e) => {
    e.preventDefault();
    const { tenth_board } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_TENTH_BOARD}`, { tenth_board })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditTenthBoard(!editTenthBoard);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateTenthPassingYear = async (e) => {
    e.preventDefault();
    const { tenth_passing_year } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_TENTH_PASS_YEAR}`, { tenth_passing_year })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditTenthPassingYear(!editTenthPassingYear);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateTenthPercentage = async (e) => {
    e.preventDefault();
    const { tenth_per } = inputValue;
    axios
      .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_TENTH_PER}`, { tenth_per })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          // alert(res.data);
          setEditTenthPer(!editTenthPer);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleEdit = (e) => {
    setInputValue((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  // console.log(inputValue);

  useEffect(() => {
    getInstitution();
  }, []);
  return (
    <>
      <ToastContainer />
      <Paper className="education-details" elevation={3}>
        <div className="education-edit-section">
          <p>Current Education Details</p>
          {/* <Button variant="outlined" onClick={enableEdit} className="education-edit-btn" color="secondary" sx={{marginRight: '30px'}} >Edit</Button> */}
        </div>
        <TableContainer sx={{ padding: "20px 20px 50px 20px" }}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="clg_name">
                <StyledTableCell component="th" scope="row">
                  Institution
                </StyledTableCell>
                <StyledTableCell align="left">
                  {institution.inst_name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Button disabled>Edit</Button>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="course">
                <StyledTableCell component="th" scope="row">
                  Enrolled Course
                </StyledTableCell>
                <StyledTableCell align="left">
                  {student.course_id}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Button disabled>Edit</Button>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="branch">
                <StyledTableCell component="th" scope="row">
                  Branch
                </StyledTableCell>
                <StyledTableCell align="left">
                  {student.branch_id}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Button disabled>Edit</Button>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="current_year">
                <StyledTableCell component="th" scope="row">
                  Current Year
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editCurrentYear ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="current_year"
                      placeholder="Current year"
                    />
                  ) : (
                    <>{student.current_year}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editCurrentYear ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateCurrentYear}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editCurrentYear"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editCurrentYear"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="graduation_year">
                <StyledTableCell component="th" scope="row">
                  Graduation Year
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editGraduationYear ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="graduation_year"
                      placeholder="Graduation year"
                    />
                  ) : (
                    <>{student.graduation_year}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editGraduationYear ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateGraduationYear}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editGraduationYear"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editGraduationYear"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="current_cgpa">
                <StyledTableCell component="th" scope="row">
                  Current CGPA
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editCurrentCgpa ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="current_cgpa"
                      placeholder="Current CGPA"
                    />
                  ) : (
                    <>{student.ug_per}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editCurrentCgpa ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateCurrentCgpa}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editCurrentCgpa"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editCurrentCgpa"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="current_backlog">
                <StyledTableCell component="th" scope="row">
                  Current Backlog
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editCurrentBacklog ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="current_backlog"
                      placeholder="Current CGPA"
                    />
                  ) : (
                    <>{student.backlogs}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editCurrentBacklog ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateCurrentBacklog}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editCurrentBacklog"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editCurrentBacklog"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <p>Class 12th/ Equivalent</p>
        <TableContainer sx={{ padding: "20px" }}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="twelve_institute_name">
                <StyledTableCell component="th" scope="row">
                  Institution
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editTwelveInstName ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="twelve_institute_name"
                      placeholder="Institution Name"
                    />
                  ) : (
                    <>{student.twelve_institute_name}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editTwelveInstName ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateTwelveInstitutionName}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editTwelveInstName"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editTwelveInstName"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="twelve_board">
                <StyledTableCell component="th" scope="row">
                  Board
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editTwelveBoard ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="twelve_board"
                      placeholder="Board Name"
                    />
                  ) : (
                    <>{student.twelve_board}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editTwelveBoard ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateTwelveBoard}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editTwelveBoard"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editTwelveBoard"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="twelve_passing_year">
                <StyledTableCell component="th" scope="row">
                  Passed Out
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editTwelvePassingYear ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="twelve_passing_year"
                      placeholder="Passed out in "
                    />
                  ) : (
                    <>{student.twelve_passing_year}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editTwelvePassingYear ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateTwelvePassingYear}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editTwelvePassingYear"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editTwelvePassingYear"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="twelve_per">
                <StyledTableCell component="th" scope="row">
                  12th Percentage
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editTwelvePer ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="twelve_per"
                      placeholder="12th Percentage"
                    />
                  ) : (
                    <>{student.twelve_percentage}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editTwelvePer ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateTwelvePercentage}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editTwelvePer"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editTwelvePer"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <p>Class 10th</p>
        <TableContainer sx={{ padding: "20px" }}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="tenth_institute_name">
                <StyledTableCell component="th" scope="row">
                  Institution
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editTenthInstName ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="tenth_institute_name"
                      placeholder="Institution Name"
                    />
                  ) : (
                    <>{student.tenth_institute_name}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editTenthInstName ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateTenthInstitutionName}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editTenthInstName"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editTenthInstName"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="tenth_board">
                <StyledTableCell component="th" scope="row">
                  Board
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editTenthBoard ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="tenth_board"
                      placeholder="Board Name"
                    />
                  ) : (
                    <>{student.tenth_board}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editTenthBoard ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateTenthBoard}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editTenthBoard"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editTenthBoard"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="tenth_passing_year">
                <StyledTableCell component="th" scope="row">
                  Passed Out
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editTenthPassingYear ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="tenth_passing_year"
                      placeholder="Passed out in "
                    />
                  ) : (
                    <>{student.tenth_passing_year}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editTenthPassingYear ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateTenthPassingYear}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editTenthPassingYear"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editTenthPassingYear"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="tenth_per">
                <StyledTableCell component="th" scope="row">
                  10th Percentage
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editTenthPer ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      onChange={handleEdit}
                      name="tenth_per"
                      placeholder="12th Percentage"
                    />
                  ) : (
                    <>{student.tenth_percentage}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editTenthPer ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateTenthPercentage}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editTenthPer"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editTenthPer"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export const InternshipDetails = () => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [experience, setExperience] = useState([]);
  const {id, inst_id} = useParams();
  const getStudentExperience = () => {
    axios.get(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${GET_INDIVIDUAL_EXPERIENCE}`)
      .then((res) => {
        if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          setExperience(res.data);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error("OOPs! something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const removeStudentExperience = (exp_id) => {
    axios.delete(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${REMOVE_INDIVIDUAL_EXPERIENCE}/${exp_id}`)
      .then((res) => {
        if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
          getStudentExperience();
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const onDownload = async (urlFile) => {
    const splittedURLFile = urlFile.split("/");
    const filename = splittedURLFile[splittedURLFile.length - 1];
    const splittedFilename = filename.split(".");
    const fileExtension = splittedFilename[splittedFilename.length - 1];

    const fileType = mime.getType(fileExtension);

    try {
      const { data } = await axios.get(urlFile, {
        responseType: "blob",
      });

      const objectURL = URL.createObjectURL(
        new File([data], filename, { type: fileType })
      );

      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.href = objectURL;
      downloadLink.download = filename;
      downloadLink.style.display = "none";
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      // console.error(error)
      toast.error(
        "OOPs! something went wrong while downloading your document",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  useEffect(() => {
    getStudentExperience();
  }, []);

  return (
    <>
      <ToastContainer />
      <Paper className="internship-details" elevation={3}>
        <p>Internships/ Work Experience</p>
        <Row>
          {experience.map((exp) => (
            <Col xs={6}>
              <Card
                elevation={4}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "30px 50px 10px 50px",
                  padding: "30px 30px 0px 30px",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{ fontWeight: "500" }}
                    >
                      {exp.job_title}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      {exp.org_name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      {exp.job_domain}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      {exp.job_loc}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      component="div"
                    >
                      {exp.start_date} - {exp.end_date}
                    </Typography>
                    <Row>
                      <Col>
                        <Button
                          variant="outlined"
                          className="intern-edit-btn"
                          onClick={() => removeStudentExperience(exp.exp_id)}
                          sx={{ color: "#0E5E6F", borderColor: "#0E5E6F" }}
                        >
                          Remove
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{
                            color: "#0E5E6F",
                            marginLeft: "90%",
                            marginTop: "-65px",
                            borderColor: "#0E5E6F",
                          }}
                          onClick={() => onDownload(exp.exp_media)}
                        >
                          Download{" "}
                          <SystemUpdateAltIcon sx={{ marginLeft: "10%" }} />
                        </Button>
                      </Col>
                    </Row>
                  </CardContent>
                </Box>
                {/* <CardMedia component="img" sx={{ width: 100, height: 100, marginTop: '5%' }} image={CompanyImage} alt="company_img"/> */}
                <cardMedia>
                  <Avatar
                    variant="rounded"
                    sx={{
                      bgcolor: "#013c54f7",
                      margin: "auto",
                      marginTop: "20px",
                      textAlign: "center",
                      width: "100px",
                      height: "100px",
                    }}
                  >
                    <span style={{ fontSize: "50px" }}>
                      {exp.org_name[0].toUpperCase()}
                    </span>
                  </Avatar>
                </cardMedia>
              </Card>
            </Col>
          ))}
        </Row>

        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClickOpen}
          className="experience-btn"
        >
          Add Experience
        </Button>
        <AddExperience
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Paper>
    </>
  );
};
const AddExperience = (props) => {
  const { onClose, selectedValue, open } = props;
  const {id, inst_id} = useParams();
  const inputRef = useRef();
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: "student/experience",
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };
  const formik = useFormik({
    initialValues: {
      org_name: "",
      job_title: "",
      job_loc: "",
      job_domain: "",
      start_date: "",
      end_date: "",
      exp_media: "",
    },
    onSubmit: (values) => {
      const { org_name, job_title, job_loc, job_domain, start_date, end_date } =
        values;
      const file = values.exp_media;

      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, `${Date.now()}.${file.name}`)
        .then((data) => {
          // console.log(data.location);
          if (!data.location.length) {
            toast.error("OOPs! something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
          const media_url = data.location;

          axios
            .post(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${ADD_STUDENT_EXPERIENCE}`, {
              org_name,
              job_title,
              job_loc,
              job_domain,
              start_date,
              end_date,
              media_url,
            })
            .then((res) => {
              // console.log(res.data);
              if(res.status === 202){
                toast.success(res.data, {
                  position: toast.POSITION.TOP_CENTER,
                });
                window.location.reload(false);
              }
            })
            .catch((err) => {
              // console.log(err);
              toast.error(err.response.data, {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        })
        .catch((err) => {
          // console.log(err);
          toast.error(err.response.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    },
    validationSchema: Yup.object({
      org_name: Yup.string().required("Required"),
      job_title: Yup.string().required("Required"),
      job_loc: Yup.string().required("Required"),
      job_domain: Yup.string().required("Required"),
      start_date: Yup.string().required("Required"),
      end_date: Yup.string().required("Required"),
      exp_media: Yup.mixed().required("Please provide proof of your work"),
    }),
  });

  const handleClose = () => {
    onClose(selectedValue);
  };
  const handleFileInput = (e) => {
    formik.values.exp_media = e.target.files[0];
  };

  // console.log(formik.values)

  return (
    <>
      <ToastContainer />
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ textAlign: "center" }} color="secondary">
          Add New Experience
        </DialogTitle>

        <form onSubmit={formik.handleSubmit} className="add-exp-dialog">
          <TextField
            id="outlined-basic"
            className="organization-name"
            label="Organization"
            variant="outlined"
            name="org_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.org_name}
          />
          {formik.touched.org_name && formik.errors.org_name ? (
            <div>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "red",
                  marginBottom: "10px",
                  marginTop: "-10px",
                  fontSize: "12px",
                }}
              >
                {formik.errors.org_name}
              </Typography>
            </div>
          ) : (
            ""
          )}
          <TextField
            id="outlined-basic"
            className="job-title-name"
            label="Job Title"
            variant="outlined"
            name="job_title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.job_title}
          />
          {formik.touched.job_title && formik.errors.job_title ? (
            <div>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "red",
                  marginBottom: "10px",
                  marginTop: "-10px",
                  fontSize: "12px",
                }}
              >
                {formik.errors.job_title}
              </Typography>
            </div>
          ) : (
            ""
          )}
          <TextField
            id="outlined-basic"
            className="location-name"
            label="Location"
            variant="outlined"
            name="job_loc"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.job_loc}
          />
          {formik.touched.job_loc && formik.errors.job_loc ? (
            <div>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "red",
                  marginBottom: "10px",
                  marginTop: "-10px",
                  fontSize: "12px",
                }}
              >
                {formik.errors.job_loc}
              </Typography>
            </div>
          ) : (
            ""
          )}
          <TextField
            id="outlined-basic"
            className="sector-name"
            label="Organization Sector"
            variant="outlined"
            name="job_domain"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.job_domain}
          />
          {formik.touched.job_domain && formik.errors.job_domain ? (
            <div>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "red",
                  marginBottom: "10px",
                  marginTop: "-10px",
                  fontSize: "12px",
                }}
              >
                {formik.errors.job_domain}
              </Typography>
            </div>
          ) : (
            ""
          )}
          <div className="intern-duration-date-section">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Joining Date"
                inputFormat="YYYY/MM/DD"
                className="date-selector"
                onChange={(date) => {
                  const d = new Date(date).toLocaleDateString("fr-FR");
                  // console.log(d);
                  formik.values.start_date = d;
                }}
                value={formik.values.start_date}
                onBlur={formik.handleBlur}
                name="start_date"
                renderInput={(params) => (
                  <TextField value={formik.values.start_date} {...params} />
                )}
              />
              {formik.touched.start_date && formik.errors.start_date ? (
                <div>
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      fontSize: "12px",
                      position: "absolute",
                      bottom: "145px",
                      left: "100px",
                    }}
                  >
                    {formik.errors.start_date}
                  </Typography>
                </div>
              ) : (
                ""
              )}
              <DesktopDatePicker
                label="Leaving Date"
                className="date-selector"
                inputFormat="YYYY/MM/DD"
                onChange={(date) => {
                  const d = new Date(date).toLocaleDateString("fr-FR");
                  console.log(d);
                  formik.values.end_date = d;
                }}
                name="end_date"
                value={formik.values.end_date}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField value={formik.values.end_date} {...params} />
                )}
              />
              {formik.touched.end_date && formik.errors.end_date ? (
                <div>
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "red",
                      position: "absolute",
                      bottom: "145px",
                      right: "250px",
                      fontSize: "12px",
                      marginTop: "10px",
                    }}
                  >
                    {formik.errors.end_date}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </LocalizationProvider>
          </div>
          <Button
            variant="outlined"
            sx={{ color: "#044f6f" }}
            className="attach-file-btn"
            onClick={() => {
              console.log(inputRef);
              inputRef.current.click();
            }}
          >
            <input
              ref={inputRef}
              type="file"
              id="exp_media"
              name="exp_media"
              onChange={handleFileInput}
              hidden
            />
            Attach File
            <span htmlFor="exp_media">
              <AttachFileIcon />
            </span>
          </Button>
          {formik.touched.exp_media && formik.errors.exp_media ? (
            <div>
              <Typography
                sx={{ textAlign: "left", color: "red", fontSize: "12px" }}
              >
                {formik.errors.exp_media}
              </Typography>
            </div>
          ) : (
            ""
          )}
          {/* <FormControlLabel control={<Checkbox {...label} defaultChecked color="success" />} label="Currently working here" /> */}

          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            sx={{ marginLeft: "30%", width: "35%" }}
          >
            Save
          </Button>
        </form>
      </Dialog>
    </>
  );
};

AddExperience.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export const ExtraCurricularDetails = () => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [activities, setActivities] = useState([]);
  const {id, inst_id} = useParams();
  const getStudentActivity = () => {
    axios.get(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${GET_INDIVIDUAL_ACTIVITY}`)
      .then((res) => {
        if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          setActivities(res.data);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const removeActivity = (activity_id) => {
    axios
      .delete(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${REMOVE_INDIVIDUAL_ACTIVITY}/${activity_id}`)
      .then((res) => {
        if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
          getStudentActivity();
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  useEffect(() => {
    getStudentActivity();
  }, []);

  return (
    <>
      <ToastContainer />
      <Paper className="extra-curricular-activity-details" elevation={3}>
        <p>Extra Curricular Activities</p>

        <Row>
          {activities.map((activity) => (
            <Col xs={6}>
              <Card
                elevation={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "30px 30px 10px 30px",
                  padding: "15px",
                  color: "#044f6f",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{ fontWeight: "500", textAlign: "center" }}
                    >
                      {activity.activity_name}
                    </Typography>
                    <Typography
                      sx={{
                        color: "gray",
                        fontWeight: "500",
                        fontSize: "13px",
                        marginTop: "20px",
                        textAlign: "center",
                      }}
                      component="div"
                    >
                      {activity.activity_position}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ marginLeft: "35%" }}
                      onClick={() => removeActivity(activity.activity_id)}
                      className="project-edit-btn"
                    >
                      Remove
                    </Button>
                  </CardContent>
                </Box>
              </Card>
            </Col>
          ))}
        </Row>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClickOpen}
          className="activity-btn"
        >
          Add Activity
        </Button>
        <AddExtraCurricularActivities
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Paper>
    </>
  );
};
const AddExtraCurricularActivities = (props) => {
  const { onClose, selectedValue, open } = props;
  const {id, inst_id} = useParams();
  const formik = useFormik({
    initialValues: {
      activity_name: "",
      activity_position: "",
    },
    onSubmit: (values) => {
      const { activity_name, activity_position } = values;

      axios.post(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${ADD_STUDENT_ACTIVITY}`, {
          activity_name,
          activity_position,
        })
        .then((res) => {
          if (res.status === 422) {
            toast.error(res.data, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 202) {
            toast.success(res.data, {
              position: toast.POSITION.TOP_CENTER,
            });
            window.location.reload(false);
          }
        })
        .catch((err) => {
          toast.error(err.response.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    },
    validationSchema: Yup.object({
      activity_name: Yup.string().required("Required"),
      activity_position: Yup.string().required("Required"),
    }),
  });
  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <>
      <ToastContainer />
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ textAlign: "center" }} color="secondary">
          Add Activity
        </DialogTitle>

        <form onSubmit={formik.handleSubmit} className="add-activity-dialog">
          <TextField
            id="outlined-basic"
            className="organization-name"
            label="Activity Name"
            variant="outlined"
            name="activity_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.activity_name}
          />
          <TextField
            id="outlined-basic"
            className="position-title-name"
            label="Position Title"
            variant="outlined"
            name="activity_position"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.activity_position}
          />
          {/* <div className="intern-duration-date-section">
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DesktopDatePicker label="Start Date" inputFormat="MM/DD/YYYY" className="date-selector" value={value} onChange={handleChange} renderInput={(params) => <TextField {...params} />} />
                            <DesktopDatePicker label="End Date" className="date-selector" inputFormat="MM/DD/YYYY" value={value} onChange={handleChange} renderInput={(params) => <TextField {...params} />} />
                        </LocalizationProvider>

                    </div> */}
          {/* <FormControlLabel control={<Checkbox {...label} defaultChecked color="success" />} label="Currently part of it!" /> */}

          <Button
            variant="outlined"
            type="submit"
            color="secondary"
            sx={{ marginLeft: "30%", width: "35%" }}
          >
            Save
          </Button>
        </form>
      </Dialog>
    </>
  );
};
AddExtraCurricularActivities.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export const CertificationDetails = () => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [certification, setCertification] = useState([]);
  const {id, inst_id} = useParams();
  const getStudentCertification = () => {
    axios.get(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${GET_INDIVIDUAL_CERTIFICATE}`)
      .then((res) => {
        if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          setCertification(res.data);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const removeStudentCertification = (certificate_id) => {
    axios.delete(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${REMOVE_INDIVIDUAL_CERTIFICATE}/${certificate_id}`)
      .then((res) => {
        if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
          getStudentCertification();
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const onDownload = async (urlFile) => {
    const splittedURLFile = urlFile.split("/");
    const filename = splittedURLFile[splittedURLFile.length - 1];
    const splittedFilename = filename.split(".");
    const fileExtension = splittedFilename[splittedFilename.length - 1];

    const fileType = mime.getType(fileExtension);

    try {
      const { data } = await axios.get(urlFile, {
        responseType: "blob",
      });

      const objectURL = URL.createObjectURL(
        new File([data], filename, { type: fileType })
      );

      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.href = objectURL;
      downloadLink.download = filename;
      downloadLink.style.display = "none";
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      // console.error(error);
      toast.error(
        "OOPs! something went wrong while downloading your document",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  useEffect(() => {
    getStudentCertification();
  }, []);

  return (
    <>
      <ToastContainer />
      <Paper className="certification-details" elevation={3}>
        <p>Certifications</p>
        <Row>
          {certification.map((cert) => (
            <Col xs={6}>
              <Card
                elevation={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "30px 50px 10px 50px",
                  width: "350px",
                  padding: "15px 15px 0px 15px",
                  color: "#044f6f",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{ fontWeight: "500" }}
                    >
                      {cert.certificate_name}
                    </Typography>
                    <Row>
                      <Col>
                        <Button
                          variant="outlined"
                          className="activity-edit-btn"
                          onClick={() =>
                            removeStudentCertification(cert.certificate_id)
                          }
                          sx={{ color: "#0E5E6F", borderColor: "#0E5E6F" }}
                        >
                          Remove
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          sx={{
                            marginLeft: "50%",
                            float: "right",
                            color: "#0E5E6F",
                            border: '1px solid #0E5E6F',
                            paddingLeft:'10px',
                            paddingRight:'10px'
                          }}
                          className="activity-edit-btn"
                          color="secondary"
                          onClick={() => onDownload(cert.certificate_url)}
                        >
                          Download{" "}
                          <SystemUpdateAltIcon size={15} sx={{marginLeft:'10%'}} />
                        </Button>
                      </Col>
                    </Row>
                  </CardContent>
                </Box>
              </Card>
            </Col>
          ))}
        </Row>

        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClickOpen}
          className="certification-btn"
        >
          Add Certification
        </Button>

        <AddCertification
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Paper>
    </>
  );
};
const AddCertification = (props) => {
  const { onClose, selectedValue, open } = props;
  const {id, inst_id} = useParams();
  const inputRef = useRef();
  // const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      certification_name: "",
      certification_file: "",
    },
    onSubmit: (values) => {
      const { certification_name } = values;
      const file = values.certification_file;

      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, `${Date.now()}.${file.name}`)
        .then((data) => {
          // console.log(data.location);
          if (!data.location.length) {
            toast.error(
              "OOPs! something went wrong while uploading your document",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          }
          const certificate_url = data.location;

          axios.post(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${ADD_STUDENT_CERTIFICATE}`, {
              certification_name,
              certificate_url,
            })
            .then((res) => {
              if (res.status === 422) {
                toast.error(res.data, {
                  position: toast.POSITION.TOP_CENTER,
                });
              } else if (res.status === 202) {
                toast.success(res.data, {
                  position: toast.POSITION.TOP_CENTER,
                });
                window.location.reload(false);
              }
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.response.data, {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        })
        .catch((err) => {
          // console.error(err);
          toast.error(err.response.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    },
    validationSchema: Yup.object({
      certification_name: Yup.string().required("Required"),
      certification_file: Yup.mixed().required("File is required"),
    }),
  });

  const handleClose = () => {
    onClose(selectedValue);
  };
  const handleFileInput = (e) => {
    formik.values.certification_file = e.target.files[0];
  };

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: "student/certification",
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };

  // console.log(formik.values);

  return (
    <>
      <ToastContainer />
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ textAlign: "center" }} color="secondary">
          Add Certification
        </DialogTitle>

        <form
          onSubmit={formik.handleSubmit}
          className="add-certification-dialog"
        >
          <div>
            <TextField
              id="outlined-basic"
              className="certification-name"
              label="Certification Name"
              variant="outlined"
              name="certification_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.certification_name}
            />
            {formik.touched.certification_name &&
            formik.errors.certification_name ? (
              <div>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.certification_name}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </div>
          <Button
            variant="outlined"
            sx={{ color: "#044f6f" }}
            className="attach-file-btn"
            onClick={() => {
              console.log(inputRef);
              inputRef.current.click();
            }}
          >
            <input
              ref={inputRef}
              type="file"
              id="certificate"
              name="certification_file"
              onChange={handleFileInput}
              hidden
            />
            Attach File
            <span htmlFor="certificate">
              <AttachFileIcon />
            </span>
          </Button>
          {formik.touched.certification_file &&
          formik.errors.certification_file ? (
            <div>
              <Typography
                sx={{ textAlign: "left", color: "red", fontSize: "12px" }}
              >
                {formik.errors.certification_file}
              </Typography>
            </div>
          ) : (
            ""
          )}

          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            className="certification-save-btn"
          >
            Save
          </Button>
        </form>
      </Dialog>
    </>
  );
};
AddCertification.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export const TechSkillDetails = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [skills, setSkills] = useState([]);
  const {id, inst_id} = useParams();
  const getStudentSkills = () => {
    axios.get(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${GET_INDIVIDUAL_SKILLS}`)
      .then((res) => {
        if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          setSkills(res.data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const removeSkill = (skill_id) => {
    // console.log(skill_id);
    axios
      .delete(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${REMOVE_INDIVIDUAL_SKILLS}/${skill_id}`)
      .then((res) => {
        if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
          getStudentSkills();
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    getStudentSkills();
  }, []);

  return (
    <>
      <ToastContainer />
      <Paper className="tech-skills-details" elevation={3}>
        <p>Tech Skills</p>
        <TableContainer sx={{ padding: "20px 20px 50px 20px" }}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableBody>
              {skills.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.skill_name}
                  </TableCell>
                  <TableCell align="center" style={{ width: "500px" }}>
                    <ProgressBar
                      now={row.skill_level}
                      className="skill-progress-bar"
                      label={`${row.skill_level}%`}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      sx={{ color: "#0E5E6F" }}
                      onClick={() => removeSkill(row.skill_id)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          variant="outlined"
          sx={{ color: "#0E5E6F" }}
          onClick={handleClickOpen}
          className="add-skill-btn"
        >
          Add Skill
        </Button>
        <AddTechSkill
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Paper>
    </>
  );
};
const AddTechSkill = (props) => {
  const { onClose, selectedValue, open } = props;
  const {id, inst_id} = useParams();
  const formik = useFormik({
    initialValues: {
      skill_name: "",
      skill_level: "",
    },
    onSubmit: (values) => {
      const { skill_name, skill_level } = values;
      axios
        .post(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${ADD_STUDENT_SKILLS}`, { skill_name, skill_level })
        .then((res) => {
          if (res.status === 422 || !res.data) {
            toast.error(res.data, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 202) {
            toast.success(res.data, {
              position: toast.POSITION.TOP_CENTER,
            });
            window.location.reload(false);
          }
        })
        .catch((err) => {
          // console.log(err);
          toast.error(err.response.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    },
    validationSchema: Yup.object({
      skill_name: Yup.string().required("Required"),
      skill_level: Yup.string().required(
        "Please select your level of understanding for the provided skill"
      ),
    }),
  });
  const handleClose = () => {
    onClose(selectedValue);
  };

  // console.log(formik.values);

  return (
    <>
      <ToastContainer />
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ textAlign: "center" }} color="secondary">
          Add Skill
        </DialogTitle>

        <form onSubmit={formik.handleSubmit} className="add-skill-dialog">
          <TextField
            id="outlined-basic"
            className="skill-name"
            label="Skill"
            variant="outlined"
            name="skill_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.skill_name}
          />
          {formik.touched.skill_name && formik.errors.skill_name ? (
            <div>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "red",
                  marginBottom: "10px",
                  marginTop: "-10px",
                  fontSize: "12px",
                }}
              >
                {formik.errors.skill_name}
              </Typography>
            </div>
          ) : (
            ""
          )}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Skill Level</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formik.values.skill_level}
              label="Skill Level"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="skill_level"
            >
              <MenuItem className="skill-list-item" value={50}>
                Intermediate
              </MenuItem>
              <MenuItem className="skill-list-item" value={25}>
                Basic
              </MenuItem>
              <MenuItem className="skill-list-item" value={75}>
                Advance
              </MenuItem>
              <MenuItem className="skill-list-item" value={100}>
                Professional
              </MenuItem>
            </Select>
            {formik.touched.skill_level && formik.errors.skill_level ? (
              <div>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: "red",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.skill_level}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </FormControl>

          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            className="skill-btn"
          >
            Save
          </Button>
        </form>
      </Dialog>
    </>
  );
};

AddTechSkill.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

const StudentProfileComponent = (props) => {
  const user_role = "student";
  const inputRef = useRef();
  const { id, inst_id } = useParams("");
  const [user, setUser] = useState([]);
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: "student/profile",
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];
  const formik = useFormik({
    initialValues: {
      profile_pic: "",
    },
    onSubmit: (values) => {
      const file = values.profile_pic;
      // console.log(file);
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, `${Date.now()}.${file.name}`).then(
        (data) => {
          // console.log(data.location);
          if (!data.location.length) {
            toast.error("OOPs! Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
          const imgUrl = data.location;
          axios
            .put(`${SERVER_URL}/student/${id}/${inst_id}/student-profile/${UPDATE_STUDENT_PROFILE_PIC}`, { imgUrl })
            .then((res) => {
              if (res.status === 202) {
                toast.success(res.data, {
                  position: toast.POSITION.TOP_CENTER,
                });
                window.location.reload(false);
              } else if (res.status === 422) {
                toast.error(res.data, {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.response.data, {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        }
      );
    },
    validationSchema: Yup.object({
      profile_pic: Yup.mixed()
        .test(
          "fileSize",
          "File Size is too large",
          (value) => value.size >= 20000
        )
        .test("fileType", "Unsupported File Format", (value) =>
          SUPPORTED_FORMATS.includes(value.type)
        ),
    }),
  });
  const getUser = async () => {
    axios
      .get(`${SERVER_URL}/student/${id}/${inst_id}/student-profile-data`)
      .then(async (res) => {
        if (res.status === 202) {
          const data = await res.data;
          setUser(data);
          data.backlogs = JSON.stringify(data.backlogs);
          data.tenth_passing_year = JSON.stringify(data.tenth_passing_year);
          data.twelve_passing_year = JSON.stringify(data.twelve_passing_year);
          data.tenth_percentage = JSON.stringify(data.tenth_percentage);
          data.twelve_percentage = JSON.stringify(data.twelve_percentage);
          data.ug_per = JSON.stringify(data.ug_per);

          if (
            !data.current_year ||
            !data.backlogs ||
            !data.gender ||
            !data.tenth_board ||
            !data.tenth_institute_name ||
            !data.tenth_passing_year ||
            !data.tenth_percentage ||
            !data.twelve_board ||
            !data.twelve_institute_name ||
            !data.twelve_passing_year ||
            !data.twelve_percentage ||
            !data.ug_per
          ) {
            toast.warn("Kindly complete your academics information!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else if (res.status === 422) {
        }
      });
  };
  const handleFileInput = (e) => {
    formik.values.profile_pic = e.target.files[0];
    formik.handleSubmit();
  };

  const profilePicStyle = {
    backgroundImage: `url(${user.profile_pic})`,
    backgroundSize: "cover",
  };

  useEffect(() => {
    getUser();
  }, []);

  const API_URL = `/student/${id}/${inst_id}/student-profile`;

  return (
    <>
      <ToastContainer />
      <section>
        <NavbarComponent data={user} role={user_role} />
      </section>
      {/* {warn ? 
                <Alert action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setWarn(false);
                            }}
                            >
                                <CloseIcon style={{color:"#0E5E6F"}} fontSize="inherit" />
                            </IconButton>
                        } 
                        className="academic-warn"
                        severity="warning">Kindly complete your academics information!!</Alert>   
                        : ''} */}
      <Row className="profile-img-section">
        <Col md={{ span: 2, offset: 2 }} style={{ marginTop: "100px" }}>
          <form
            onSubmit={formik.handleSubmit}
            className="user-profile-add-image"
          >
            <Button
              sx={{
                bgcolor: "#f4feddf5",
                margin: "auto",
                marginBottom: "20px",
                textAlign: "center",
                width: "150px",
                height: "150px",
                borderRadius: "50%",
              }}
              className="attach-file-btn"
              style={profilePicStyle}
              onClick={() => {
                console.log(inputRef);
                inputRef.current.click();
              }}
            >
              <input
                ref={inputRef}
                type="file"
                id="certificate"
                name="profile_pic"
                onChange={handleFileInput}
                hidden
              />
              {user.profile_pic === null || user.profile_pic === undefined ? (
                <AddPhotoAlternateIcon
                  sx={{ color: "#0E5E6F", fontSize: "50px", margin: "auto" }}
                />
              ) : (
                ""
              )}
            </Button>
            {formik.touched.profile_pic && formik.errors.profile_pic ? (
              <div>
                <Typography
                  sx={{
                    textAlign: "left",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                    color: "white",
                  }}
                >
                  {formik.errors.profile_pic}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </form>
        </Col>
        <Col className="profile-name">
          <span>{user.s_name}</span>
          <span style={{ fontSize: "14px" }}>{user.s_roll_no}</span>
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 2, offset: 1 }} className="profile-list">
          <List sx={{ bgcolor: "background.paper" }}>
            <Link to={API_URL + "/basic-info"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <InfoIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Basic Info" />
              </ListItem>
            </Link>
            <Link to={API_URL + "/education"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <CastForEducationIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Academics" />
              </ListItem>
            </Link>
            <Link to={API_URL + "/account"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <ManageAccountsIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Account" />
              </ListItem>
            </Link>
            <Link to={API_URL + "/skills"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <TipsAndUpdatesIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Tech Skills" />
              </ListItem>
            </Link>
            <Link to={API_URL + "/internship"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <WorkIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Internships & Experience" />
              </ListItem>
            </Link>
            <Link to={API_URL + "/certification"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <WorkspacePremiumIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Certifications" />
              </ListItem>
            </Link>
            <Link to={API_URL + "/project"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <AccountTreeIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Projects" />
              </ListItem>
            </Link>
            <Link
              to={API_URL + "/extra-curricular-activity"}
              activeclassname="active"
            >
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <LocalActivityIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Extracurricular activities" />
              </ListItem>
            </Link>
          </List>
        </Col>
        <Col xs={{ span: 7, offset: 1 }}>
          <props.Children student={user} />
        </Col>
      </Row>
      <Outlet />
    </>
  );
};

export default StudentProfileComponent;
