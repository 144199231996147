import React, { useState, useEffect } from "react";
import "./TechSkillReportComponent.css";
import SkillBar from "react-skillbars";
import { Paper } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SERVER_URL } from "../../../apiRoutes";

const colors = {
  bar: "#3cf9e0",
  title: {
    text: "#fff",
    background: "#034056",
    width: "200px",
  },
};

const TechSkillReportComponent = () => {
  const { id, inst_id } = useParams("");
  const [skills, setSkills] = useState([]);

  const getSkills = async () => {
    axios
      .get(`${SERVER_URL}/student/${id}/${inst_id}/student-tech-report`)
      .then(async (res) => {
        if (res.status === 422 || !res.data) {
          // console.log("Error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          const data = await res.data;
          setSkills(data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  // console.log(skills);

  useEffect(() => {
    getSkills();
  }, []);
  return (
    <>
      <ToastContainer />
      {/* <Container > */}
      <Paper elevation={3} sx={{ marginTop: "50px", padding: " 50px 100px" }}>
        <SkillBar skills={skills} colors={colors} height={10} />
      </Paper>
      {/* </Container> */}
    </>
  );
};

export default TechSkillReportComponent;
