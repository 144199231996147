import React, { useState, useEffect } from "react";
import "../tpo/driveHistory/DriveHistory.css";
import { useParams, useLocation } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import { GET_TPO_DATA, SERVER_URL } from "../../apiRoutes";
import { ToastContainer, toast } from "react-toastify";

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const DriveResult = () => {
  const location = useLocation();
  const { inst_id, drive_id } = useParams("");
  const [applicants, setApplicants] = useState([]);

  const { comp_name } = location.state;

  const getResult = () => {
    axios
      .get(`${SERVER_URL}/${GET_TPO_DATA}/${inst_id}/get-drive-result-details/${drive_id}`)
      .then((res) => {
        if (res.status === 202) {
          // console.log(res.data);
          setApplicants(res.data);
        } else if (res.status === 422) {
          toast.error("OOPs! Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "s_roll_no",
      headerName: "Enrollment No.",
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "s_name",
      headerName: "Name",
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "s_email",
      headerName: "Email",
      flex: 3,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "profile",
      headerName: "Profile Designation",
      flex: 3,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "branch",
      headerName: "Branch",
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "s_mobile",
      headerName: "Contact No.",
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
  ];
  useEffect(() => {
    getResult();
  }, []);

  return (
    <>
      <ToastContainer />
      <div
        style={{
          height: "70vh",
          width: "80%",
          marginTop: "50px",
          marginLeft: "10%",
        }}
      >
        <p className="profile-heading">{comp_name}</p>
        <StripedDataGrid
          className="drive-history-table"
          rows={applicants}
          pageSize={10}
          columns={columns}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </>
  );
};

export default DriveResult;
