import React, { useState, useEffect, useRef } from "react";
import S3 from "react-aws-s3";
import { Paper, styled, List, ListItem, ListItemAvatar, ListItemText, Avatar, Table, TableBody, TableCell, TableContainer, TableRow, Button, Typography} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Row, Col, Form } from "react-bootstrap";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NavbarComponent from "../../../modules/common/navbar/NavbarComponent";
import { tableCellClasses } from "@mui/material/TableCell";
import { Link, Outlet, useParams } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  UPDATE_TPO_EMAIL,
  UPDATE_TPO_MOBILE,
  UPDATE_TPO_NAME,
  SERVER_URL,
  UPDATE_TPO_PASSWORD
} from "../../../apiRoutes";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const BasicTpoInfoDetails = (props) => {
  const { tpo } = props;
  const {id, inst_id} = useParams();
  const [inputValue, setInputValue] = useState({
    tpo_name: "",
    tpo_email: "",
    tpo_mobile: "",
  });

  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editGender, setEditGender] = useState(false);
  const [editMobile, setEditMobile] = useState(false);

  const enableEdit = (e) => {
    if (e.target.name === "editName") {
      setEditName(!editName);
    }
    if (e.target.name === "editEmail") {
      setEditEmail(!editEmail);
    }
    if (e.target.name === "editGender") {
      setEditGender(!editGender);
    }
    if (e.target.name === "editMobile") {
      setEditMobile(!editMobile);
    }
  };
  const handleEdit = (e) => {
    setInputValue((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const updateName = async (e) => {
    e.preventDefault();
    const { tpo_name } = inputValue;
    
    axios.put(`${SERVER_URL}/tpo/${id}/${inst_id}/tpo-profile/${UPDATE_TPO_NAME}`, { tpo_name })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          setEditName(!editEmail);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateEmail = async (e) => {
    e.preventDefault();
    const { tpo_email } = inputValue;
    axios
      .put(`${SERVER_URL}/tpo/${id}/${inst_id}/tpo-profile/${UPDATE_TPO_EMAIL}`, { tpo_email })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          // alert(res.data);
          setEditEmail(!editEmail);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const updateMobile = async (e) => {
    e.preventDefault();
    const { tpo_mobile } = inputValue;
    axios
      .put(`${SERVER_URL}/tpo/${id}/${inst_id}/tpo-profile/${UPDATE_TPO_MOBILE}`, { tpo_mobile })
      .then((res) => {
        if (res.status === 422 || !res.data) {
          // alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          // alert(res.data);
          setEditMobile(!editMobile);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  // console.log(inputValue);

  return (
    <>
      <ToastContainer />
      <Paper
        className="basic-details"
        elevation={3}
        id="basic-info"
        sx={{ minHeight: "400px" }}
      >
        <p>Basic Details</p>
        <TableContainer
          sx={{ padding: "20px 20px 50px 20px", minHeight: "400px" }}
        >
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="Name">
                <StyledTableCell component="th" scope="row">
                  Name
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editName ? (
                    <>
                      <Form.Control
                        type="text"
                        className="mt-3"
                        placeholder={tpo.tpo_name}
                        name="tpo_name"
                        onChange={handleEdit}
                      />
                    </>
                  ) : (
                    <>{tpo.tpo_name}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editName ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateName}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editName"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editName"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="email">
                <StyledTableCell component="th" scope="row">
                  Email
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editEmail ? (
                    <Form.Control
                      type="email"
                      className="mt-3"
                      name="tpo_email"
                      onChange={handleEdit}
                      placeholder={tpo.tpo_email}
                    />
                  ) : (
                    <>{tpo.tpo_email}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editEmail ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateEmail}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editEmail"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      disabled
                      name="editEmail"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="mobile">
                <StyledTableCell component="th" scope="row">
                  Mobile No.
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editMobile ? (
                    <Form.Control
                      type="telephone"
                      className="mt-3"
                      onChange={handleEdit}
                      name="tpo_mobile"
                      placeholder={tpo.tpo_mobile}
                    />
                  ) : (
                    <>{tpo.tpo_mobile}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {editMobile ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateMobile}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editMobile"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editMobile"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export const TpoAccountDetails = (props) => {
  const { tpo } = props;
  const {id, inst_id} = useParams();
  const [inputValue, setInputValue] = useState({
    s_name: "",
    user_password: "",
  });

  const [editUsername, setEditUsername] = useState(false);

  const enableEdit = (e) => {
    if (e.target.name === "editUsername") {
      setEditUsername(!editUsername);
    }
  };
  const updateUsername = async (e) => {
    e.preventDefault();
    const { tpo_name } = inputValue;
    axios.put(`${SERVER_URL}/tpo/${id}/${inst_id}/tpo-profile/${UPDATE_TPO_NAME}`, { tpo_name }).then((res) => {
      if (res.status === 422 || !res.data) {
        //   alert("error");
        toast.error(res.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        //   alert(res.data);
        setEditUsername(!editUsername);
        window.location.reload(false);
      }
    });
  };
  const updatePassword = async (e) => {
    e.preventDefault();
    const email = tpo.tpo_email;
    axios
      .post(`${SERVER_URL}/tpo/${id}/${inst_id}/tpo-profile/${UPDATE_TPO_PASSWORD}`, { email })
      .then((res) => {
        console.log(res.data);
        if (res.status === 422 || !res.data) {
          //   alert("error");
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleEdit = (e) => {
    setInputValue((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  return (
    <>
      <ToastContainer />
      <Paper className="account-details" elevation={3}>
        <p>Account Info</p>
        <TableContainer sx={{ padding: "20px" }}>
          <Table sx={{ maxWidth: "100%" }} aria-label="customized table">
            <TableBody>
              <StyledTableRow key="tpo_name">
                <StyledTableCell component="th" scope="row">
                  Username
                </StyledTableCell>
                <StyledTableCell align="left">
                  {editUsername ? (
                    <Form.Control
                      type="text"
                      className="mt-3"
                      value={inputValue.tpo_name}
                      placeholder={tpo.tpo_name}
                      name="tpo_name"
                      onChange={handleEdit}
                    />
                  ) : (
                    <>{tpo.tpo_name}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {/* <Button sx={{color: '#0467a6'}} name="editUsername" onClick={enableEdit} >Edit</Button>  */}
                  {editUsername ? (
                    <div>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        onClick={updateUsername}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ margin: "10px", marginBottom: "0px" }}
                        color="secondary"
                        name="editUsername"
                        onClick={enableEdit}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      sx={{ color: "#0467a6" }}
                      name="editUsername"
                      onClick={enableEdit}
                    >
                      Edit
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key="password">
                <StyledTableCell component="th" scope="row">
                  Password
                </StyledTableCell>
                <StyledTableCell align="left">********</StyledTableCell>
                <StyledTableCell align="right">
                  <Button
                    sx={{ color: "#0467a6" }}
                    name="editPassword"
                    onClick={updatePassword}
                  >
                    Change
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

const TpoProfileComponent = (props) => {
  const user_role = "tpo";
  const inputRef = useRef();

  // const[open, setOpen] = useState(false);

  // const renderComponent = () => {
  //     setOpen(true);
  // }
  const [user, setUser] = useState([]);
  const { id, inst_id } = useParams("");
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: "tpo/profile",
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];
  const formik = useFormik({
    initialValues: {
      profile_pic: "",
    },
    onSubmit: (values) => {
      const file = values.profile_pic;
      // console.log(file);
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, `${Date.now()}.${file.name}`).then(
        (data) => {
          // console.log(data.location);
          if (!data.location.length) {
            toast.error("OOPs! something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
          const imgUrl = data.location;
          axios
            .put(`${SERVER_URL}/tpo/${id}/${inst_id}/tpo-profile/update-tpo-profile-pic`, { imgUrl })
            .then((res) => {
              if (res.status === 202) {
                toast.success(res.data, {
                  position: toast.POSITION.TOP_CENTER,
                });
                getUser();
              } else if (res.status === 422) {
                toast.error(res.data, {
                  position: toast.POSITION.TOP_CENTER,
                });
              }
            })
            .catch((err) => {
              // console.log(err);
              toast.error(err.response.data, {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        }
      );
    },
    validationSchema: Yup.object({
      profile_pic: Yup.mixed()
        .test(
          "fileSize",
          "File Size is too large",
          (value) => value.size >= 20000
        )
        .test("fileType", "Unsupported File Format", (value) =>
          SUPPORTED_FORMATS.includes(value.type)
        ),
    }),
  });

  const getUser = async () => {
    axios.get(`${SERVER_URL}/tpo/${id}/${inst_id}/tpo-profile-data`).then(async (res) => {
      const data = await res.data;
      setUser(data);
    });
  };
  console.log(user);

  const handleFileInput = (e) => {
    formik.values.profile_pic = e.target.files[0];
    formik.handleSubmit();
  };

  const profilePicStyle = {
    backgroundImage: `url(${user.profile_pic})`,
    backgroundSize: "cover",
  };

  useEffect(() => {
    getUser();
  }, []);
  const API_URL = `/tpo/${id}/${inst_id}/tpo-profile`;

  return (
    <>
      <ToastContainer />
      <section>
        <NavbarComponent data={user} role={user_role} />
      </section>
      <Row className="profile-img-section">
        {/* <Col md={{span:2, offset:2}} className="profile-img" > */}
        <Col md={{ span: 2, offset: 2 }} style={{ marginTop: "100px" }}>
          <form
            onSubmit={formik.handleSubmit}
            className="user-profile-add-image"
          >
            <Button
              sx={{
                bgcolor: "#f4feddf5",
                margin: "auto",
                marginBottom: "20px",
                textAlign: "center",
                width: "150px",
                height: "150px",
                borderRadius: "50%",
              }}
              className="attach-file-btn"
              style={profilePicStyle}
              onClick={() => {
                console.log(inputRef);
                inputRef.current.click();
              }}
            >
              <input
                ref={inputRef}
                type="file"
                id="certificate"
                name="profile_pic"
                onChange={handleFileInput}
                hidden
              />
              {user.profile_pic === null || user.profile_pic === undefined ? (
                <AddPhotoAlternateIcon
                  sx={{ color: "#0E5E6F", fontSize: "50px", margin: "auto" }}
                />
              ) : (
                ""
              )}
            </Button>
            {formik.touched.profile_pic && formik.errors.profile_pic ? (
              <div>
                <Typography
                  sx={{
                    textAlign: "left",
                    marginBottom: "10px",
                    marginTop: "-10px",
                    fontSize: "12px",
                    color: "white",
                  }}
                >
                  {formik.errors.profile_pic}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </form>
        </Col>

        {/* </Col> */}
        <Col className="profile-name">
          <span>{user.tpo_name}</span>
          {/* <span style={{fontSize : "14px"}} ></span> */}
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 2, offset: 1 }} className="profile-list">
          <List sx={{ bgcolor: "background.paper" }}>
            <Link to={API_URL + "/basic-info"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <InfoIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Basic Info" />
              </ListItem>
            </Link>
            <Link to={API_URL + "/account"} activeclassname="active">
              <ListItem className="profile-list-item">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#4b425d" }}>
                    <ManageAccountsIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Account" />
              </ListItem>
            </Link>
          </List>
        </Col>
        <Col xs={{ span: 7, offset: 1 }}>
          <props.Children tpo={user} />
        </Col>
      </Row>
      <Outlet />
    </>
  );
};

export default TpoProfileComponent;
