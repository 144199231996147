import React from "react";
import NavbarComponent from "./navbar/NavbarComponent";
import {
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import './style.css';

const Support = () => {
  let data = [
    {
      que: "Facing isues while signin",
      ans: "If you are facing an error while logging into Superset, please ensure that the email ID and password that you have entered is correct. If you do not remember your password, then please use the Forgot Password option. If you are still unable to login to Remote Placement Cell, then please mail us your query.",
    },
    {
      que: "Issues while updating personal, academic information, etc.",
      ans: `Visit your personal profile. Then there is an academics section where you can update your academic details. Similarly, you can update your information relevant to those sections. Further if you encounter any issues while updating your personal, academic information, or any other relevant details, please mail us for immediate assistance.`,
    },
    {
      que: "How to apply for a placement drive?",
      ans: "Navigate to the eligibility messages page. All drives for which you are eligible are listed here. To apply for a drive, simply click the apply button and choose one of the available designations and confirm.",
    },
    {
      que: "How to change password?",
      ans: "Click on forgot password while signing in. Then you have to provide your registered email id, you will receive an email with a link to change your password.",
    },
    {
      que: "How to upload student data?",
      ans: 'Download the sample CSV file from your dashboard, add the respective student data, and upload it in the "Upload Student Data" section.',
    },
  ];

  return (
    <>
      <NavbarComponent />
      <div
        style={{ height: "50vh", width: "100vw", backgroundColor: "#6eeff04f" }}
      >
        <span className="support-heading" >
          How can we help?
        </span>
      </div>
      <Paper
        elevation={3}
        style={{
          
        }}
        className="support-paper"
      >
        <span className="support-trouble">
          Having trouble with ...
        </span>

        {data.map((item) => (
          <Accordion
            className="support-accordian"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color="#505867">{item.que}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="cadetblue">{item.ans}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
        <p
          style={{
            color: "#0E5E6F",
            fontSize: "16px",
            fontWeight: "600",
            letterSpacing: "0.5px",
            textAlign: "center",
            marginTop: "50px",
          }}
        >
          Still if you encounter any issues, please Email us at{" "}
          <a href="mailto: remote.placement.cell@gmail.com">
            remote.placement.cell
          </a>{" "}
          for immediate assistance .
        </p>
      </Paper>
    </>
  );
};

export default Support;
