import React from "react";
import {
  SaveProjectsData,
  ModifyProjectsCount,
} from "../../../actions/actions";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, Divider, Grid, TextField } from "@material-ui/core";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "60px",
    width: "90%",
    margin: "auto",
    minHeight: "490px",
  },
  deleteButton: {
    marginRight: "1%",
  },
  addButton: {
    backgroundColor: "#7ff9ee",
    color: "#044f6f",
  },
  footer: {
    padding: "1%",
  },
  heading: {
    textAlign: "center",
    marginBottom: "50px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#044f6f",
  },
  instance: {
    marginBottom: "1%",
    padding: "1%",
  },
}));

const ProjectForm = (props) => {
  const classes = useStyles();

  const handleChange = (index) => (event) => {
    const { name, value } = event.target;
    const list = [...props.projectFormData.Data];
    console.log(index + 1, list.length);
    if (index + 1 > list.length) {
      while (index + 1 !== list.length) {
        list.push({ projectName: null, techStack: null, description: null });
      }
    }
    list[index][name] = value;
    props.SaveProjectsData(list);
  };

  const Deleteducation = () => {
    const list = [...props.projectFormData.Data];
    list.pop();
    props.SaveProjectsData(list);
    props.ModifyProjectsCount(props.projectFormData.Count - 1);
  };

  const AddEducation = () => {
    const list = [...props.projectFormData.Data];
    list.push({ projectName: null, techStack: null, description: null });
    props.SaveProjectsData(list);
    props.ModifyProjectsCount(props.projectFormData.Count + 1);
  };

  let Form = [];
  for (let i = 0; i < props.projectFormData.Count; i++) {
    Form.push(
      <div className={classes.instance}>
        <Grid container spacing={3} className="resume">
          <Grid item md={4} xs={12}>
            <TextField
              required
              fullWidth
              name={`projectName`}
              defaultValue={
                props.projectFormData.Data && props.projectFormData.Data[i]
                  ? props.projectFormData.Data[i].projectName
                  : ""
              }
              label="Project Name"
              onChange={handleChange(i)}
              variant="outlined"
              formControlProps={{
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              name={`techStack`}
              defaultValue={
                props.projectFormData.Data && props.projectFormData.Data[i]
                  ? props.projectFormData.Data[i].techStack
                  : ""
              }
              label="Tech Stack"
              onChange={handleChange(i)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              defaultValue={
                props.projectFormData.Data && props.projectFormData.Data[i]
                  ? props.projectFormData.Data[i].description
                  : ""
              }
              name={`description`}
              label="Description"
              onChange={handleChange(i)}
              multiline={2}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Divider />
      </div>
    );
  }

  return (
    <>
      <form autoComplete="off" noValidate>
        <Card className={classes.root}>
          <p className={classes.heading}>Add your Projects</p>
          <form>{Form.map((instance) => instance)}</form>
          <div className={classes.footer}>
            <Button
              disabled={props.projectFormData.Count < 2}
              className={classes.deleteButton}
              onClick={Deleteducation}
              variant="outlined"
              color="secondary"
            >
              Delete
            </Button>
            <Button
              className={classes.addButton}
              onClick={AddEducation}
              variant="contained"
            >
              ADD Project
            </Button>
          </div>
        </Card>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  projectFormData: state.Project,
});

export default connect(mapStateToProps, {
  SaveProjectsData,
  ModifyProjectsCount,
})(ProjectForm);
