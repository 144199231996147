import React from "react";
import "react-vertical-timeline-component/style.min.css";
import "./HomeComponent.css";
import Carousel from "react-bootstrap/Carousel";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import BackupIcon from "@mui/icons-material/Backup";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import RegisterComponent from "../../modules/authentication/register/RegisterComponent";
import NavbarComponent from "../navbar/NavbarComponent";
import FooterComponent from "../footer/FooterComponent";
import { Paper } from "@mui/material";

const HomeComponent = () => {
  return (
    <>
      <header className="navbar">
        <NavbarComponent />
      </header>
      <section className="carousel-section">
        <Carousel
          className=""
          style={{
            margin: "3%",
            borderRadius: "10px",
            alignContent: "center",
          }}
        >
          <Carousel.Item interval={2000} style={{ borderRadius: "15px" }}>
            <img
              className="d-block w-100 carousel-img"
              src={require("../../assets/home-carosel2.png")}
              alt="First slide"
            />
            <Carousel.Caption>
              <p>Automate all the placement drives.</p>
              <p>Reduce manual work for both TPC and students.</p>
              <p>
                Provide insights on technology demand based on an analysis with
                appropriate accuracy.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100 carousel-img"
              src={require("../../assets/home-carosel2.png")}
              alt="Second slide"
            />
            <Carousel.Caption>
              <p>
                Provide better eligibility information through user profiles,
                SMS, and email.
              </p>
              <p>Maintain a record of student performance.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100 carousel-img"
              src={require("../../assets/home-carosel2.png")}
              alt="Third slide"
            />
            <Carousel.Caption>
              <p>
                Develop a college-specific interface for an auto-resume
                generator.
              </p>
              <p>Organize and access student data conveniently</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        {/* </Col>
                        <Col xs={4}>
                            <img src={require('../../assets/RPC_gif.gif')} style={{width:'100%', marginLeft:'25%', marginTop:'-100px'}} alt="mobile_view" />
                        </Col>
                    </Row>
                </Container> */}
      </section>

      <section className="vertical-goals">
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date=""
            iconStyle={{ background: "transparent" }}
          >
            <Paper elevation={3} sx={{ padding: "20px" }}>
              <ul>
                <li style={{ "--accent-color": "#41516C" }}>
                  <div className="date">
                    {" "}
                    <span>1</span>{" "}
                  </div>
                  <div className="title">
                    <AssessmentIcon
                      sx={{ fontSize: "50px", color: "#41516C" }}
                    />
                  </div>
                  <div className="descr">
                    Automate all the placement drives.
                  </div>
                </li>
              </ul>
            </Paper>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date=""
            iconStyle={{ background: "transparent" }}
          >
            <Paper elevation={3} sx={{ padding: "20px" }}>
              <ul>
                <li style={{ "--accent-color": "#FBCA3E" }}>
                  <div className="date">
                    {" "}
                    <span>2</span>{" "}
                  </div>
                  <div className="title">
                    <BackupIcon sx={{ fontSize: "50px", color: "#FBCA3E" }} />
                  </div>
                  <div className="descr">
                    Organize and access student data conveniently
                  </div>
                </li>
              </ul>
            </Paper>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date=""
            iconStyle={{ background: "transparent" }}
          >
            <Paper elevation={3} sx={{ padding: "20px" }}>
              <ul>
                <li style={{ "--accent-color": "#E24A68" }}>
                  <div className="date">
                    <span>3</span>
                  </div>
                  <div className="title">
                    {" "}
                    <NotificationsActiveIcon
                      sx={{ fontSize: "50px", color: "#E24A68" }}
                    />{" "}
                  </div>
                  <div className="descr">
                    Provide better eligibility information through user
                    profiles, SMS, and email.
                  </div>
                </li>
              </ul>
            </Paper>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date=""
            iconStyle={{ background: "transparent" }}
          >
            <Paper elevation={3} sx={{ padding: "20px" }}>
              <ul>
                <li style={{ "--accent-color": "#1B5F8C" }}>
                  <div className="date">
                    <span>4</span>
                  </div>
                  <div className="title">
                    {" "}
                    <ContactPageIcon
                      sx={{ fontSize: "50px", color: "#1B5F8C" }}
                    />{" "}
                  </div>
                  <div className="descr">
                    Develop a college-specific interface for an auto-resume
                    generator.
                  </div>
                </li>
              </ul>
            </Paper>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date=""
            iconStyle={{ background: "transparent" }}
          >
            <Paper elevation={3} sx={{ padding: "20px" }}>
              <ul>
                <li style={{ "--accent-color": "#4CADAD" }}>
                  <div className="date">
                    <span>5</span>
                  </div>
                  <div className="title">
                    {" "}
                    <TimelapseIcon
                      sx={{ fontSize: "50px", color: "#4CADAD" }}
                    />{" "}
                  </div>
                  <div className="descr">
                    Reduce manual work for both TPC and students.
                  </div>
                </li>
              </ul>
            </Paper>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date=""
            iconStyle={{ background: "transparent" }}
          >
            <Paper elevation={3} sx={{ padding: "20px" }}>
              <ul>
                <li style={{ "--accent-color": "#41516C" }}>
                  <div className="date">
                    <span>6</span>
                  </div>
                  <div className="title">
                    {" "}
                    <TipsAndUpdatesIcon
                      sx={{ fontSize: "50px", color: "#41516C" }}
                    />{" "}
                  </div>
                  <div className="descr">
                    Provide insights on technology demand based on an analysis
                    with appropriate accuracy.
                  </div>
                </li>
              </ul>
            </Paper>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </section>

      {/* style={{backgroundImage :`url(${formBackground})`}} */}
      <section className="registerComponent" id="register">
        <span style={{display:'block'}}>
          <i> Let's grow together </i>
        </span>
        <RegisterComponent />
      </section>

      <section className="footerComponent">
        <FooterComponent />
      </section>
    </>
  );
};

export default HomeComponent;
