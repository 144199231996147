import React, { useState, useEffect } from "react";
import "./adminStyle.css";
import { Paper, Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  GET_ALL_USERS,
  DELETE_STUDENTS,
  DELETE_TPOS,
  DELETE_INST,
  SERVER_URL
} from "../../apiRoutes";

const ManageUsers = () => {
  const [institution, setInstitution] = useState([]);
  const [tpo, setTpo] = useState([]);
  const [student, setStudent] = useState([]);
  // const [checkboxSelection, setCheckboxSelection] = useState(true);
  const checkboxSelection = true;
  const [checkedStuId, setCheckedStuId] = useState([]);
  const [checkedTpoId, setCheckedTpoId] = useState([]);
  const [checkedInstId, setCheckedInstId] = useState([]);

  const getUsers = () => {
    axios
      .get(`${SERVER_URL}/${GET_ALL_USERS}`)
      .then((res) => {
        if (res.status === 422 || !res.data) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          // console.log(res.data[2].institutions);
          setStudent(res.data[0].students);
          setTpo(res.data[1].tpos);
          setInstitution(res.data[2].institutions);
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const deleteStudents = () => {
    axios
      .put(`${SERVER_URL}/${DELETE_STUDENTS}`, checkedStuId)
      .then((res) => {
        if (res.status === 422 || !res.data) {
          toast.error("OOPs! Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
          getUsers();
        }
      })
      .catch((err) => {
        toast.error("OOPs! Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const deleteTpos = () => {
    axios
      .put(`${SERVER_URL}/${DELETE_TPOS}`, checkedTpoId)
      .then((res) => {
        if (res.status === 422 || !res.data) {
          toast.error("OOPs! Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const deleteInstitutions = () => {
    axios
      .put(`${SERVER_URL}/${DELETE_INST}`, checkedInstId)
      .then((res) => {
        if (res.status === 422 || !res.data) {
          toast.error("OOPs! Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  // console.log(checkedStuId);

  useEffect(() => {
    getUsers();
  }, []);

  const student_columns = [
    { field: "id", headerName: "ID", hide: true },
    { field: "s_name", headerName: "Name", flex: 2 },
    { field: "s_email", headerName: "Email", flex: 2 },
    { field: "s_mobile", headerName: "Contact no.", flex: 1 },
  ];
  const tpo_columns = [
    { field: "id", headerName: "ID", hide: true },
    { field: "tpo_name", headerName: "Name", flex: 2 },
    { field: "s_email", headerName: "Email", flex: 2 },
    { field: "s_mobile", headerName: "Contact no.", flex: 1 },
  ];
  const inst_columns = [
    { field: "id", headerName: "ID", hide: true },
    { field: "inst_name", headerName: "Name", flex: 2 },
    { field: "inst_email", headerName: "Email", flex: 2 },
    { field: "inst_mobile", headerName: "Contact no.", flex: 1 },
  ];
  return (
    <>
      {/* {
                error ? <ConfirmationComponent data={resMsg} children={resIcon} /> : "" 
            } */}
      <ToastContainer />
      <Paper elevation={3} className="outer-paper">
        <Paper elevation={3} className="inner-paper">
          <div
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <p className="user-role-heading">Institution</p>
            <Button
              onClick={deleteInstitutions}
              variant="contained"
              color="secondary"
              className="users-delete-btn"
            >
              Delete
            </Button>
          </div>
          <div style={{ height: 400 }}>
            <DataGrid
              checkboxSelection={checkboxSelection}
              rows={institution}
              pageSize={10}
              columns={inst_columns}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRowData = institution.filter((row) => {
                  selectedIDs.has(row.id.toString());
                  return setCheckedInstId(ids);
                });
                console.log(selectedRowData);
              }}
            />
          </div>
        </Paper>
        <Paper elevation={3} className="inner-paper">
          <div
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <p className="user-role-heading">Students</p>
            <Button
              onClick={deleteStudents}
              variant="contained"
              color="secondary"
              className="users-delete-btn"
            >
              Delete
            </Button>
          </div>
          <div style={{ height: 400 }}>
            <DataGrid
              checkboxSelection={checkboxSelection}
              rows={student}
              pageSize={10}
              columns={student_columns}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRowData = student.filter((row) => {
                  selectedIDs.has(row.id.toString());
                  return setCheckedStuId(ids);
                });
                console.log(selectedRowData);
              }}
            />
          </div>
        </Paper>
        <Paper elevation={3} className="inner-paper">
          <div
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <p className="user-role-heading">Training & Placement Officer</p>
            <Button
              onClick={deleteTpos}
              variant="contained"
              color="secondary"
              className="users-delete-btn"
            >
              Delete
            </Button>
          </div>
          <div style={{ height: 400 }}>
            <DataGrid
              checkboxSelection={checkboxSelection}
              rows={tpo}
              pageSize={10}
              columns={tpo_columns}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRowData = tpo.filter((row) => {
                  selectedIDs.has(row.id.toString());
                  return setCheckedTpoId(ids);
                });
                console.log(selectedRowData);
              }}
            />
          </div>
        </Paper>
      </Paper>
    </>
  );
};

export default ManageUsers;
