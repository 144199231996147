import React, { useState, useEffect } from "react";
import NavbarComponent from "../../../modules/common/navbar/NavbarComponent";
import SideNavComponent from "../../common/sideNav/SideNavComponent";
// import NewPlacementDriveComponent from "../newPlacementDrive/NewPlacementDriveComponent";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_TPO_DATA, SERVER_URL } from "../../../apiRoutes";

const TpoDashboardComponent = (props) => {
  const { id, inst_id } = useParams("");
  const [user, setUser] = useState([]);
  const user_role = "tpo";

  const getUser = async () => {
    axios
      .get(`${SERVER_URL}/${GET_TPO_DATA}/${id}/${inst_id}`)
      .then(async (res) => {
        if (res.status === 422 || !res.data) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const data = await res.data;
          setUser(data);
        }
      })
      .catch((err) => {
        toast.error("OOPs! something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  // console.log(user);
  useEffect(() => {
    getUser();
  }, []);
  const { Children } = props;

  return (
    <>
      <ToastContainer />
      <NavbarComponent data={user} role={user_role} />

      <SideNavComponent data={user} role={user_role} children={Children} />
    </>
  );
};

export default TpoDashboardComponent;
