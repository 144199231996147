import React, { useState } from "react";
import "./ResetPasswordComponent.css";
import {
  IconButton,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Paper,
  Button,
  FormControl,
  Card,
  CardContent,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RESET_PASSWORD, SERVER_URL } from "../../../apiRoutes";

const ResetPasswordComponent = () => {
  const [inputValue, setInputValue] = useState(
    {
      user_password: "",
      showPassword: false,
    },
    []
  );

  const setData = (e) => {
    setInputValue((pre) => {
      return {
        ...pre,
        [e.target.name]: e.target.value,
      };
    });
  };

  const { resetToken, email } = useParams("");

  const sendPassword = async (e) => {
    e.preventDefault();

    const { user_password } = inputValue;

    // console.log(user_password);
    await axios
      .post(`${SERVER_URL}/${RESET_PASSWORD}/${resetToken}/${email}`, {
        user_password,
      })
      .then((res) => {
        if (!res.data || res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 202) {
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleClickShowPassword = () => {
    setInputValue({
      ...inputValue,
      showPassword: !inputValue.showPassword,
    });
    // showPassword : !showPassword;
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <ToastContainer />

      <Paper elevation={3} className="reset-pass-form">
        <Card>
          <CardContent className="reset-pass-card">
            <p className="head">Enter new Password</p>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlinedpassword">Password</InputLabel>
              <OutlinedInput
                id="outlinedpassword"
                type={inputValue.showPassword ? "text" : "password"}
                value={inputValue.user_password}
                onChange={setData}
                name="user_password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {inputValue.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              <Button
                variant="outlined"
                onClick={sendPassword}
                className="sendBtn"
              >
                Send
              </Button>
            </FormControl>
          </CardContent>
        </Card>
      </Paper>
    </>
  );
};

export default ResetPasswordComponent;
