import React, { useState, useEffect} from "react";
import "./NewPlacementDriveComponent.css";
import {Box, Paper, Stepper, Step, StepButton, Button, Checkbox, OutlinedInput, ListItemText, InputLabel, MenuItem, FormControl, Select, styled, StepLabel, TextField, FormGroup, FormControlLabel, Tooltip, tooltipClasses,
} from "@mui/material";
import PropTypes from "prop-types";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Row, Col } from "react-bootstrap";
import Step1Img from "../../../assets/newDriveStep1.svg";
import Step2Img from "../../../assets/newDriveStep2.svg";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { withStyles } from "@material-ui/core/styles";
// import StepContent from '@material-ui/core/StepContent';
import StepContent from "@mui/material/StepContent";
import Add from "@material-ui/icons/AddCircle";
import Delete from "@material-ui/icons/Delete";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios from "axios";
import { Outlet, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CREATE_PLACEMENT_DRIVE,
  GET_AVAILABLE_BATCHES,
  GET_AVAILABLE_BRANCHES,
  GET_AVAILABLE_COURSES,
  GET_AVAILABLE_REQ_SKILLS,
  SERVER_URL
} from "../../../apiRoutes";

export const isSet = (value) => {
  return value !== undefined && value !== null;
};

export const stepOptions = [{ title: "", content: "" }];

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

const QontoStepIcon = (props) => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
};

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const driveSteps = ["Step 1", "step 2", "Step 3"];

const genderOptions = ["Male", "Female"];

const NewPlacementDriveComponent = (props) => {
  const navigate = useNavigate();
  const {id, inst_id} = useParams();
  const [policy, setPolicy] = useState(true);
  const [batchOptions, setBatchOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [techReqOptions, setTechReqOptions] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [initialStep, setNextStep] = useState({
    title: "",
    content: "",
  });
  const [profileDesg, setProfileDesg] = useState([
    {
      profile: "",
      package: 0,
      tech_req: [],
    },
  ]);
  const [inputValue, setInputValue] = useState({
    company_name: "",
    batch: [],
    course: "",
    branch: [],
    tenth_per: 0,
    twelve_per: 0,
    ug_per: 0,
    gender: [],
    backlogs: 0,
    profile_designation: [],
    steps: [
      {
        title: "",
      },
    ],
    hiringFlowActiveStep: 0,
  });
  const handleChange = (e) => {
    // console.log(e);
    const {
      target: { value },
    } = e;
    // console.log(value);
    const val = typeof value === "string" ? value.split(",") : value;
    setInputValue((prev) => {
      // On autofill we get a stringified value.
      return {
        ...prev,
        [e.target.name]: val,
      };
    });
  };
  const handleProfileDesignationData = (e, index) => {
    const { name, value } = e.target;
    const list = [...profileDesg];
    list[index][name] = value;
    setProfileDesg(list);
  };
  const removeProfileDesignationData = (index) => {
    const list = [...profileDesg];
    list.splice(index, 1);
    setProfileDesg(list);
  };
  const addProfileDesignationData = () => {
    setProfileDesg([
      ...profileDesg,
      {
        profile: "",
        package: 0,
        tech_req: [],
      },
    ]);
  };
  const saveProfileDesignation = (value) => {
    let profiles = [...inputValue.profile_designation];
    value.map((prof) => {
      return profiles.push(prof);
    });
    // profileDesg.length = 1;
    setInputValue((prev) => {
      return {
        ...prev,
        profile_designation: profiles,
      };
    });
  };
  const changeStepValue = (ind, value) => {
    let steps = [...inputValue.steps];
    steps[ind].content = value.content;
    steps[ind].title = value.title;
    setInputValue((preVal) => {
      return {
        ...preVal,
        steps: steps,
        hiringFlowActiveStep: steps.length,
      };
    });
  };
  const setHiringStepData = (e) => {
    setNextStep((preVal) => ({
      ...preVal,
      [e.target.name]: e.target.value,
    }));
  };
  const addStep = () => {
    let newSteps = [...inputValue.steps];
    newSteps.push({ title: "" });
    setInputValue((preVal) => {
      return {
        ...preVal,
        steps: newSteps,
        hiringFlowActiveStep: newSteps.length - 1,
      };
    });
  };
  const removeStep = (ind) => {
    let steps = [...inputValue.steps];
    console.log(steps);
    if (steps.length < ind + 1) {
      return;
    }
    const stepsLeft = steps.filter((item, i) => i !== ind);
    setInputValue((preVal) => {
      // console.log(stepsLeft);
      return {
        ...preVal,
        steps: stepsLeft,
        hiringFlowActiveStep: stepsLeft.length,
      };
    });
  };

  const setData = (e) => {
    setInputValue((preVal) => {
      return {
        ...preVal,
        [e.target.name]: e.target.value,
      };
    });
  };
  const updatePolicyEligigbility = () => {
    setPolicy(!policy);
  };
  const totalSteps = () => {
    return driveSteps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          driveSteps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const validateCompanyAndBatchDetails = () => {
    if (
      !inputValue.company_name ||
      !inputValue.batch ||
      !inputValue.course ||
      !inputValue.batch
    ) {
      // alert("Please fill all the data");
      toast.error("Incomplete Data", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    return true;
  };

  const validateDriveForProfileDesignationDetails = () => {
    if (!inputValue.profile_designation) {
      // alert("Please fill all the data");
      toast.error("Please add profile designation", {
        position: toast.POSITION.TOP_CENTER,
      });
      return false;
    }
    return true;
  };
  const handleComplete = () => {
    let flag = true;

    if (activeStep === 0) {
      flag = validateCompanyAndBatchDetails();
    } else if (activeStep === 1) {
      flag = validateDriveForProfileDesignationDetails();
    }
    if (flag) {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    }
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  //   setCompleted({});
  // };

  const { classes } = props;
  const { steps, hiringFlowActiveStep } = inputValue;

  const getAvailableBatches = () => {
    axios
      .get(`${SERVER_URL}/${GET_AVAILABLE_BATCHES}`)
      .then((res) => {
        if (res.status === 202) {
          setBatchOptions(res.data);
        } else if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error("OOPs! something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const getAvailableBranches = () => {
    axios
      .get(`${SERVER_URL}/${GET_AVAILABLE_BRANCHES}`)
      .then((res) => {
        if (res.status === 202) {
          setBranchOptions(res.data);
        } else if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const getAvailableCourses = () => {
    axios
      .get(`${SERVER_URL}/${GET_AVAILABLE_COURSES}`)
      .then((res) => {
        if (res.status === 202) {
          setCourseOptions(res.data);
        } else if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const getAvailableSkillsReq = () => {
    axios
      .get(`${SERVER_URL}/${GET_AVAILABLE_REQ_SKILLS}`)
      .then((res) => {
        if (res.status === 202) {
          setTechReqOptions(res.data);
        } else if (res.status === 422) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const createPlacementDrive = async (e) => {
    e.preventDefault();

    const {
      company_name,
      profile_designation,
      batch,
      course,
      branch,
      tenth_per,
      twelve_per,
      ug_per,
      gender,
      backlogs,
      steps,
      hiringFlowActiveStep,
    } = inputValue;

    axios.post(`${SERVER_URL}/tpo/${id}/${inst_id}/${CREATE_PLACEMENT_DRIVE}`, {
        company_name,
        profile_designation,
        batch,
        course,
        branch,
        tenth_per,
        twelve_per,
        ug_per,
        gender,
        policy,
        backlogs,
        steps,
        hiringFlowActiveStep,
      })
      .then((res) => {
        // console.log(res);
        if (res.status === 422 || !res.data) {
          toast.error(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        if (res.status === 202) {
          res.data.companyName = company_name;
          toast.success(res.data, {
            position: toast.POSITION.TOP_CENTER,
          });
          navigate(`shortlised-students`, { state: res.data });
        }
      })
      .catch((err) => {
        toast.error(err.response.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    getAvailableBatches();
  }, []);

  useEffect(() => {
    getAvailableBranches();
  }, []);

  useEffect(() => {
    getAvailableCourses();
  }, []);

  useEffect(() => {
    getAvailableSkillsReq();
  }, []);

  // console.log(profileDesg);
  // console.log(inputValue);
  return (
    <>
      <ToastContainer />
      <Box className="stepper-box">
        <Stepper alternativeLabel activeStep={0} connector={<QontoConnector />}>
          {driveSteps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton>
                <StepLabel
                  onClick={handleStep(index)}
                  StepIconComponent={QontoStepIcon}
                >
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <div className="steps">
          {activeStep <= 2 ? (
            <Button
              className="back-step-btn"
              color="inherit"
              height="50px"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              {" "}
              <KeyboardArrowLeft /> Back{" "}
            </Button>
          ) : (
            ""
          )}

          {allStepsCompleted() ? (
            <>
              <Paper
                elevation={4}
                className="successfully-created"
                sx={{
                  padding: "150px 200px",
                  margin: "100px auto",
                  textAlign: "center",
                }}
              >
                <p>Are you sure ? </p>
                {/* <CheckCircleIcon sx={{fontSize: '80px' ,color : '#0e9e1f'}} /> */}
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ margin: "20px" }}
                  onClick={createPlacementDrive}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ margin: "20px" }}
                >
                  No
                </Button>
              </Paper>
              {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box> */}
            </>
          ) : (
            <div className="step-data">
              {activeStep === 0 ? (
                <>
                  <Paper className="step-1-data" elevation={3}>
                    <img
                      src={Step1Img}
                      className="step-1-img"
                      alt="step1Img"
                      height="300"
                      width="300"
                    />
                    <div className="step1-input-area">
                      <TextField
                        id="outlined-basic"
                        label="Company Name"
                        variant="outlined"
                        onChange={setData}
                        name="company_name"
                        className="name userInput"
                      />
                      <FormControl>
                        <InputLabel id="batch-id">Select Batch</InputLabel>
                        <Select
                          labelId="batch-id"
                          id="demo-multiple-checkbox"
                          multiple
                          value={inputValue.batch}
                          name="batch"
                          onChange={handleChange}
                          input={<OutlinedInput label="Select Batch" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {batchOptions.map((bat) => (
                            <MenuItem
                              key={bat}
                              value={bat}
                              className="select-menu-item"
                            >
                              <Checkbox
                                checked={inputValue.batch.indexOf(bat) > -1}
                              />
                              <ListItemText primary={bat} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel id="course-id">Select Course</InputLabel>
                        <Select
                          labelId="course-id"
                          id="demo-simple-select"
                          value={inputValue.course}
                          name="course"
                          label="Select Course"
                          onChange={setData}
                        >
                          {courseOptions.map((cour) => (
                            <MenuItem key={cour} value={cour}>
                              {cour}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl>
                        <InputLabel id="branch-id">Select Branch</InputLabel>
                        <Select
                          labelId="branch-id"
                          id="demo-multiple-checkbox"
                          multiple
                          value={inputValue.branch}
                          name="branch"
                          onChange={handleChange}
                          input={<OutlinedInput label="Select Branch" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {branchOptions.map((bran) => (
                            <MenuItem
                              key={bran}
                              value={bran}
                              className="select-menu-item"
                            >
                              <Checkbox
                                checked={inputValue.branch.indexOf(bran) > -1}
                              />
                              <ListItemText primary={bran} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Paper>
                </>
              ) : (
                <>
                  {activeStep === 1 ? (
                    <Paper className="select-data-2" elevation={3}>
                      <Row>
                        <Col>
                          <img
                            src={Step2Img}
                            className="step-2-img"
                            alt="step2Img"
                            height="200"
                            width="300"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="step2-input-area">
                            {profileDesg.map((profile, index) => {
                              return (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <TextField
                                      id="outlined-basic"
                                      type="text"
                                      label="Profile"
                                      variant="outlined"
                                      name="profile"
                                      onChange={(e) =>
                                        handleProfileDesignationData(e, index)
                                      }
                                      className="profile-field"
                                    />
                                    <TextField
                                      id="outlined-basic"
                                      type="number"
                                      label="Package (LPA)"
                                      variant="outlined"
                                      className="package-field"
                                      name="package"
                                      onChange={(e) =>
                                        handleProfileDesignationData(e, index)
                                      }
                                    />
                                    <FormControl>
                                      <InputLabel id="tech-req-id">
                                        Tech Requirement
                                      </InputLabel>
                                      <Select
                                        labelId="tech-req-id"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={profileDesg[index].tech_req}
                                        name="tech_req"
                                        onChange={(e) =>
                                          handleProfileDesignationData(e, index)
                                        }
                                        input={
                                          <OutlinedInput label="Tech Requirement" />
                                        }
                                        renderValue={(selected) =>
                                          selected.join(", ")
                                        }
                                        MenuProps={MenuProps}
                                      >
                                        {techReqOptions.map((tech) => (
                                          <MenuItem
                                            key={tech}
                                            value={tech}
                                            className="select-menu-item"
                                          >
                                            <Checkbox
                                              checked={
                                                profileDesg[
                                                  index
                                                ].tech_req.indexOf(tech) > -1
                                              }
                                            />
                                            <ListItemText primary={tech} />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>

                                    {profileDesg.length !== 1 && (
                                      <Button
                                        onClick={() =>
                                          removeProfileDesignationData(index)
                                        }
                                        sx={{ fontSize: "40px", color: "red" }}
                                      >
                                        {" "}
                                        <HighlightOffIcon />{" "}
                                      </Button>
                                    )}
                                  </div>
                                  {profileDesg.length - 1 === index && (
                                    <Button
                                      onClick={() =>
                                        addProfileDesignationData(index)
                                      }
                                      className="profile-add-btn"
                                      variant="contained"
                                    >
                                      ADD
                                    </Button>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            variant="outlined"
                            onClick={() => saveProfileDesignation(profileDesg)}
                            className="profile-save-btn"
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Paper>
                  ) : (
                    <>
                      <Paper className="step-3-data" elevation={3}>
                        <p>Eligibility Criteria</p>
                        <FormControl>
                          <Row>
                            <Col>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                label="10th %"
                                variant="outlined"
                                onChange={setData}
                                name="tenth_per"
                                className="name userInput"
                              />
                            </Col>
                            <Col>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                label="12th %"
                                variant="outlined"
                                onChange={setData}
                                name="twelve_per"
                                className="name userInput"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                label="UG %"
                                variant="outlined"
                                onChange={setData}
                                name="ug_per"
                                className="name userInput"
                              />
                            </Col>
                            <Col>
                              <TextField
                                type="number"
                                id="outlined-basic"
                                label="Backlogs"
                                variant="outlined"
                                onChange={setData}
                                name="backlogs"
                                className="name userInput"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <FormControl>
                                <InputLabel id="gender">Gender</InputLabel>
                                <Select
                                  labelId="gender"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  value={inputValue.gender}
                                  name="gender"
                                  onChange={handleChange}
                                  input={<OutlinedInput label="Gender" />}
                                  renderValue={(selected) =>
                                    selected.join(", ")
                                  }
                                  MenuProps={MenuProps}
                                >
                                  {genderOptions.map((gen) => (
                                    <MenuItem
                                      key={gen}
                                      value={gen}
                                      className="select-menu-item"
                                    >
                                      <Checkbox
                                        checked={
                                          inputValue.gender.indexOf(gen) > -1
                                        }
                                      />
                                      <ListItemText primary={gen} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Col>
                          </Row>
                          <Row style={{ float: "left" }}>
                            <Col md={{ span: 5 }}>
                              <BootstrapTooltip title="Apply 30%+ criteria">
                                <FormGroup>
                                  <FormControlLabel
                                    onChange={updatePolicyEligigbility}
                                    control={<Checkbox defaultChecked />}
                                    label="Apply Institution Policy"
                                  />
                                </FormGroup>
                              </BootstrapTooltip>
                            </Col>
                          </Row>
                        </FormControl>
                        <p>Hiring Workflow</p>
                        {/* <HiringWorkflowComponent changeStepValue={changeStepValue} addStep={addStep} />   */}

                        <div className={classes.body}>
                          <Stepper
                            nonLinear
                            sx={{ padding: "50px" }}
                            activeStep={hiringFlowActiveStep}
                            orientation="vertical"
                          >
                            {steps.map((step, index) => (
                              <Step
                                key={`step_${index}_container`}
                                disabled={index !== steps.length - 1}
                              >
                                <StepButton
                                  onClick={() =>
                                    setInputValue((prev) => {
                                      return {
                                        ...prev,
                                        hiringFlowActiveStep:
                                          hiringFlowActiveStep === index &&
                                          isSet(steps[index])
                                            ? steps.length
                                            : index,
                                      };
                                    })
                                  }
                                >
                                  <div style={{ display: "flex" }}>
                                    <span>
                                      {step.title || "Enter step details "}
                                    </span>
                                    {hiringFlowActiveStep === index && (
                                      <Tooltip
                                        title="Remove Step"
                                        placement={"right"}
                                      >
                                        <Delete
                                          style={{
                                            marginLeft: "500px",
                                            color: "#03506f",
                                          }}
                                          onClick={() => removeStep(index)}
                                        />
                                      </Tooltip>
                                    )}
                                  </div>
                                </StepButton>
                                <StepContent>
                                  <FormControl>
                                    <TextField
                                      id="outlined-basic"
                                      label="Title"
                                      name="title"
                                      onChange={setHiringStepData}
                                      variant="outlined"
                                      className="userInput"
                                    />
                                    <TextField
                                      id="outlined-basic"
                                      label="Content"
                                      onChange={setHiringStepData}
                                      variant="outlined"
                                      name="content"
                                      className="userInput"
                                    />
                                    <Button
                                      variant="outlined"
                                      color="success"
                                      onClick={() =>
                                        changeStepValue(index, initialStep)
                                      }
                                    >
                                      Done
                                    </Button>
                                  </FormControl>
                                </StepContent>
                              </Step>
                            ))}
                            <Step
                              key={"add-a-step"}
                              disabled={
                                steps.length > 0 &&
                                !isSet(steps[steps.length - 1])
                              }
                            >
                              <StepButton
                                icon={
                                  <Add
                                    className={
                                      classes.addButton +
                                      (steps.length > 0 &&
                                      !isSet(steps[steps.length - 1].value)
                                        ? " disabled"
                                        : "")
                                    }
                                  />
                                }
                                onClick={() => addStep()}
                                style={{ fontWeight: "bold" }}
                              >
                                {"Add a Step"}
                              </StepButton>
                            </Step>
                          </Stepper>
                        </div>
                      </Paper>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {/* {
              activeStep !== steps.length &&
              (completed[activeStep] ? (
                <Typography variant="caption" sx={{ display: 'inline-block' }}>
                  Step {activeStep + 1} already completed
                </Typography>
              ) : ( */}
          <Button onClick={handleComplete} className="next-step-btn">
            {completedSteps() === totalSteps() - 1 && activeStep === 2 ? (
              "Finish"
            ) : (
              <>
                {activeStep <= 2 ? (
                  <>
                    Next <KeyboardArrowRight />
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </Button>
          {/* ))
            } */}
        </div>
        <Outlet />
      </Box>
    </>
  );
};

NewPlacementDriveComponent.propTypes = {
  classes: PropTypes.object,
};

const styles = (theme) => ({
  addButton: {
    color: "#0088f2",
    transformOrigin: "center",
    transform: "scale(1.3)",
    "&.disabled": {
      color: "rgba(0,0,0,0.38)",
    },
  },
  body: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(7),
    },
  },
});

export default withStyles(styles)(NewPlacementDriveComponent);

// export default NewPlacementDriveComponent;
