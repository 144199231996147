export const EDUCATION = "EDUCATION";
export const CLEAR_EDUCATION = "CLEAR_EDUCATION";
export const PROFILE = "PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const SKILLS = "SKILLS";
export const CLEAR_SKILLS = "CLEAR_SKILLS";
export const SOCIALS = "SOCIALS";
export const CLEAR_SOCIALS = "CLEAR_SOCIALS";
export const PROJECTS = "PROJECTS";
export const CLEAR_PROJECTS = "CLEAR_PROJECTS";
export const MODIFY_COUNT = "MODIFY_COUNT";
export const MODIFY_SKILLS_COUNT = "MODIFY_SKILLS_COUNT";
export const MODIFY_SOCIAL_COUNT = "MODIFY_SOCIAL_COUNT";
export const MODIFY_PROJECTS_COUNT = "MODIFY_PROJECTS_COUNT";
