import React, { useState, useEffect } from "react";
import "./adminStyle.css";
import NavbarComponent from "../common/navbar/NavbarComponent";
import SideNavComponent from "../common/sideNav/SideNavComponent";
import { useParams } from "react-router-dom";
import axios from "axios";
import ConfirmationComponent from "../common/ConfirmationComponent";
import { GET_ADMIN_DATA, SERVER_URL } from "../../apiRoutes";

const AdminDashBoardComponent = (props) => {
  const { id } = useParams("");
  const { Children } = props;
  const [user, setUser] = useState([]);
  const [error, setError] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [resIcon, setResIcon] = useState("");
  const user_role = "admin";

  const getAdminData = () => {
    axios
      .get(`${SERVER_URL}/${GET_ADMIN_DATA}/${id}/data`)
      .then((res) => {
        if (res.status === 422) {
          setError(true);
          setResMsg("OOPs! Something went wrong");
          setResIcon("error");
        } else if (res.status === 202) {
          setUser(res.data);
        }
      })
      .catch((err) => {
        setError(true);
        setResMsg("OOPs! Something went wrong");
        setResIcon("error");
      });
  };
  // console.log(user);

  useEffect(() => {
    getAdminData();
  }, []);

  return (
    <>
      {error ? <ConfirmationComponent data={resMsg} children={resIcon} /> : ""}
      <NavbarComponent data={user} role={user_role} />

      <SideNavComponent data={user} role={user_role} children={Children} />
    </>
  );
};

export default AdminDashBoardComponent;
